import { Route, Routes } from 'react-router-dom';
import { ProgrammesTilesPage } from './ProgrammesTilesPage';
import { CivilLiabilityExecutiveRouter } from './CivilLiability/CivilLiabilityExecutive/CivilLiabilityExecutiveRouter';
import { CivilLiabilityProRouter } from './CivilLiability/CivilLiabilityPro/CivilLiabilityProRouter';
import { PropertyRouter } from './Property/PropertyRouter';
import { OtherConstructionRouter } from './Construction/OthersConstruction/OtherConstructionRouter';
import { GolfRouter } from './Golf/GolfRouter';
import { OwnAccountsRouter } from './Transport/OwnAccounts/OwnAccountsRouter';
import { FreightedGoodsRouter } from './Transport/FreightedGoods/FreightedGoodsRouter';
import { LightVehiclesRouter } from './VehiclesFleets/Lightvehicles/LightVehiclesRouter';
import { HeavyVehiclesRouter } from './VehiclesFleets/HeavyVehicles/HeavyVehiclesRouter';
import { OtherVehiclesRouter } from './VehiclesFleets/OtherVehicles/OtherVehiclesRouter';
import { AutomissionsRouter } from './VehiclesFleets/Automissions/AutomissionsRouter';
import { AdValoremRouter } from './Transport/Advalorem/AdValoremRouter';
import { FacultiesRouter } from './Transport/Faculties/FacultiesRouter';
import { ConstructionInsuranceRouter } from './Construction/ConstructionInsurance/ConstructionInsuranceRouter';
import { PremiumCalculator } from './Construction/PremiumCalculator/PremiumCalculator';
import { Exception } from '../../components';

export const ProgrammesRouter = () => (
  <Routes>
    <Route
      path="/construction-insurance/*"
      element={<ConstructionInsuranceRouter />}
    />
    <Route path="/other-construction/*" element={<OtherConstructionRouter />} />
    <Route path="/own-accounts/*" element={<OwnAccountsRouter />} />
    <Route path="/freighted-goods/*" element={<FreightedGoodsRouter />} />
    <Route path="/faculties/*" element={<FacultiesRouter />} />
    <Route path="/ad-valorem/*" element={<AdValoremRouter />} />
    <Route path="/automissions/*" element={<AutomissionsRouter />} />
    <Route path="/other-vehicles/*" element={<OtherVehiclesRouter />} />
    <Route path="/heavy-vehicles/*" element={<HeavyVehiclesRouter />} />
    <Route path="/light-vehicles/*" element={<LightVehiclesRouter />} />
    <Route path="/golfs/*" element={<GolfRouter />} />
    <Route path="/properties/*" element={<PropertyRouter />} />
    <Route
      path="/civil-liability-executive/*"
      element={<CivilLiabilityExecutiveRouter />}
    />
    <Route
      path="/civil-liability-pro/*"
      element={<CivilLiabilityProRouter />}
    />
    <Route path="/calculator" element={<PremiumCalculator />} />
    <Route
      index
      element={
        <ProgrammesTilesPage
          title="insurance_programmes"
          tilesPage="programmes"
        />
      }
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
