import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.automissionsFields - Field configurations for automissions.
 */

export const useAutomissionsFields = () => {
  const automissionsFields = [
    {
      name: ['missing_documents'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['contract_number'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['date_added'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['entity'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['sub_entity'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['site'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['address'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['postal_code'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['city'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['products'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['collaborator_first_name'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['collaborator_last_name'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['vehicle_registration_number'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['departure_time'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['start_of_the_mission'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['planned_end_of_the_mission'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['journey_lenght'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['departure_city'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['arrival_city'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    }
  ];
  return { automissionsFields };
};
