import React, { useState, createContext, useEffect } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

export const HeavyVehiclesContext = createContext();

/**
 * A provider component for the HeavyVehiclesContext.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.Node} props.children - The child components to be wrapped by the context.
 * @returns {React.Element} The HeavyVehiclesContextProvider component.
 */

export const HeavyVehiclesContextProvider = ({ children }) => {
  const [contractId, setContractId] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [contracts, setContracts] = useState([]);
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();

  const getcontractId = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/contracts/number/companies/${company}`,
        body: {
          programme_types: 'FLEET',
          sub_programme: 'HEAVY_VEHICLE'
        }
      });
      setContractId(data[0]._id);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getcontractId();
    })();
  }, []);

  return (
    <HeavyVehiclesContext.Provider
      value={{
        forceRefresh,
        setForceRefresh,
        contracts,
        setContracts,
        contractId
      }}
    >
      {children}
    </HeavyVehiclesContext.Provider>
  );
};
