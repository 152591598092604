import { Tree } from 'antd';

const { TreeNode } = Tree;

export const DisplayNodesPermissions = () => {
  const renderTitle = (node) => {
    if (!node) return null;

    return <span>{node.title}</span>;
  };

  const renderTreeNodes = (nodes) => {
    if (!nodes) return null;

    if (Array.isArray(nodes)) {
      return nodes.map((node) => {
        const title = renderTitle(node);

        if (node.children) {
          return (
            <TreeNode key={node.key} title={title} autoExpandParent>
              {renderTreeNodes(node.children)}
            </TreeNode>
          );
        }
        return null;
      });
    }
    const title = renderTitle(nodes);
    return <TreeNode key={nodes.key} title={title} isLeaf={nodes.isLeaf} />;
  };
  return {
    renderTitle,
    renderTreeNodes
  };
};
