import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Image, Row, Modal } from 'antd';
import { onRemove } from './onRemove';

/**
 * Custom render function for items in an Ant Design upload list.
 *
 * @function
 * @param {Object} file - The file object of the current file item.
 * @param {Array} fileList - The list of all files.
 * @param {function} downloadFile - A function to handle file download.
 * @param {string} name - The name associated with the file.
 * @param {Object} filesConfiguration - Configuration related to files.
 * @param {function} setFilesConfiguration - A function to set files configuration.
 * @param {function} setFieldsFileList - A function to set fields file list.
 * @param {string} purpose - The purpose of the file.
 * @param {function} deleteFile - A function to delete a file.
 * @param {string} message - A message related to the file.
 * @param {function} setFilesToUpload - A function to set files to upload.
 * @param {function} t - A function to translate text.
 * @returns {React.Node} The custom or default component to render for the current file item.
 */
export const itemRender = (
  file,
  fileList,
  downloadFile,
  name,
  filesConfiguration,
  setFilesConfiguration,
  setFieldsFileList,
  purpose,
  deleteFile,
  message,
  setFilesToUpload,
  t
) => {
  const fileName = file?.file?.file?.name || file?.file?.name;
  const fileToRender = fileList.find(
    (fileItem) => fileItem?.rawFile?.metadata.originalName === fileName
  );
  const isEditFile = !!fileToRender?.rawFile?._id;

  const handleRemove = () => {
    Modal.confirm({
      title: t('files.modal.delete_title'),
      content: t('files.modal.delete_content'),
      okText: t('files.modal.yes'),
      okType: 'danger',
      cancelText: t('files.modal.no'),
      onOk: async () => {
        await onRemove(
          name,
          file,
          filesConfiguration,
          setFilesConfiguration,
          setFieldsFileList,
          purpose,
          deleteFile,
          message,
          setFilesToUpload
        );
      }
    });
  };

  return (
    <Row
      className="custom-fileList-item"
      gutter={[8, 8]}
      style={{ minWidth: 300 }}
    >
      <Col span={8} style={{ maxHeight: 100, minHeight: 100 }}>
        <Image src={isEditFile ? fileToRender?.file?.url : file?.url} />
      </Col>
      <Col span={16}>
        <Row className="fileList-metadata">
          <Col className="file-name">
            {isEditFile
              ? fileToRender.rawFile.metadata.originalName
              : file?.file?.name}
          </Col>
          <Col>
            {isEditFile && (
              <Button
                type="link"
                onClick={() => downloadFile(fileToRender.rawFile)}
              >
                <DownloadOutlined />
              </Button>
            )}
            <Button type="danger" onClick={handleRemove}>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
