import React, { useState, createContext, useEffect } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

export const AutomissionsContext = createContext();

export const AutomissionsContextProvider = ({ children }) => {
  const [enums, setEnums] = useState([]);
  const [contractId, setContractId] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [contracts, setContracts] = useState([]);
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/vehicles/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getcontractId = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/contracts/number/companies/${company}`,
        body: {
          programme_types: 'FLEET',
          sub_programme: 'LIGHT_VEHICLE'
        }
      });
      setContractId(data[0]._id);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getcontractId();
    })();
  }, []);

  return (
    <AutomissionsContext.Provider
      value={{
        enums,
        forceRefresh,
        setForceRefresh,
        contracts,
        setContracts,
        contractId
      }}
    >
      {children}
    </AutomissionsContext.Provider>
  );
};
