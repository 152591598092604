import { Routes, Route } from 'react-router-dom';
import { Exception } from '../../components';
import { ListSubscriptions } from './ListSubscriptions';
import { CivilLiabilitySubscriptionCreateUpdate } from './CivilLiabilitySubscriptionCreateUpdate';
import { TransportSubscriptionCreateUpdate } from './TransportSubscriptionCreateUpdate';
import { ConstructionSubscriptionCreateUpdate } from './ConstructionSubscriptionCreateUpdate';
import { SubscriptionShow } from './SubscriptionShow';
import { SubscriptionContextProvider } from './SubscriptionContext';

/**
 * A router component for subscription-related routes.
 * @component
 * @returns {JSX.Element} The JSX element representing the subscription router.
 */
export const SubscriptionRouter = () => (
  <SubscriptionContextProvider>
    <Routes>
      <Route index element={<ListSubscriptions />} />
      <Route
        path="/civil-liability/pro/create"
        element={
          <CivilLiabilitySubscriptionCreateUpdate
            purpose="create"
            subProgramme="CIVIL_LIABILITY_PRO"
          />
        }
      />
      <Route
        path="/civil-liability/executive/create"
        element={
          <CivilLiabilitySubscriptionCreateUpdate
            purpose="create"
            subProgramme="CIVIL_LIABILITY_EXECUTIVE"
          />
        }
      />
      <Route
        path="/transport/faculty/create"
        element={
          <TransportSubscriptionCreateUpdate
            purpose="create"
            subProgramme="FACULTY"
          />
        }
      />
      <Route
        path="/transport/freighted-goods/create"
        element={
          <TransportSubscriptionCreateUpdate
            purpose="create"
            subProgramme="FREIGHTED_GOODS"
          />
        }
      />
      <Route
        path="/transport/own-accounts/create"
        element={
          <TransportSubscriptionCreateUpdate
            purpose="create"
            subProgramme="OWN_ACCOUNT"
          />
        }
      />
      <Route
        path="/construction/create"
        element={
          <ConstructionSubscriptionCreateUpdate
            purpose="create"
            subProgramme="OTHER"
          />
        }
      />
      <Route
        path="/civil-liability/edit/:id"
        element={<CivilLiabilitySubscriptionCreateUpdate purpose="edit" />}
      />
      <Route
        path="/transport/edit/:id"
        element={<TransportSubscriptionCreateUpdate purpose="edit" />}
      />
      <Route
        path="/construction/edit/:id"
        element={<ConstructionSubscriptionCreateUpdate purpose="edit" />}
      />
      <Route path="show/:id" element={<SubscriptionShow />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </SubscriptionContextProvider>
);
