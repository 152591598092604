import { Route, Routes } from 'react-router-dom';
import { ShowFreightedGoods } from './ShowFreightedGoods';
import { ListFreightedGoods } from './ListFreightedGoods';
import { Exception } from '../../../../components';

export const FreightedGoodsRouter = () => (
  <Routes>
    <Route path="/show/:id" element={<ShowFreightedGoods />} />
    <Route index element={<ListFreightedGoods />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
