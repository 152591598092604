import { Link } from 'react-router-dom';
import { Flex } from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  FileAddOutlined,
  SyncOutlined,
  MailOutlined
} from '@ant-design/icons';
import { Flood } from '@carbon/icons-react';

const iconSize = 18;

/**
 * `useActionColumn` is a hook that returns a configuration array for rendering an action column.
 * The action column consists of several icons used for viewing a document, editing a document,
 * and other associated actions. Each icon has an associated action, such as navigation or
 * triggering a functionality.
 *
 * @hook
 * @returns {Array} An array containing configuration for the action column. The configuration includes
 * how to render the column based on the record provided.
 */

export const useActionColumn = () => [
  {
    key: 'action',
    align: 'right',
    render: (record) => (
      <Flex gap="small" align="center" justify="end" wrap="nowrap">
        <Link to={`/programmes/construction-insurance/show/${record?._id}`}>
          <EyeOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Link to={`/programmes/construction-insurance/edit/${record?._id}`}>
          <EditOutlined style={{ fontSize: iconSize }} />
        </Link>
        {record?.status !== 'AMENDMENT_REQUEST' && (
          <Link
            to={{
              pathname: `/programmes/construction-insurance/amendment/create/${record?._id}`
            }}
          >
            <FileAddOutlined style={{ fontSize: iconSize }} />
          </Link>
        )}
        <Link to={`/claims/construction-insurance/create/${record?._id}`}>
          <Flood size={iconSize} />
        </Link>
        <SyncOutlined style={{ fontSize: iconSize }} />
        <MailOutlined style={{ fontSize: iconSize }} />
      </Flex>
    )
  }
];
