import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListClaimAccountingOverview } from './ListClaimAccountingOverview';

import { useFieldsAppeal } from './utils/fields/fieldsAppeal';
import { useFieldsInvoicesPayment } from './utils/fields/fieldsInvoicesPayment';
import { useFieldsFees } from './utils/fields/fieldsFees';
import { useFieldsExcess } from './utils/fields/fieldsExcess';
import { useFieldsAccountingProvision } from './utils/fields/fieldsAccountingProvision';

import { CustomerSumFirstLine } from './utils/amountsDisplay/CustomerSumFirstLine';
import { CustomerTotalAgainst } from './utils/amountsDisplay/CustomerTotalAgainst';
import { CustomerSumLodged } from './utils/amountsDisplay/CustomerSumLodged';
import { AmountDue } from './utils/amountsDisplay/AmountDue';

export const ShowClaimAccountingOverview = ({
  current,
  buttonBelowTable,
  noActionColumnsButton,
  accountingBalanced,
  setAccountingBalanced
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { fieldsInvoicesPayment } = useFieldsInvoicesPayment();
  const { fieldsAppeal } = useFieldsAppeal();
  const { fieldsFees } = useFieldsFees();
  const { fieldsExcess } = useFieldsExcess();
  const { fieldsAccountingProvision } = useFieldsAccountingProvision();
  const [reload, setReload] = useState(false);

  return (
    <Card title={t('claims.accounting_overview')}>
      {current && (
        <Row gutter={[16, 16]}>
          <Col xs={12} xl={12}>
            <Row gutter={[16, 16]} justify="space-between">
              <CustomerSumLodged
                reload={reload}
                baseUrl="property-claim-accounts"
              />
              <CustomerSumFirstLine
                reload={reload}
                baseUrl="property-claim-accounts"
              />
            </Row>
          </Col>
          <Col xs={12} xl={12}>
            <Row gutter={[16, 16]} justify="space-between">
              <CustomerTotalAgainst
                reload={reload}
                baseUrl="property-claim-accounts"
              />
              <AmountDue
                reload={reload}
                baseUrl="property-claim-accounts"
                accountingBalanced={accountingBalanced}
                setAccountingBalanced={setAccountingBalanced}
              />
            </Row>
          </Col>
          <Col xs={12} xl={12}>
            <Card title={t('claims.form.INVOICES_PAYMENT')}>
              <ListClaimAccountingOverview
                claimId={id}
                resourceName="property-claim-accounts"
                amountType="INVOICES_PAYMENT"
                fields={fieldsInvoicesPayment}
                url="property-claim-accounts"
                setReload={setReload}
                reload={reload}
                buttonBelowTable={buttonBelowTable}
                noActionColumnsButton={noActionColumnsButton}
              />
            </Card>
          </Col>
          <Col xs={12} xl={12}>
            <Card title={t('claims.form.appeal')}>
              <ListClaimAccountingOverview
                claimId={id}
                resourceName="property-claim-accounts"
                amountType="APPEAL"
                fields={fieldsAppeal}
                url="property-claim-accounts"
                setReload={setReload}
                reload={reload}
                buttonBelowTable={buttonBelowTable}
                noActionColumnsButton={noActionColumnsButton}
              />
            </Card>
          </Col>
          <Col xs={12} xl={12}>
            <Card title={t('claims.form.FEES')}>
              <ListClaimAccountingOverview
                claimId={id}
                resourceName="property-claim-accounts"
                amountType="FEES"
                fields={fieldsFees}
                url="property-claim-accounts"
                setReload={setReload}
                reload={reload}
                buttonBelowTable={buttonBelowTable}
                noActionColumnsButton={noActionColumnsButton}
              />
            </Card>
          </Col>
          <Col xs={12} xl={12}>
            <Card title={t('claims.form.EXCESS')}>
              <ListClaimAccountingOverview
                claimId={id}
                resourceName="property-claim-accounts"
                amountType="EXCESS"
                fields={fieldsExcess}
                url="property-claim-accounts"
                setReload={setReload}
                reload={reload}
                buttonBelowTable={buttonBelowTable}
                noActionColumnsButton={noActionColumnsButton}
              />
            </Card>
          </Col>
          <Col xs={12} xl={12}>
            <Card title={t('claims.form.ACCOUNTING_PROVISION')}>
              <ListClaimAccountingOverview
                claimId={id}
                resourceName="property_claims"
                amountType="ACCOUNTING_PROVISION"
                fields={fieldsAccountingProvision}
                url="property_claims"
                setReload={setReload}
                reload={reload}
                noPagination
                noActionColumnsButton={noActionColumnsButton}
              />
            </Card>
          </Col>
        </Row>
      )}
    </Card>
  );
};

ShowClaimAccountingOverview.propTypes = {
  current: PropTypes.number.isRequired,
  buttonBelowTable: PropTypes.bool,
  noActionColumnsButton: PropTypes.bool,
  accountingBalanced: PropTypes.bool.isRequired,
  setAccountingBalanced: PropTypes.func.isRequired
};

ShowClaimAccountingOverview.defaultProps = {
  buttonBelowTable: false,
  noActionColumnsButton: false
};
