import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectEnumsClaimsType } from '../../components/SelectEnumsClaimsType';
import { SelectManager } from '../../components/SelectManager';

/**
 * A hook for generating form fields configuration based on the provided parameters.
 *
 * @hook
 * @param {boolean} isOther - Indicates whether the "claim_description_if_other" field is visible.
 * @param {Function} setIsOther - A function to set the visibility of the "claim_description_if_other" field.
 * @param {Function} setIsComplaint - A function to set the visibility fields in dragger.
 * @returns {Object} An object containing an array of form fields configuration.
 */

export const useFields = (isOther, setIsOther, setIsComplaint) => {
  const dateFormat = 'DD-MM-YYYY';
  const { t } = useTranslation();

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['insurance_company_reference'],
      name: ['insurance_company_reference']
    },
    {
      label: ['client_reference'],
      name: ['client_reference']
    },
    {
      label: ['claim_type'],
      name: ['claim_type'],
      rules: [{ required: true }],
      input: (
        <SelectEnumsClaimsType
          dbKey="claim_type"
          url="property_claims"
          setIsOther={setIsOther}
          setIsComplaint={setIsComplaint}
        />
      )
    },
    {
      label: ['claim_type_if_other'],
      name: ['claim_type_if_other'],
      hidden: !isOther
    },
    {
      label: ['sinister_date'],
      name: ['sinister_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['pictures_damages_caused'],
      name: ['pictures_damages_caused'],
      input: 'File',
      maxFilesCount: 5,
      multipleFiles: true,
      startWithDivider: {
        title: t('claims.form.pictures')
      },
      endWithDivider: {
        title: t('claims.form.documents')
      }
    }
  ];

  return {
    fieldsInformations
  };
};
