import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A hook that generates an array of field configurations. These configurations
 * are used for form fields and include details like field names, labels, and validation rules.
 * The fields are meant for contacts, categorized under 'customer_managers' and 'claims_managers'.
 *
 * @hook
 * @returns {Object} Contains an array of field configurations.
 * @property {Array<Object>} fields - An array of field configurations.
 */

export const useFields = () => {
  const { t } = useTranslation();
  const fields = [
    {
      name: ['div1'],
      label: ['divider_1'],
      input: <Divider>{t('contacts.customer_managers')}</Divider>
    },
    {
      label: ['vehicle_contacts_mail'],
      name: ['vehicle_contacts_mail_customer'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['vehicle_contacts_phone'],
      name: ['vehicle_contacts_phone_customer']
    },
    {
      label: ['real_estate_contacts_mail'],
      name: ['real_estate_contacts_mail_customer'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['real_estate_contacts_phone'],
      name: ['real_estate_contacts_phone_customer']
    },
    {
      label: ['golf_contacts_mail'],
      name: ['golf_contacts_mail_customer'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['golf_contacts_phone'],
      name: ['golf_contacts_phone_customer']
    },
    {
      label: ['construction_contacts_mail'],
      name: ['construction_contacts_mail_customer'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['construction_contacts_phone'],
      name: ['construction_contacts_phone_customer']
    },
    {
      label: ['civil_liability_contacts_mail'],
      name: ['civil_liability_contacts_mail_customer'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['civil_liability_contacts_phone'],
      name: ['civil_liability_contacts_phone_customer']
    },
    {
      label: ['transport_contacts_mail'],
      name: ['transport_contacts_mail_customer'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['transport_contacts_phone'],
      name: ['transport_contacts_phone_customer']
    },
    {
      name: ['div2'],
      label: ['divider_2'],
      input: <Divider>{t('contacts.claims_managers')}</Divider>
    },
    {
      label: ['vehicle_contacts_mail'],
      name: ['vehicle_contacts_mail_claim'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['vehicle_contacts_phone'],
      name: ['vehicle_contacts_phone_claim']
    },
    {
      label: ['real_estate_contacts_mail'],
      name: ['real_estate_contacts_mail_claim'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['real_estate_contacts_phone'],
      name: ['real_estate_contacts_phone_claim']
    },
    {
      label: ['golf_contacts_mail'],
      name: ['golf_contacts_mail_claim'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['golf_contacts_phone'],
      name: ['golf_contacts_phone_claim']
    },
    {
      label: ['construction_contacts_mail'],
      name: ['construction_contacts_mail_claim'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['construction_contacts_phone'],
      name: ['construction_contacts_phone_claim']
    },
    {
      label: ['civil_liability_contacts_mail'],
      name: ['civil_liability_contacts_mail_claim'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['civil_liability_contacts_phone'],
      name: ['civil_liability_contacts_phone_claim']
    },
    {
      label: ['transport_contacts_mail'],
      name: ['transport_contacts_mail_claim'],
      rules: [{ type: 'email' }]
    },
    {
      label: ['transport_contacts_phone'],
      name: ['transport_contacts_phone_claim']
    }
  ];

  return {
    fields
  };
};
