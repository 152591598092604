import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const LinksGeneration = ({ target, link }) => {
  const { t } = useTranslation();
  return (
    <Link to={`/${target}/${link}`}>{t(`tiles.${link}`)}&nbsp;&nbsp;→</Link>
  );
};

LinksGeneration.propTypes = {
  link: PropTypes.string,
  target: PropTypes.string.isRequired
};

LinksGeneration.defaultProps = {
  link: ''
};
