import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Flex, Row, Col, Image, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { createFileFromJSON } from '../../components/CreateUpdateContainerV3/utils/createFileFromJSON';

/**
 * A component for displaying photos of the exterior and interior of an asset.
 *
 * This component fetches and displays photos of the exterior and interior of an asset
 * based on the provided base URL.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.baseUrl - The base URL for fetching photos.
 *
 * @returns {JSX.Element} The rendered `Photos` component.
 */

export const Photos = ({ baseUrl }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [photosExterior, setPhotosExterior] = useState([]);
  const [photosInterior, setPhotosInterior] = useState([]);

  const getPhotos = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${baseUrl}/${id}?populate=documents.file`
      });
      const picturesExterior = data.documents.filter((doc) =>
        doc.fileKey.includes('photos_of_the_exterior')
      );
      const picturesInterior = data.documents.filter((doc) =>
        doc.fileKey.includes('photos_of_the_interior')
      );

      const exteriorFiles = await Promise.all(
        picturesExterior.map((doc) =>
          createFileFromJSON(doc, dispatchAPI, message)
        )
      );

      const interiorFiles = await Promise.all(
        picturesInterior.map((doc) =>
          createFileFromJSON(doc, dispatchAPI, message)
        )
      );

      if (
        exteriorFiles.every((file) => file && file.url) &&
        interiorFiles.every((file) => file && file.url)
      ) {
        setPhotosExterior(exteriorFiles);
        setPhotosInterior(interiorFiles);
      } else {
        message('Some files could not be processed.');
      }
    } catch (e) {
      message(e);
    }
  }, [baseUrl, id, dispatchAPI, message]);

  useEffect(() => {
    (async () => {
      await getPhotos();
    })();
  }, []);

  return (
    <Row gap={[24, 16]}>
      <Col span={12}>
        <Typography.Title level={5}>
          {t('properties.form.photos_of_the_exterior')}
        </Typography.Title>
        {photosExterior.length > 0 && (
          <Flex gap="small" wrap="wrap">
            {photosExterior.map((picture) => (
              <Image
                key={picture._id}
                width={100}
                height={100}
                src={picture.url}
              />
            ))}
          </Flex>
        )}
      </Col>
      <Col span={12}>
        <Typography.Title level={5}>
          {t('properties.form.photos_of_the_interior')}
        </Typography.Title>
        {photosInterior.length > 0 && (
          <Flex gap="small" wrap="wrap">
            {photosInterior.map((picture) => (
              <Image
                key={picture._id}
                width={100}
                height={100}
                src={picture.url}
              />
            ))}
          </Flex>
        )}
      </Col>
    </Row>
  );
};

Photos.propTypes = {
  baseUrl: PropTypes.string
};

Photos.defaultProps = {
  baseUrl: undefined
};
