import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import { FileExclamationOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});

  const renderAddress = (text, record) => {
    const { number, street, additional } = record || {};
    return number || street || additional
      ? `${number || ''} ${street || ''} ${additional || ''}`
      : '-';
  };

  const allColumns = [
    {
      title: t('properties.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('golfs.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('golfs.form.contract'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('golfs.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? moment(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('golfs.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? moment(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('golfs.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date
          ? moment(validity_end_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('golfs.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      sorter: true
    },
    {
      title: t('golfs.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      sorter: true
    },
    {
      title: t('golfs.form.golf_name'),
      key: 'golf_name',
      dataIndex: 'golf_name',
      sorter: true
    },
    {
      title: t('golfs.form.address'),
      key: 'address',
      sorter: true,
      render: renderAddress
    },
    {
      title: t('golfs.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['company', 'zip_code'],
      sorter: true
    },
    {
      title: t('golfs.form.city'),
      key: 'city',
      dataIndex: ['company', 'city'],
      sorter: true
    },
    {
      title: t('properties.form.country'),
      key: 'country',
      dataIndex: ['country'],
      sorter: true
    },
    {
      title: t('golfs.form.total_insured_developed_area'),
      key: 'total_insured_developed_area',
      dataIndex: 'total_insured_developed_area',
      sorter: true
    },

    {
      title: t('golfs.form.workforce_full_time_equivalent'),
      key: 'workforce_full_time_equivalent',
      dataIndex: 'workforce_full_time_equivalent',
      sorter: true
    },
    {
      title: t('golfs.form.company_registration_number'),
      key: 'company_registration_number',
      dataIndex: 'company_registration_number',
      sorter: true
    },
    {
      title: t('golfs.form.content'),
      key: 'content',
      dataIndex: 'content',
      sorter: true
    },
    {
      title: t('golfs.form.revenue'),
      key: 'previous_year_revenue',
      dataIndex: 'previous_year_revenue',
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/golfs-configs/${companyId}`
      });
      setConfig(data[0]);
    } catch (e) {
      message(e);
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return filteredColumns || [];
};
