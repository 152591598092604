/**
 * `handleFileTypeChange` is a function that handles changes to the type of a specific file. It updates the file's configuration accordingly.
 *
 * @function
 * @param {Object} file - Object containing the file information.
 * @param {string} selectedFileKey - The key of the selected file.
 * @param {Array} filesConfiguration - The current files configuration.
 * @param {Function} setFilesConfiguration - Function to set the new files configuration.
 * @returns {void} No return value.
 */
export const handleFileTypeChange = (
  file,
  selectedFileKey,
  filesConfiguration,
  setFilesConfiguration
) => {
  const updatedFileIndexAndTypeList = [
    ...filesConfiguration,
    {
      name: file.name,
      fileKey: selectedFileKey,
      source: 'dragger',
      type: file.type
    }
  ];
  setFilesConfiguration(updatedFileIndexAndTypeList);
};
