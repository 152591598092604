export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta',
  DEVELOPER: 'orange'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const WFLStatus = {
  INITIATED: 'grey',
  PENDING: 'orange',
  VALIDATED: 'green'
};

export const templateStatus = {
  'CREATING-PROCESS': '#FADB14',
  VALIDATED: '#2DE180'
};

export const constructionStatus = {
  SUBSCRIPTION: 'geekblue',
  AWAITING_CUSTOMER_RESPONSE: 'orange',
  AWAITING_COMPANY_RESPONSE: 'orange',
  AWAIITING_CONTRACT_START: 'yellow',
  RUNNING_CONTRACT: 'purple',
  RECEPTION: 'green',
  AMENDMENT_REQUEST: 'violet'
};
export const claimStatus = {
  RECEIVED_DECLARATION: 'green',
  INFORMATIONS_WAITING: 'orange',
  EXPERTISE_IN_PROGRESS: 'geekblue',
  UNDERSTUDY_COMPENSATION: 'blue',
  APPEAL: 'magenta',
  AWAITING_CLOSING: 'yellow',
  CLOSED: 'red'
};

export const subscriptionStatus = {
  request_received: 'geekblue',
  additional_request: 'cyan',
  insurance_exchange: 'orange',
  rejected: 'red',
  accepted: 'green'
};
