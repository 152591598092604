import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, Skeleton, Row, Col, Card, Popconfirm, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  SyncOutlined,
  FileAddOutlined,
  MailOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useListContent } from './listContentConstructionInsurance';
import { useListContentAmendment } from './Amendment/listContentAmendmentMainInfos';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { routes } from '../../../../utils/constants/adminRoutes';
import { Documents } from '../../../components/Documents';
import { StepperConstructionInsurance } from './StepperConstructionInsurance';
import { MailingConstructionEndingDocsRequest } from '../../../Mailing/Modals/MailingConstructionEndingDocsRequest';
import { MailingButton } from '../../../Mailing/MailingButton';
import { ClaimButton } from '../../../components/ClaimButton';
import { ShowAmendment } from './Amendment/ShowAmendment';

const iconSize = 18;

/**
 * The `ConstructionInsurancehow` component displays details about a specific automission.
 *
 * The component fetches and displays comprehensive details of an automission, identified by its ID.
 * It provides capabilities to edit or delete the current automission and to view associated documents.
 *
 * It uses the `useParams` hook to get the ID of the automission from the current route.
 * The `useNavigate` hook is used for navigation upon certain actions (e.g., after deleting the automission).
 * It also incorporates translation capabilities with the `useTranslation` hook.
 *
 * @component
 *
 * @returns {JSX.Element} The rendered `ConstructionInsurancehow` component which displays the automission's details, documents, and associated drivers.
 */

export const ConstructionInsuranceShow = () => {
  const draggerFilesKeysMandatory = [
    'insurance_company_form',
    'construction_start_statement_document_CERFA',
    'construction_permit_or_declaration_of_work',
    'contracting_owner_tied_companies_and_subcontractors',
    'project_management_agreement',
    'plans_and_technical_documents',
    'initial_report_from_technical_controller',
    'soil_test_report',
    'detailed_description_of_the_work_on_existing_premises',
    'construction_schedule',
    'RICT',
    'uptodate_companies_attestation_at_construction_start_statement_date'
  ];
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [constructionInsurance, setConstructionInsurance] = useState();
  const [templateMail, setTemplateMail] = useState();
  const [isModalMailingOpen, setIsModalMailingOpen] = useState(false);
  const [hasCurrentAmendment, setHasCurrentAmendment] = useState(false);
  const [hasAmendmentRequest, setHasAmendmentRequest] = useState(false);
  const [hasRefusedAmendment, setHasRefusedAmendment] = useState(false);
  const [currentAmendment, setCurrentAmendment] = useState(null);
  const [previousAmendments, setPreviousAmendments] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [toOriginalEntry, setToOriginalEntry] = useState(false);
  const navigate = useNavigate();
  const listContent = useListContent(constructionInsurance);
  const listContentAmendment = useListContentAmendment(currentAmendment || {});

  const getConstructionInsurance = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/constructions/${id}?populate=customer_manager,unit_manager,contract,entity,entity.company,site,company_Id,documents,amendments,refused_amendments,current_amendment,amendment_request`
      });
      if (data?.amendments && data?.amendments.length > 1) {
        setPreviousAmendments(
          data?.amendments
            .filter(
              (amendment) => amendment?._id !== data?.current_amendment._id
            )
            .map((amendment) => amendment)
        );
      }
      if (data?.refused_amendments && data?.refused_amendments?.length > 0) {
        setHasRefusedAmendment(
          data?.refused_amendments.map((amendment) => amendment)
        );
      }
      if (data) {
        const { current_amendment, contract, entity, site } = data;
        setCurrentAmendment({
          ...current_amendment,
          contract,
          entity,
          site
        });
      }
      setHasCurrentAmendment(data?.current_amendment);
      setConstructionInsurance(data);
      setHasAmendmentRequest(data?.amendment_request);
      setLoaded(true);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const getTemplateMail = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/templates-mails/name/ConstructionEndingDocsRequest?populate=documents,documents.file`
      });
      setTemplateMail(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deleteConstruction = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/constructions/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const gotoOriginalEntry = () => {
    setToOriginalEntry(!toOriginalEntry);
  };

  useEffect(() => {
    (async () => {
      await getConstructionInsurance();
      await getTemplateMail();
    })();
  }, [getConstructionInsurance, forceRefresh]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${
          constructionInsurance?.unit_counter || 'xxxx'
        } - ${t('construction.title')}       
        ${constructionInsurance?.construction_site_name || ''}`}
        extra={
          <Flex wrap="wrap" gap="small" justify="end">
            <Link
              to={{
                pathname: `${routes.PROGRAMMES}/construction-insurance/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Button>
              {`${t('buttons.generate_attestation')} `}
              <SyncOutlined style={{ fontSize: iconSize }} />
            </Button>
            <MailingButton
              asset={constructionInsurance}
              templateName="InsuranceAttestation"
              tradKey="sending_attestation"
              resource="Construction"
              baseUrl="constructions"
            />
            <ClaimButton
              id={id}
              url="construction-insurance"
              claimType="construction"
            />
            <MailingButton
              asset={constructionInsurance}
              templateName="Blank"
              tradKey="specific_demand"
              resource="Construction"
              baseUrl="constructions"
            />
            {constructionInsurance?.status !== 'AMENDMENT_REQUEST' && (
              <Link
                to={{
                  pathname: `${routes.PROGRAMMES}/construction-insurance/amendment/create/${id}`
                }}
              >
                <Button>
                  {`${t('buttons.amendment')} `}
                  <FileAddOutlined />
                </Button>
              </Link>
            )}
            {constructionInsurance?.status === 'RUNNING_CONTRACT' && (
              <Button onClick={() => setIsModalMailingOpen(true)}>
                <MailOutlined />
                {`${t('buttons.construction_ending_docs_request')} `}
              </Button>
            )}
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteConstruction}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Flex>
        }
      />
      {loaded && (
        <ContentCustom>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Row justify="center" gutter={[16, 16]}>
              <StepperConstructionInsurance
                constructionInsurance={constructionInsurance}
                id={id}
              />
              <Col xs={24} xl={12}>
                <Card>
                  {hasCurrentAmendment && !toOriginalEntry ? (
                    <>
                      <DescriptionList
                        data={listContentAmendment}
                        translate
                        layout="vertical"
                        extra={`${t(`amendments.form.number`)} ${
                          currentAmendment?.amendment_number
                        }`}
                        title={t('amendments.form.amendment_main_informations')}
                      />
                      <Button onClick={() => gotoOriginalEntry()}>
                        {t('amendments.form.to_original_entry')}
                      </Button>
                    </>
                  ) : (
                    <>
                      <DescriptionList
                        data={listContent}
                        translate
                        layout="vertical"
                        title={t('constructions.form.main_informations')}
                      />
                      {hasCurrentAmendment && (
                        <Button onClick={() => gotoOriginalEntry()}>
                          {t('amendments.form.to_current_amendment')}
                        </Button>
                      )}
                    </>
                  )}
                </Card>
                {hasAmendmentRequest && (
                  <ShowAmendment
                    amendment={hasAmendmentRequest}
                    title="request"
                    setForceRefresh={setForceRefresh}
                    forceRefresh={forceRefresh}
                    constructionInsuranceId={id}
                    isRequest
                  />
                )}
                {hasCurrentAmendment && (
                  <ShowAmendment
                    amendment={currentAmendment}
                    title="current"
                    constructionInsuranceId={id}
                  />
                )}
                {previousAmendments &&
                  previousAmendments
                    .sort((a, b) =>
                      dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1
                    )
                    .map((amendment) => (
                      <ShowAmendment
                        amendment={amendment}
                        title="previous"
                        key={amendment._id}
                        constructionInsuranceId={id}
                      />
                    ))}
                {hasRefusedAmendment &&
                  hasRefusedAmendment
                    .sort((a, b) =>
                      dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1
                    )
                    .map((amendment) => (
                      <ShowAmendment
                        amendment={amendment}
                        title="refused"
                        key={amendment._id}
                        constructionInsuranceId={id}
                      />
                    ))}
              </Col>
              <Col xs={24} xl={12}>
                <Documents
                  idWithoutParams={id}
                  baseUrl="constructions"
                  resource="Construction"
                  draggerFilesKeysMandatory={draggerFilesKeysMandatory}
                />
              </Col>
            </Row>
            <MailingConstructionEndingDocsRequest
              isModalMailingOpen={isModalMailingOpen}
              setIsModalMailingOpen={setIsModalMailingOpen}
              templateMail={templateMail}
              idWithoutParams={id}
              datas={constructionInsurance}
            />
          </Skeleton>
        </ContentCustom>
      )}
    </>
  );
};
