import { Route, Routes } from 'react-router-dom';
import { ListFaculties } from './ListFaculties';
import { ShowFaculties } from './ShowFaculties';
import { Exception } from '../../../../components';

export const FacultiesRouter = () => (
  <Routes>
    <Route path="show/:id" element={<ShowFaculties />} />
    <Route index element={<ListFaculties />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
