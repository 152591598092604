import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Card, Checkbox, Col, Divider, InputNumber, Row } from 'antd';
import { PageHeaderCustom, ContentCustom } from '../../../../components';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * @component
 * PremiumCalculator component calculates premium costs based on user inputs and rates.
 * @returns {JSX.Element} The JSX elements that make up the PremiumCalculator component.
 */
export const PremiumCalculator = () => {
  const { t } = useTranslation();
  const { company, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [rates, setRates] = useState({});
  const [calculation, setCalculation] = useState({});
  const [totalPremiumCosts, setTotalPremiumCosts] = useState(0);

  const getRates = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rates/company/${company}`
      });
      setRates(data);
    } catch (e) {
      message(e);
    }
  };

  const handleChange = async (value) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/rates/calculator/${company}`,
        body: { globalCost: value }
      });
      setCalculation(data.premiumCosts);
      setTotalPremiumCosts(data.totalPremiumCosts);
    } catch (e) {
      message(e);
    }
  };

  const onCheck = (checked) => {
    if (!checked.target.checked) {
      const newTotal = totalPremiumCosts - calculation.civil_liability_rmco;
      setTotalPremiumCosts(newTotal);
    } else {
      const newTotal =
        totalPremiumCosts + parseFloat(calculation.civil_liability_rmco);
      setTotalPremiumCosts(newTotal);
    }
  };

  useEffect(() => {
    (async () => {
      await getRates();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom title={`${t('rates.title')}`} />
      <ContentCustom>
        <Col span={15}>
          <Card>
            <Row justify="start">
              <Col style={{ display: 'flex', alignItems: 'center' }} span={8}>
                {t('rates.form.overall_cost_construction_excluding_tax')}
              </Col>
              <Col>
                <InputNumber
                  controls={false}
                  addonAfter="€"
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Card>

          <Card>
            <Row justify="center">
              {t('rates.form.civil_liability_insurance_rmco')}
            </Row>
          </Card>
          <Row justify="space-between">
            <Col span={8}>
              <Checkbox defaultChecked onChange={onCheck}>
                {t('rates.form.civil_liability_rmco')}
              </Checkbox>
            </Col>
            <Col>{rates.civil_liability_rmco} %</Col>
            <Col>{calculation.civil_liability_rmco} €</Col>
          </Row>
          <Divider />
          <p style={{ textAlign: 'right', fontStyle: 'italic' }}>
            {t(
              'rates.form.excluding_natural_disasters_and_terrorist_attack_guarantee'
            )}
          </p>

          <Card>
            <Row justify="center">{t('rates.form.work_damage')}</Row>
          </Card>
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.property_damage')}</Col>
            <Col>{rates.work_damage} %</Col>
            <Col>{calculation.work_damage} €</Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.risks')}</Col>
            <Col>{rates.risks} %</Col>
            <Col>{calculation.risks} €</Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.maintenance_visit')}</Col>
            <Col>{rates.maintenance_visit} %</Col>
            <Col>{calculation.maintenance_visit} €</Col>
          </Row>
          <Divider />
          <p style={{ textAlign: 'right', fontStyle: 'italic' }}>
            {t(
              'rates.form.excluding_natural_disasters_and_terrorist_attack_guarantee'
            )}
          </p>

          <Card>
            <Row justify="center">{t('rates.form.construction_insurance')}</Row>
          </Card>
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.mandatory_damage')}</Col>
            <Col>{rates.mandatory_damage} %</Col>
            <Col>{calculation.mandatory_damage} €</Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.equipment_work')}</Col>
            <Col>{rates.equipment_work} %</Col>
            <Col>{calculation.equipment_work} €</Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.non_material_damages')}</Col>
            <Col>{rates.non_material_damages} %</Col>
            <Col>{calculation.non_material_damages} €</Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.existing_damages')}</Col>
            <Col>{rates.existing_damages} %</Col>
            <Col>{calculation.existing_damages} €</Col>
          </Row>
          <Divider />
          <p style={{ textAlign: 'right', fontStyle: 'italic' }}>
            {t('rates.form.rates_excluding_taxes')}
          </p>

          <Card>
            <Row justify="center">{t('rates.form.damage_guarantee')}</Row>
          </Card>
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.mandatory_guarantee')}</Col>
            <Col>{rates.mandatory_guarantee} %</Col>
            <Col>{calculation.mandatory_guarantee} €</Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.proper_functioning')}</Col>
            <Col>{rates.proper_functioning} %</Col>
            <Col>{calculation.proper_functioning} €</Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.after_non_material_damages')}</Col>
            <Col>{rates.after_non_material_damages} %</Col>
            <Col>{calculation.after_non_material_damages} €</Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={8}>{t('rates.form.after_existing_damages')}</Col>
            <Col>{rates.after_existing_damages} %</Col>
            <Col>{calculation.after_existing_damages} €</Col>
          </Row>
          <Divider />

          <Col
            justify="space-between"
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'fixed',
              bottom: 0,
              backgroundColor: 'white',
              padding: '20px 20px'
            }}
          >
            <Row span={8} style={{ fontSize: '20px' }}>
              {t('rates.form.total_premium')} {totalPremiumCosts} €
            </Row>
            <Row style={{ fontStyle: 'italic' }}>
              {t('rates.form.total_excluding_management_fees')}
            </Row>
          </Col>
        </Col>
      </ContentCustom>
    </>
  );
};
