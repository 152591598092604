import { Card, List, Spin, Badge } from 'antd';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Notification, UserMultiple } from '@carbon/icons-react';
import { handleNotifMenuItemLink } from './handleNotificationMenuLink';

export const Alerts = ({
  notifications,
  t,
  dispatchAPI,
  user,
  forceRefresh,
  setForceRefresh,
  message,
  loading,
  vehicule,
  golves,
  transports,
  property,
  notifclass
}) => {
  const [alertsNotifications, setAlertsNotifications] = useState([]);

  const deleteNotification = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `notifications/delete/${id}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    setAlertsNotifications(
      notifications?.filter(
        (notification) =>
          notification.item.type !== null &&
          notification.item?.class === notifclass
      )
    );
  }, [notifications]);

  const filteredNotif = alertsNotifications?.map(
    (notif) => notif.consulted?.filter((id) => id === user._id).length
  );
  const numberOfNotifNotConsulted = filteredNotif?.filter(
    (item) => item === 0
  ).length;

  return (
    <Card
      title={
        <>
          {notifclass !== 'gestionnaire' ? (
            <Notification size={22} />
          ) : (
            <UserMultiple size={22} />
          )}
          {t(`notifications.supervisions_alerts.${notifclass}`)}
        </>
      }
      className="alert-card"
      extra={<Badge count={numberOfNotifNotConsulted} />}
    >
      <Spin spinning={loading}>
        <List
          dataSource={alertsNotifications}
          renderItem={(item) =>
            handleNotifMenuItemLink(
              item._id,
              item.item,
              deleteNotification,
              t,
              item.created_at,
              item.consulted,
              vehicule,
              golves,
              transports,
              property
            )
          }
        />
      </Spin>
    </Card>
  );
};

Alerts.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  vehicule: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  golves: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  transports: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  property: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  notifclass: PropTypes.string.isRequired
};
