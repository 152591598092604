import { useTranslation } from 'react-i18next';
import { useColumns } from '../utils/columnsCivilLiability';
import { useActionColumn } from '../../../components/actionColumnSubscriptionTypeContract';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * `ListCivilLiabilityExecutive` is a React component designed to list and manage light vehicles.
 * It provides filtering options by vehicle type, entity, missing documents and site.
 * The component fetches the data based on the filters applied and displays it in a tabular format.
 *
 * @component
 * @returns {ReactElement} Rendered `ListCivilLiabilityExecutive` component.
 */

export const ListCivilLiabilityExecutive = () => {
  const { company } = useAuthContext();
  const columns = useColumns();
  const actionColumn = useActionColumn();
  const { t } = useTranslation();

  return (
    <ListResource
      extraQuery={`company_id=${company}&civil_liability_type=CIVIL_LIABILITY_EXECUTIVE`}
      resourceName="civil-liability"
      exportUrl={`exports/company/${company}/sub-programme/CIVIL_LIABILITY_EXECUTIVE`}
      importUrl={`imports-transports/company/${company}/sub-programme/CIVIL_LIABILITY_EXECUTIVE`}
      tradKey="civil_liability_executive"
      exportTemplateUrl="export-templates/template/civil-liability-executive"
      columns={[...columns, ...actionColumn]}
      noCreateButton
      populate="contract,company_id"
      sheetName={t('civil-liability-executive.title')}
      headers={[]}
      editAction={false}
      showAction={false}
      duplicateAction={false}
      printAction={false}
      deleteAction={false}
      withUploadButton={false}
    />
  );
};
