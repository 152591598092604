import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMailingFields } from './MailingFields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerSendMail';
import 'react-quill/dist/quill.snow.css';

export const MailingModalExpertVehicle = ({
  isModalMailingOpen,
  setIsModalMailingOpen,
  idWithoutParams,
  templateMail,
  recipients,
  claim
}) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState();
  const [mailObject, setMailObject] = useState();
  const [templateReLoaded, setTemplateReLoaded] = useState(false);
  const { mailingFields } = useMailingFields(
    editorState,
    setEditorState,
    mailObject
  );
  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        recipient: `${data?.expert_first_name} ${data?.expert_last_name}`,
        recipient_email_address: data.email,
        sender: `${claim?.unit_manager?.first_name} ${claim?.unit_manager?.last_name}`,
        sender_email_address: claim?.unit_manager?.email,
        mail_object: mailObject && mailObject,
        template_content: editorState && editorState
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };
  const resetEditorContent = () => {
    setTemplateReLoaded(!templateReLoaded);
  };
  useEffect(() => {
    if (templateMail[0]) {
      const processedTemplateContent = templateMail[0]?.template_content
        .replace('{title}', t(`claims.experts.${recipients?.title}`))
        .replace('{expert_last_name}', recipients?.expert_last_name)
        .replace('{claim_number}', claim?.claim_number)
        .replace('{vehicle_brand}', claim?.entry_id?.vehicle_brand)
        .replace('{vehicle_model}', claim?.entry_id?.vehicle_model)
        .replace(
          '{registration_or_serial_number}',
          claim?.entry_id?.registration_or_serial_number
        )
        .replace('{garage_informations}', claim?.garage_informations);

      setMailObject(templateMail[0]?.object);
      setEditorState(processedTemplateContent);
    }
  }, [templateMail, claim]);

  return (
    <Modal
      footer={null}
      title={t('customerinvoices.show.modals.reminder')}
      open={isModalMailingOpen}
      onOk={() => setIsModalMailingOpen(false)}
      onCancel={() => setIsModalMailingOpen(false)}
      className="mail-modal"
    >
      {templateMail && editorState && mailObject && (
        <>
          <CreateUpdateContainer
            fields={mailingFields}
            purpose="edit"
            resource="Expert"
            baseUrl="experts"
            tradKey="mailing"
            config={config}
            withFilesManager={false}
            WithPageHeaderCustom={false}
            idWithoutParams={idWithoutParams}
            templateReLoaded={templateReLoaded}
          />
          <Button
            onClick={() => {
              resetEditorContent();
            }}
          >
            {t('buttons.reset_to_base_editor_content')}
          </Button>
        </>
      )}
      <Button type="link" onClick={() => setIsModalMailingOpen(false)}>
        {t('buttons.cancel')}
      </Button>
    </Modal>
  );
};

MailingModalExpertVehicle.propTypes = {
  isModalMailingOpen: PropTypes.bool.isRequired,
  setIsModalMailingOpen: PropTypes.func.isRequired,
  idWithoutParams: PropTypes.string,
  customFormInstance: PropTypes.shape({}),
  templateMail: PropTypes.arrayOf(
    PropTypes.shape({
      template_content: PropTypes.string,
      object: PropTypes.string,
      _id: PropTypes.string
    })
  ),
  recipients: PropTypes.shape({
    title: PropTypes.string,
    expert_last_name: PropTypes.string
  }),
  claim: PropTypes.shape({
    unit_manager: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string
    }),
    claim_number: PropTypes.string,
    garage_informations: PropTypes.string,
    entry_id: PropTypes.shape({
      vehicle_brand: PropTypes.string,
      vehicle_model: PropTypes.string,
      registration_or_serial_number: PropTypes.string,
      _id: PropTypes.string
    })
  })
};

MailingModalExpertVehicle.defaultProps = {
  customFormInstance: {},
  templateMail: {},
  idWithoutParams: null,
  recipients: {},
  claim: {}
};
