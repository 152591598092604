import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Divider } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { CreateEditSelect } from './CreateEditSelect';
import { useVehicleFields } from './vehicle-settingsFields';
import { usePropertiesFields } from './properties-settingsFields';
import { useGolfsFields } from './golfs-settingsFields';
import { useOtherVehiclesFields } from './other-vehicles-settingsFields';
import { useAutomissionsFields } from './automissions-settingsFields';
import { useTransportsFields } from './transports-settingsFields';
import { useConstructionsFields } from './constructions-settingsFields';

/**
 * `Settings` is a React component that renders a settings interface allowing users
 * to view and edit configurations related to various resources like properties, golf, vehicles, etc.
 * It leverages the `useTranslation` hook to support internationalization and the `CreateEditSelect`
 * component to handle creation and editing functionalities.
 *
 * The component structure includes:
 * - A page header displaying the settings title.
 * - A divider component with a settings description.
 * - A collapse/accordion component containing each of the setting categories.
 *
 * Each setting category (like properties, golf, vehicles) is represented by a collapsible panel,
 * and the actual editing interface is handled by the `CreateEditSelect` component.
 *
 * @component
 * @returns {JSX.Element} - A rendered `Settings` component.
 */

export const Settings = () => {
  const { t } = useTranslation();
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();

  const { otherVehiclesFields } = useOtherVehiclesFields();
  const { vehiclesFields } = useVehicleFields();
  const { propertyFields } = usePropertiesFields();
  const { golfFields } = useGolfsFields();
  const { automissionsFields } = useAutomissionsFields();
  const { transportsFields } = useTransportsFields();
  const { constructionsFields } = useConstructionsFields();

  const [fleetDisplay, setFleetDisplay] = useState(false);
  const [transportDisplay, setTransportDisplay] = useState(false);
  const [constructionDisplay, setConstructionDisplay] = useState(false);
  const [golfDisplay, setGolfDisplay] = useState(false);
  const [propertyDisplay, setPropertyDisplay] = useState(false);

  const items = [
    propertyDisplay && {
      key: '1',
      label: t('settings.property'),
      children: (
        <CreateEditSelect
          fields={propertyFields}
          baseUrl="properties-configs"
          resource="properties"
        />
      )
    },
    golfDisplay && {
      key: '2',
      label: t('settings.golf'),
      children: (
        <CreateEditSelect
          fields={golfFields}
          baseUrl="golfs-configs"
          resource="golfs"
        />
      )
    },
    fleetDisplay && {
      key: '3',
      label: t('settings.light_vehicles_fleet'),
      children: (
        <CreateEditSelect
          fields={vehiclesFields}
          baseUrl="vehicles-light-configs"
          resource="vehicles"
        />
      )
    },
    fleetDisplay && {
      key: '4',
      label: t('settings.heavy_vehicles_fleet'),
      children: (
        <CreateEditSelect
          fields={vehiclesFields}
          baseUrl="vehicles-heavy-configs"
          resource="vehicles"
        />
      )
    },
    fleetDisplay && {
      key: '5',
      label: t('settings.other_vehicles_fleet'),
      children: (
        <CreateEditSelect
          fields={otherVehiclesFields}
          baseUrl="vehicles-other-configs"
          resource="vehicles"
        />
      )
    },
    transportDisplay && {
      key: '6',
      label: t('settings.automissions'),
      children: (
        <CreateEditSelect
          fields={automissionsFields}
          baseUrl="automissions-configs"
          resource="vehicles"
        />
      )
    },
    transportDisplay && {
      key: '7',
      label: t('settings.transport'),
      children: (
        <CreateEditSelect
          fields={transportsFields}
          baseUrl="advalorem-configs"
          resource="transports"
        />
      )
    },
    constructionDisplay && {
      key: '8',
      label: t('settings.construction'),
      children: (
        <CreateEditSelect
          fields={constructionsFields}
          baseUrl="constructions-configs"
          resource="constructions"
        />
      )
    }
  ].filter(Boolean);

  const getProgrammesToDisplay = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `contracts/company/${company}`
      });
      const { programmesToDisplay } = data;
      setFleetDisplay(programmesToDisplay.includes('FLEET'));
      setGolfDisplay(programmesToDisplay.includes('GOLF'));
      setPropertyDisplay(programmesToDisplay.includes('PROPERTY'));
      setTransportDisplay(programmesToDisplay.includes('TRANSPORT'));
      setConstructionDisplay(programmesToDisplay.includes('CONSTRUCTION'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    (async () => {
      await getProgrammesToDisplay();
    })();
  }, [company]);

  return (
    <>
      <PageHeaderCustom title={t('settings.title')} />
      <ContentCustom>
        <Divider orientation="left">{t('settings.description')}</Divider>
        <Collapse accordion items={items} />
      </ContentCustom>
    </>
  );
};
