import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useListContent } from './utils/listContentAutomissions';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { routes } from '../../../../utils/constants/adminRoutes';
import { Documents } from '../../../components/Documents';
import { ClaimButton } from '../../../components/ClaimButton';
import { MailingButton } from '../../../Mailing/MailingButton';

const iconSize = 20;

/**
 * The `AutomissionShow` component displays details about a specific automission.
 *
 * The component fetches and displays comprehensive details of an automission, identified by its ID.
 * It provides capabilities to edit or delete the current automission and to view associated documents.
 *
 * It uses the `useParams` hook to get the ID of the automission from the current route.
 * The `useNavigate` hook is used for navigation upon certain actions (e.g., after deleting the automission).
 * It also incorporates translation capabilities with the `useTranslation` hook.
 *
 * @component
 *
 *
 * @returns {JSX.Element} The rendered `AutomissionShow` component which displays the automission's details, documents, and associated drivers.
 */

export const AutomissionShow = () => {
  const draggerFilesKeysOverRide = [
    'collaborator_vehicle_registration_document',
    'collaborator_driving_licence'
  ];
  const draggerFilesKeysMandatory = [
    'collaborator_vehicle_registration_document',
    'collaborator_driving_licence'
  ];
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState();
  const listContent = useListContent(vehicle);

  const getVehicle = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/vehicles/${id}?populate=customer_manager,unit_manager,contract,entity,entity.company,site`
      });
      setVehicle(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getVehicle();
    })();
  }, [getVehicle]);

  const deleteVehicle = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/vehicles/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${vehicle?.unit_counter || 'xxxx'} - ${t(
          'automissions.title'
        )}
        ${vehicle?.collaborator_first_name || ''} ${
          vehicle?.collaborator_last_name || ''
        }`}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.PROGRAMMES}/automissions/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Button>
              {`${t('buttons.generate_attestation')} `}
              <SyncOutlined style={{ fontSize: iconSize }} />
            </Button>
            <MailingButton
              asset={vehicle}
              templateName="InsuranceAttestation"
              tradKey="sending_attestation"
              resource="Vehicle"
              baseUrl="vehicles"
            />
            <ClaimButton id={id} url="automissions" claimType="vehicle" />
            <MailingButton
              asset={vehicle}
              templateName="Blank"
              tradKey="specific_demand"
              resource="Vehicle"
              baseUrl="vehicles"
            />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteVehicle}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Skeleton loading={isLoading} title={0} paragraph={0} active />
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card>
                <DescriptionList
                  data={listContent}
                  translate
                  layout="vertical"
                  title={t('vehicles.form.main_informations')}
                />
              </Card>
            </Skeleton>
          </Col>
          <Col xs={24} xl={12}>
            <Documents
              idWithoutParams={id}
              baseUrl="vehicles"
              resource="Vehicle"
              draggerFilesKeysOverRide={draggerFilesKeysOverRide}
              draggerFilesKeysMandatory={draggerFilesKeysMandatory}
            />
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
