/**
 * Component containing functions for creating and organizing tree nodes.
 *
 * @function
 * @returns {object} The setNodeParent and setNodeChildren functions.
 */
export const SetNodes = () => {
  /**
   * Create a tree node object.
   *
   * @function
   * @param {string} key - Node key.
   * @param {string} title - Node title.
   * @param {string} type - Node type.
   * @param {boolean} isLeaf - Whether the node is a leaf node.
   * @param {array} children - Node children.
   * @returns {object} The created node.
   */
  const createNode = (key, title, type, isLeaf, children) => {
    const newNode = {
      key,
      title,
      type,
      isEditable: type !== 'parent' && type !== 'sub_program',
      isLeaf,
      children
    };
    return newNode;
  };

  /**
   * Organize the tree node's children based on the type.
   *
   * @function
   * @param {object} nodes - Node data.
   * @param {string} type - Node type.
   * @returns {object|null} The organized node with children.
   */
  const setNodeChildren = (nodes, type) => {
    if (!nodes || !nodes.name) return null;

    if (!nodes.sites || !nodes.sites[0].name) {
      return createNode(nodes._id, nodes.name, type, true);
    }
    const children = nodes?.sites?.map((site) =>
      setNodeChildren(site, 'sites')
    );

    if (children) {
      return createNode(nodes._id, nodes.name, type, false, children);
    }
    return createNode(nodes._id, nodes.name, type, true);
  };

  /**
   * Organize the tree node's parents based on the data.
   *
   * @function
   * @param {object} data - Node data.
   * @returns {array} The organized parent nodes.
   */
  const setNodeParent = (data) => {
    const newData = [];

    Object.keys(data).forEach((key) => {
      if (key !== 'GOLF' && key !== 'PROPERTY') {
        const dataArray = data[key];

        if (dataArray && Array.isArray(dataArray) && dataArray.length > 0) {
          const subProgramNodes = [];

          for (let i = 0; i < dataArray.length; i += 1) {
            const entities = Array.isArray(dataArray[i].entities)
              ? dataArray[i].entities
              : [dataArray[i].entities];

            if (entities[0].name !== null) {
              const children = entities.map((entity) =>
                setNodeChildren(entity, 'entities')
              );

              if (children) {
                const nodes = createNode(
                  dataArray[i].sub_program,
                  dataArray[i].sub_program,
                  'sub_program',
                  false,
                  children
                );
                subProgramNodes.push(nodes);
              } else {
                const nodes = createNode(
                  dataArray[i].sub_program,
                  dataArray[i].sub_program,
                  'sub_program',
                  true
                );
                subProgramNodes.push(nodes);
              }
            } else {
              const nodes = createNode(
                dataArray[i].sub_program,
                dataArray[i].sub_program,
                'sub_program',
                true
              );
              subProgramNodes.push(nodes);
            }
          }
          newData.push(createNode(key, key, 'parent', false, subProgramNodes));
        }
      } else {
        const dataArray = data[key];

        if (dataArray && Array.isArray(dataArray) && dataArray.length > 0) {
          const entitiesArray = dataArray.flatMap(
            (item) => item.entities || []
          );
          if (entitiesArray[0].name !== null) {
            const children = entitiesArray.map((item) =>
              setNodeChildren(item, 'entities')
            );
            const parentNode = createNode(key, key, 'parent', false, children);
            newData.push(parentNode);
            return newData;
          }
          const parentNode = createNode(key, key, 'parent', true);
          newData.push(parentNode);
        }
      }
      return null;
    });
    return newData;
  };
  return {
    setNodeParent,
    setNodeChildren
  };
};
