import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
// import { Link, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-components';
import { HomeOutlined } from '@ant-design/icons';
import { routes } from '../../utils/constants/adminRoutes';

export const PageHeaderCustom = ({ title, extra }) => {
  // const { t } = useTranslation();
  // const location = useLocation();
  // const { pathname } = location;
  // const splitPathname = pathname.split('/').filter((p) => p !== '');
  // let tmpPath;
  // const breadCrumbs = splitPathname
  //   .slice(0, splitPathname.length - 1)
  //   .map((p) => {
  //     tmpPath = `/${p}`;
  //     return {
  //       path: tmpPath,
  //       breadcrumbName: t(`breadcrumbs.${p}`)
  //     };
  //   });
  const dynamicRoutes = [
    {
      path: routes.HOME,
      breadcrumbName: <HomeOutlined />
    }
  ];

  const itemRender = (
    // eslint-disable-next-line react/prop-types
    { path, breadcrumbName, state, search, ...route },
    params,
    routesList
  ) => {
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={path}>{breadcrumbName}</span>
    ) : (
      <Link key={path} to={{ pathname: path, state, search }}>
        {breadcrumbName}
      </Link>
    );
  };

  return (
    <PageHeader
      breadcrumb={{ items: dynamicRoutes, itemRender, separator: '>' }}
      title={title}
      ghost={false}
      extra={extra}
    />
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.string.isRequired,
  extra: PropTypes.element
};

PageHeaderCustom.defaultProps = {
  extra: null
};
