import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.otherVehiclesFields - Field configurations for other vehicles types.
 */

export const useOtherVehiclesFields = () => {
  const otherVehiclesFields = [
    {
      name: ['missing_documents'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['contract_number'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['date_added'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['entity'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['sub_entity'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['site'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['address'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['postal_code'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['city'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['products'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['vehicle_brand'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['registration_or_serial_number'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['initial_traffic_release'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['vehicle_model'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['principal_driver'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['purchase_date_or_lease_start_date'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['mileage'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['observations'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    }
  ];

  return { otherVehiclesFields };
};
