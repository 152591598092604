import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../../contexts/AuthContext';

import { useFields } from './fieldsAdValorem';

/**
 * `AdValoremCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * Note: This component does not support managing files.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const AdValoremCreateUpdate = ({ purpose }) => {
  const [selectedType, setSelectedType] = useState();
  const { fields, isFieldsLoading, base64 } = useFields(
    setSelectedType,
    selectedType
  );
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();
  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        date_added: data.created_at && dayjs(data.created_at),
        desired_effective_date:
          data.desired_effective_date && dayjs(data.desired_effective_date),
        departure_date: data.departure_date && dayjs(data.departure_date),
        estimated_delivery_date:
          data.estimated_delivery_date && dayjs(data.estimated_delivery_date)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_Id: company,
        programmes_types: 'TRANSPORT',
        sub_programme: 'AD_VALOREM'
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...(base64 ? { photo: base64 } : {})
      })
    }
  };
  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      title={t('ad-valorem.title')}
      baseUrl="transports"
      resource="transports"
      config={config}
      extraTitle={<Row justify="center">{`${t('folder')} ${companyName}`}</Row>}
      withFilesManager={false}
    />
  );
};

AdValoremCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
