import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});

  const renderAddress = (text, record) => {
    const { number, street, additional } = record || {};
    return number || street || additional
      ? `${number || ''} ${street || ''} ${additional || ''}`
      : '-';
  };

  const allColumns = [
    {
      title: t('properties.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('properties.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('properties.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('properties.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? moment(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('properties.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? moment(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('properties.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date
          ? moment(validity_end_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('properties.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      sorter: true
    },
    {
      title: t('properties.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      sorter: true
    },
    {
      title: t('properties.form.assets_addresses'),
      key: 'assets_addresses',
      sorter: true,
      render: renderAddress
    },
    {
      title: t('properties.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['postal_code'],
      sorter: true
    },
    {
      title: t('properties.form.city'),
      key: 'city',
      dataIndex: ['city'],
      sorter: true
    },
    {
      title: t('properties.form.country'),
      key: 'country',
      dataIndex: ['country'],
      sorter: true
    },
    {
      title: t('properties.form.total_insured_developed_area'),
      key: 'total_insured_developed_area',
      dataIndex: ['total_insured_developed_area'],
      sorter: true
    },
    {
      title: t('properties.form.asset'),
      key: 'asset_name',
      dataIndex: 'asset_name',
      sorter: true
    },
    {
      title: t('properties.form.legal_status'),
      key: 'legal_status',
      dataIndex: ['legal_status'],
      sorter: true,
      render: (legal_status) =>
        (legal_status && t(`properties.form.${legal_status}`)) || '-'
    },
    {
      title: t('properties.form.typology'),
      key: 'typology',
      dataIndex: 'typology',
      sorter: true,
      render: (typology) =>
        (typology && t(`properties.form.${typology}`)) || '-'
    },
    {
      title: t('properties.form.tenant_occupation'),
      key: 'tenant_occupation',
      dataIndex: 'tenant_occupation',
      sorter: true
    },
    {
      title: t('properties.form.last_and_first_name_of_tenant'),
      key: 'last_and_first_name_of_tenant',
      dataIndex: 'last_and_first_name_of_tenant',
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/properties-configs/${companyId}`
      });
      setConfig(data[0]);
    } catch (e) {
      message(e);
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return filteredColumns || [];
};
