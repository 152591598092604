// TODO: Uncomment when ready

import { Link } from 'react-router-dom';
import { Flex } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Flood } from '@carbon/icons-react';

const iconSize = 18;

/**
 * `useActionColumn` is a hook that returns a configuration array for rendering an action column.
 * The action column consists of several icons used for viewing a document, editing a document,
 * and other associated actions. Each icon has an associated action, such as navigation or
 * triggering a functionality.
 *
 * @hook
 * @returns {Array} An array containing configuration for the action column. The configuration includes
 * how to render the column based on the record provided.
 */

const formatUrlPart = (str) => str.toLowerCase().replace(/_/g, '-');

const extractSubProgrammes = (record) => {
  const {
    fleet_sub_programmes,
    civil_liability_sub_programmes,
    transport_sub_programmes,
    construction_sub_programmes
  } = record.contract || {};

  const subProgrammesParts = [
    ...(fleet_sub_programmes || []),
    ...(civil_liability_sub_programmes || []),
    ...(transport_sub_programmes || []),
    ...(construction_sub_programmes || [])
  ];

  return subProgrammesParts
    .filter((part) => typeof part === 'string')
    .map(formatUrlPart)
    .join('/');
};

export const useActionColumn = () => [
  {
    key: 'action',
    align: 'right',
    render: (record) => {
      const subProgrammesString = extractSubProgrammes(record);

      return (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          <Link to={`/programmes/${subProgrammesString}/show/${record?._id}`}>
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Link to={`/claims/${subProgrammesString}/create/${record?._id}`}>
            <Flood size={iconSize} />
          </Link>
        </Flex>
      );
    }
  }
];
