import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  useListContent,
  useListContentAddresses
} from './utils/listContentProperty';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { Documents } from '../../components/Documents';
import { routes } from '../../../utils/constants/adminRoutes';
import { Photos } from '../../components/Photos';
import { PropertyContext } from './PropertyContext';
import { MailingButton } from '../../Mailing/MailingButton';
import { ClaimButton } from '../../components/ClaimButton';

const iconSize = 18;

/**
 * The `PropertyShow` component renders detailed information about a specific property.
 * It fetches the property data from the API using its ID and displays it using the `DescriptionList` component.
 * The component also provides options to edit or delete the property.
 *
 * @component
 * @returns {JSX.Element} Returns the detailed view of a specific property including options to edit or delete it.
 */

export const PropertyShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI, permissions, verifPermissions } = useAuthContext();
  const { generateAttestation } = useContext(PropertyContext);
  const [isLoading, setIsLoading] = useState(false);
  const [property, setProperty] = useState();
  const [edit, setEdit] = useState(true);

  const listContent = useListContent(property);
  const listContentAddresses = useListContentAddresses(property);
  const draggerFilesKeysOverRide = [
    'lease_agreements',
    'Deed_of_acquisition',
    'plans',
    'sectional_plans',
    'external_plans_or_cadastral_parcel_designations',
    'plans_Documents_for_specific_installations',
    'condominium_status',
    'statutes_of_ASL_AFUL',
    'certificates_Q4_Q5_Q17_Q18_Q19',
    'security_surveillance_contracts',
    'KBIS',
    'presentation_model',
    'current_contract',
    'loss_history_report',
    'tax_return_package',
    'other'
  ];

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions(
          '/programmes/properties/edit',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  const getProperty = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/properties/${id}?populate=customer_manager,unit_manager,entity,site,contract,company,documents`
      });

      setProperty(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getProperty();
    })();
  }, [getProperty]);

  const deleteProperty = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/Properties/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${property?.unit_counter || 'xxxx'} - ${
          property?.asset_name || ''
        } ${property?.city || ''}`}
        extra={
          <>
            {edit && (
              <Link
                to={{
                  pathname: `${routes.PROGRAMMES}/properties/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            )}
            {property?.contract?.insurance_company === 'AXA' && (
              <Button onClick={() => generateAttestation(property?._id)}>
                {`${t('buttons.generate_attestation')} `}
                <SyncOutlined style={{ fontSize: iconSize }} />
              </Button>
            )}
            <MailingButton
              asset={property}
              templateName="InsuranceAttestation"
              tradKey="sending_attestation_property"
              resource="Property"
              baseUrl="properties"
            />
            <ClaimButton id={id} url="properties" claimType="property" />
            <MailingButton
              asset={property}
              templateName="Blank"
              tradKey="specific_demand"
              resource="Property"
              baseUrl="properties"
            />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteProperty}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Skeleton loading={isLoading} title={0} paragraph={0} active />
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                    title={t('properties.form.main_informations')}
                  />
                </Card>
                <Card>
                  <DescriptionList
                    data={listContentAddresses}
                    translate
                    title={t('properties.form.assets_addresses')}
                  />
                </Card>
                <Card className="photo-card">
                  <Photos baseUrl="properties" />
                </Card>
              </Row>
            </Skeleton>
          </Col>
          <Col xs={24} xl={12}>
            <Documents
              idWithoutParams={id}
              baseUrl="properties"
              resource="Property"
              draggerFilesKeysOverRide={draggerFilesKeysOverRide}
            />
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
