import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { routes } from '../../../../utils/constants/adminRoutes';
import { useListContent } from '../ListConstentTransports';
import { MailingButton } from '../../../Mailing/MailingButton';
import { ClaimButton } from '../../../components/ClaimButton';

const iconSize = 18;

/**
 * Represents the `ShowDocument` component that displays details of a document.
 *
 * Fetches and displays a document's details based on its ID, which is derived from the current route parameters.
 * The component also provides action buttons for editing, regenerating, downloading, and sending the document.
 *
 * @component
 * @example
 * ```jsx
 * <ShowDocument />
 * ```
 */

export const ShowFreightedGoods = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState();
  const listContent = useListContent(document);
  const navigate = useNavigate();

  const getDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/transports/id/${id}`
      });
      setDocument(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deleteFreightedGoods = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/transports/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getDocument();
    })();
  }, [getDocument]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${document?.unit_counter || 'xxxx'} - ${t(
          'freighted-goods.title'
        )}`}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.DOCUMENTS}/edit/${document?.contract?.documents[0]?._id}`
              }}
            >
              <Button>
                <EditOutlined />
                {`${t('buttons.edit')} `}
              </Button>
            </Link>
            <Button>
              {`${t('buttons.generate_attestation')} `}
              <SyncOutlined style={{ fontSize: iconSize }} />
            </Button>
            <MailingButton
              asset={document}
              templateName="InsuranceAttestation"
              tradKey="sending_attestation"
              resource="Transport"
              baseUrl="transports"
            />
            <ClaimButton id={id} url="freighted-goods" claimType="transport" />
            <MailingButton
              asset={document}
              templateName="Blank"
              tradKey="specific_demand"
              resource="Transport"
              baseUrl="transports"
            />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteFreightedGoods}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Col xs={24} md={8} lg={6} xl={4} xxl={2}>
            <Skeleton
              loading={isLoading}
              title={0}
              paragraph={0}
              avatar={{ size: 80 }}
              active
            />
          </Col>
          <Col xs={24} md={16} lg={18} xl={20} xxl={22}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card>
                <DescriptionList
                  data={listContent}
                  translate
                  layout="vertical"
                  column={4}
                  title={t('transports.form.main_informations')}
                />
              </Card>
            </Skeleton>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
