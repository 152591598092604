import PropTypes from 'prop-types';
import { Tree } from 'antd';
import { useTranslation } from 'react-i18next';
import { DisplayNodesPermissions } from './DisplayNodesPermissions';
import { usePermissionsData } from '../Hooks/usePermissionsData';
import { useCheckedKeysUserTwo } from '../Hooks/useCheckedKeysUserTwo';
import { useTreeExpansion } from '../Hooks/useTreeExpansion';

const { TreeNode } = Tree;

const TreePermissionsUserTwo = ({ userId, keySearched }) => {
  const { renderTreeNodes } = DisplayNodesPermissions();
  const { t } = useTranslation();
  const { dataTree } = usePermissionsData(userId);
  const { checkedKeys, onCheck } = useCheckedKeysUserTwo(userId, dataTree);

  const { expandedKeys, isSearchBarEmpty, onExpand } = useTreeExpansion(
    keySearched,
    dataTree
  );

  return (
    <Tree
      checkable
      defaultCheckedKeys={checkedKeys}
      checkedKeys={checkedKeys}
      onCheck={(keys) => onCheck(keys, dataTree)}
      style={{ marginTop: 16 }}
      expandedKeys={expandedKeys}
      autoExpandParent={!isSearchBarEmpty}
      onExpand={onExpand}
    >
      {dataTree && (
        <TreeNode
          key={t('companies.tree.programs')}
          title={t('companies.tree.programs')}
        >
          {renderTreeNodes(dataTree)}
        </TreeNode>
      )}
    </Tree>
  );
};

export default TreePermissionsUserTwo;

TreePermissionsUserTwo.propTypes = {
  userId: PropTypes.string.isRequired,
  keySearched: PropTypes.string.isRequired
};
