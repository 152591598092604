import { useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { UserRouter } from './UserRouter';
import { AdminRouter } from './AdminRouter';
import { GestionnaitreUnitRouter } from './GestionnaireUnitRouter';
import { GestionnaireClientRouter } from './GestionnaireClientRouter';
import { useThemeContext } from '../contexts/ThemeContext';

export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { setTheme } = useThemeContext();

  useEffect(() => {
    const body = document.body;
    if (body && !user) {
      body.className = 'user';
      setTheme('user');
    }
  }, [user]);

  const dispatchRouter = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
        return <GestionnaitreUnitRouter />;
      case 'admins:SUPER-ADMIN':
        return <AdminRouter />;
      case 'users:USER':
        return <GestionnaireClientRouter />;
      case 'users:SUPER-USER':
        return <GestionnaitreUnitRouter />;
      default:
        return <UserRouter />;
    }
  };
  return dispatchRouter();
};
