import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import { FileExclamationOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});

  const allColumns = [
    {
      title: t('vehicles.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('vehicles.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('vehicles.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('vehicles.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? moment(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? moment(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date
          ? moment(validity_end_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      sorter: true
    },
    {
      title: t('vehicles.form.sub_entity'),
      key: 'sub_entity',
      dataIndex: ['sub_entity', 'name'],
      sorter: true
    },
    {
      title: t('vehicles.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      sorter: true
    },
    {
      title: t('vehicles.form.address'),
      key: 'address',
      dataIndex: ['company'],
      sorter: true,
      render: (company) =>
        company
          ? `${company.number || ''} ${company.street || ''} ${
              company.additional || ''
            }`
          : '-'
    },
    {
      title: t('vehicles.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['company', 'zip_code'],
      sorter: true
    },
    {
      title: t('vehicles.form.city'),
      key: 'city',
      dataIndex: ['company', 'city'],
      sorter: true
    },
    {
      title: t('vehicles.form.products'),
      key: 'products',
      dataIndex: 'fleet_type',
      sorter: true,
      render: (fleet_type) => fleet_type && t(`vehicles.form.${fleet_type}`)
    },
    {
      title: t('vehicles.form.collaborator_first_name'),
      key: 'collaborator_first_name',
      dataIndex: 'collaborator_first_name',
      sorter: true
    },
    {
      title: t('vehicles.form.collaborator_last_name'),
      key: 'collaborator_last_name',
      dataIndex: 'collaborator_last_name',
      sorter: true
    },
    {
      title: t('vehicles.form.vehicle_registration_number'),
      key: 'vehicle_registration_number',
      dataIndex: 'vehicle_registration_number',
      sorter: true
    },
    {
      title: t('vehicles.form.departure_time'),
      key: 'departure_time',
      dataIndex: ['departure_time'],
      render: (departure_time) =>
        departure_time ? moment(departure_time).format('HH:MM') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.start_of_the_mission'),
      key: 'start_of_the_mission',
      dataIndex: ['start_of_the_mission'],
      render: (start_of_the_mission) =>
        start_of_the_mission
          ? moment(start_of_the_mission).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.planned_end_of_the_mission'),
      key: 'planned_end_of_the_mission',
      dataIndex: ['planned_end_of_the_mission'],
      render: (planned_end_of_the_mission) =>
        planned_end_of_the_mission
          ? moment(planned_end_of_the_mission).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.journey_lenght'),
      key: 'journey_lenght',
      dataIndex: 'journey_lenght',
      sorter: true
    },
    {
      title: t('vehicles.form.departure_city'),
      key: 'departure_city',
      dataIndex: 'departure_city',
      sorter: true
    },
    {
      title: t('vehicles.form.arrival_city'),
      key: 'arrival_city',
      dataIndex: 'arrival_city',
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/automissions-configs/${companyId}`
      });
      setConfig(data[0]);
    } catch (e) {
      message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return filteredColumns || [];
};
