import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Spin, Row, Col, Card, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { ContentCustom, DescriptionList } from '../../../../components';
import TreeStructure from './TreeStructure';
import CreateUpdateTreeModal from './CreateUpdateTreeModal';
import { CompanyContextProvider } from '../../CompanyContext';
import { handleSearch } from '../utils/searchUtils';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Search } = Input;

const Organization = ({ isLoading, listContent }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [create, setCreate] = useState(true);
  const [filters, setFilters] = useState(true);

  const { permissions, verifPermissions } = useAuthContext();
  const { t } = useTranslation();
  const [keySearched, setKeySearched] = useState('');

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setCreate(
        !verifPermissions('/sites/create', permissions?.notPermittedRoutes)
      );
      setFilters(
        !verifPermissions('/sites/filters', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  return (
    <CompanyContextProvider>
      <Spin spinning={isSubmitting}>
        <CreateUpdateTreeModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setIsSubmitting={setIsSubmitting}
        />
        <Row gutter={[24, 24]} style={{ width: '100%' }}>
          <Col span={24}>
            <Card
              title={t('companies.form.company_informations')}
              style={{ width: '100%' }}
            >
              <Skeleton
                active
                loading={isLoading}
                paragraph={{ rows: 5 }}
                style={{ width: '100%' }}
              >
                <DescriptionList
                  data={listContent}
                  layout="vertical"
                  translate
                />
              </Skeleton>
            </Card>
          </Col>

          <Col span={24}>
            <Card
              title={t(`companies.form.organization`)}
              style={{ width: '100%' }}
            >
              <ContentCustom>
                <Row
                  justify="space-between"
                  gutter={[8, 16]}
                  style={{ width: '50%' }}
                >
                  {filters && (
                    <Col>
                      <Search
                        allowClear
                        placeholder={t('placeholder.search')}
                        onSearch={(value) =>
                          handleSearch(value, setKeySearched)
                        }
                      />
                    </Col>
                  )}
                  {create && (
                    <Button
                      type="add"
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      {t('companies.form.create_site')}
                    </Button>
                  )}
                </Row>
                <TreeStructure keySearched={keySearched} />
              </ContentCustom>
            </Card>
          </Col>
        </Row>
      </Spin>
    </CompanyContextProvider>
  );
};

export default Organization;

Organization.propTypes = {
  listContent: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool
};

Organization.defaultProps = {
  isLoading: false
};
