import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFields } from './fieldsOthersConstruction';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';

/**
 * Provides a component for creating or updating other construction insurance claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for other construction insurance claims.
 */
export const OthersConstructionCreateUpdate = ({ purpose }) => {
  const { dispatchAPI, company } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [otherConstruction, setOthersConstruction] = useState();
  const { fieldsInformations } = useFields();

  const draggerFilesKeysOverRide = [
    'lifting_of_reservation_report',
    'letter_formal_notice',
    'reception_report',
    'registered_mail',
    'declaration_to_insurer',
    'full_statement',
    'filing_complaint'
  ];
  const getOthersConstruction = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `constructions/${id}?populate=entity,site,contract,company_Id`
      });
      setOthersConstruction(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOthersConstruction();
    })();
  }, [id]);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        sinister_date: data.sinister_date && dayjs(data.sinister_date)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: otherConstruction?._id,
        company,
        contract: otherConstruction?.contract?._id,
        opening_date: dayjs(Date.now()),
        city: otherConstruction?.city,
        entity: otherConstruction?.contract?.entity,
        site: otherConstruction?.contract?.site,
        construction_sub_programmes: 'OTHER'
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="construction_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
    />
  );
};

OthersConstructionCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
