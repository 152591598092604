import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * `useListContent` is a custom hook for generating a list of labels and corresponding content for displaying transport information.
 *
 * @hook
 * @param {Object} data - The transport data.
 * @returns {Array} An array of label-content pairs.
 */

export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    contract,
    created_at,
    entity,
    site,
    desired_effective_date,
    customer_reference_number,
    shipper_name,
    recipient_name,
    unit_reference_number,
    departure_date,
    estimated_delivery_date,
    parcel_quantity,
    weight,
    means_of_transport,
    nature_of_the_goods,
    value_of_the_goods
  } = data;

  const {
    departure_street_number,
    departure_street_name,
    departure_additional_address,
    departure_zip_code,
    departure_city,
    departure_country
  } = data;

  const addressPartsdeparture = [
    departure_street_number,
    departure_street_name,
    departure_additional_address,
    departure_zip_code,
    departure_city,
    departure_country
  ];
  const departureAddress = addressPartsdeparture
    .filter((part) => part)
    .join(' ');

  const {
    arrival_street_number,
    arrival_street_name,
    arrival_additional_address,
    arrival_zip_code,
    arrival_city,
    arrival_country
  } = data;

  const addressPartsArrival = [
    arrival_street_number,
    arrival_street_name,
    arrival_additional_address,
    arrival_zip_code,
    arrival_city,
    arrival_country
  ];
  const arrivalAddress = addressPartsArrival.filter((part) => part).join(' ');

  const labels = [
    {
      label: 'transports.form.contract_number',
      span: 1,
      content: (contract && contract.contract_number) || '-'
    },
    {
      label: 'transports.form.insurance_company',
      span: 1,
      content: (contract && contract.insurance_company) || '-'
    },
    {
      label: 'transports.form.date_added',
      span: 1,
      content: (created_at && moment(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'transports.form.entity',
      span: 1,
      content: (entity && entity.name) || '-'
    },
    {
      label: 'transports.form.site',
      span: 1,
      content: (site && site.name) || '-'
    },
    {
      label: 'transports.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          moment(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'transports.form.unit_reference_number',
      span: 1,
      content: (unit_reference_number && unit_reference_number) || '-'
    },
    {
      label: 'transports.form.customer_reference_number',
      span: 1,
      content: (customer_reference_number && customer_reference_number) || '-'
    },
    {
      label: 'transports.form.shipper_name',
      span: 1,
      content: (shipper_name && shipper_name) || '-'
    },
    {
      label: 'transports.form.recipient_name',
      span: 1,
      content: (recipient_name && recipient_name) || '-'
    },
    {
      label: 'transports.form.departure_date',
      span: 1,
      content:
        (departure_date && moment(departure_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'transports.form.estimated_delivery_date',
      span: 1,
      content:
        (estimated_delivery_date &&
          moment(estimated_delivery_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'transports.form.parcel_quantity',
      span: 1,
      content: (parcel_quantity && parcel_quantity) || '-'
    },
    {
      label: 'transports.form.weight',
      span: 1,
      content: (weight && weight) || '-'
    },
    {
      label: 'transports.form.means_of_transport',
      span: 1,
      content:
        (means_of_transport && t(`transports.form.${means_of_transport}`)) ||
        '-'
    },
    {
      label: 'transports.form.nature_of_the_goods',
      span: 1,
      content: (nature_of_the_goods && nature_of_the_goods) || '-'
    },
    {
      label: 'transports.form.value_of_the_goods',
      span: 2,
      content: (value_of_the_goods && value_of_the_goods) || '-'
    },
    {
      label: 'transports.form.departure_location',
      span: 3,
      content: departureAddress || '-'
    },
    {
      label: 'transports.form.arrival_location',
      span: 3,
      content: arrivalAddress || '-'
    }
  ];

  return labels;
};
