import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;

/**
 * `SelectBySite` is a component that renders a dropdown with site names.
 * It fetches sites based on the provided `company` and displays them in the dropdown.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {string} props.dbKey - The database key for the item.
 * @returns {JSX.Element} The rendered `Select` component containing site names.
 */

export const SelectBySite = ({ onChange }) => {
  const { t } = useTranslation();
  const [sites, setSites] = useState([]);
  const { company, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getsites = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entities/company/${company}?populate=sites`
      });
      const allSites = data.reduce(
        (acc, currEntity) => acc.concat(currEntity.sites || []),
        []
      );
      setSites(allSites);
    } catch (e) {
      message(e);
    }
  }, [company]);

  useEffect(() => {
    (async () => {
      await getsites();
    })();
  }, [getsites]);

  return (
    <Select
      allowClear
      placeholder={t('vehicles.filter.filter_by_site')}
      showSearch
      onChange={onChange}
      filterOption={(inputValue, option) =>
        (option?.title ?? '').toLowerCase().includes(inputValue.toLowerCase())
      }
    >
      {sites &&
        sites.map((s) => (
          <Option key={s._id} value={s._id} title={s.name}>
            {s.name}
          </Option>
        ))}
    </Select>
  );
};

SelectBySite.propTypes = {
  onChange: PropTypes.func.isRequired
};
