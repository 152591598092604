import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react';
import { useColumns } from './utils/columnsGolf';
import { useActionColumn } from './utils/actionColumn';
import { ListResource } from '../../../components/ListResource/ListResource';
import { SelectByEntity } from '../../components/SelectByEntity';
import { SelectBySite } from '../../components/SelectBySite';
import { useAuthContext } from '../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../components/FilterByMissingDocuments';
import { GolfContext } from './GolfContext';

/**
 * A component that lists 'Golf' filtered by selected entity and site.
 *
 * `ListGolf` is responsible for fetching and listing 'Golf' for a
 * specific entity and site. It provides filter dropdowns (`SelectByEntity` and `SelectBySite`)
 * to allow users to select specific entity or site to filter the results.
 *
 * Once a user chooses an entity or a site, the component updates the browser URL with the
 * filtering parameters.
 *
 * @Component
 *
 * @returns {JSX.Element} The rendered `ListGolf` component with filtering capabilities.
 */

export const ListGolf = () => {
  const [entity, setEntity] = useState('');
  const [site, setSite] = useState('');
  const [isMissingDocuments, setIsMissingDocuments] = useState(false);
  const [showData, setShowData] = useState(true);
  const [edit, setEdit] = useState(true);
  const [reintegrate, setReintegrate] = useState(true);

  const { generateAttestation } = useContext(GolfContext);
  const navigate = useNavigate();
  const { company, permissions, verifPermissions } = useAuthContext();
  const { t } = useTranslation();
  const columns = useColumns();
  const actionColumn = useActionColumn(edit, generateAttestation);

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShowData(
        !verifPermissions('datas/golfs', permissions?.notPermittedRoutes)
      );
      setEdit(
        !verifPermissions(
          '/programmes/golfs/edit/',
          permissions?.notPermittedRoutes
        )
      );
      setReintegrate(
        !verifPermissions(
          'programmes/golfs/reintegrate',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  let filterParameter = `entity=${entity}`;
  const onFilterEntity = (entityId) => {
    setEntity(entityId || '');
  };

  const onFilterSite = (siteId) => {
    setSite(siteId || '');
  };

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments);
  };

  useEffect(() => {
    if (site !== '') {
      filterParameter += `__site=${site}__`;
    } else {
      filterParameter += '__';
    }
    navigate({
      pathname: '/programmes/golfs',
      search: `?f=${filterParameter}`
    });
  }, [entity, site, isMissingDocuments]);
  return (
    <ListResource
      extraQuery={`company=${company}`}
      resourceName="golfs"
      exportUrl={`exports/company/${company}/assets/programme-type/GOLF`}
      importUrl={`imports-golfs/golfs/company/${company}`}
      tradKey="golves"
      exportTemplateUrl="export-templates/template/golf"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <>
          <SelectByEntity onChange={onFilterEntity} />
          <SelectBySite onChange={onFilterSite} />
          <FilterByMissingDocuments onChange={onFilterMissingDocuments} />
        </>
      }
      populate="entity,site,contract,company"
      editAction={false}
      showAction={false}
      sheetName={t('golves.title')}
      headers={[]}
      withUploadButton={showData}
      permissionReintegrate={reintegrate}
    />
  );
};
