import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('claims.form.accounting_provision'),
      dataIndex: 'accounting_provision',
      key: 'accounting_provision',
      render: (accounting_provision) =>
        (accounting_provision && `${accounting_provision} €`) || '0 €'
    }
  ];
};
