import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Card, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';

export const CustomerSumFirstLine = ({ reload, baseUrl }) => {
  const [customerTotalFirstLine, setCustomerTotalFirstLine] = useState(0);
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();

  const getAmountFirstLine = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `${baseUrl}/total-first-line/${id}`
    });
    setCustomerTotalFirstLine(data);
  };

  useEffect(() => {
    getAmountFirstLine();
  }, [reload]);

  return (
    <Card>
      {t(`claims.form.customer_total_first_line`)} <Divider type="vertical" />
      {customerTotalFirstLine} €
    </Card>
  );
};

CustomerSumFirstLine.propTypes = {
  reload: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired
};
