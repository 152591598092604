import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * @component
 * @name ExclTaxButton
 * @description A button to change the tax type to excluding tax
 * @namespace components modules/ClaimAccountingOverview/components/ClaimAccountingOverview
 * @param {string} amountType The type of the amount
 * @param {function} setChangeDone A function to set the change done
 * @param {boolean} changeDone A boolean to know if the change is done
 * @returns {jsx} Return the jsx to display
 */

export const ExclTaxButton = ({ amountType, setChangeDone, changeDone }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { id } = useParams();

  const changeTaxType = async () => {
    try {
      await dispatchAPI('GET', {
        url: `/property-claim-accounts/tax-excluded/${id}/${amountType}`
      });
      setChangeDone(!changeDone);
    } catch (e) {
      message(e);
    }
  };

  return (
    <Button type="primary" onClick={() => changeTaxType()}>
      {t('claims.form.amounts_excluding_tax')}
    </Button>
  );
};

ExclTaxButton.propTypes = {
  amountType: PropTypes.string,
  setChangeDone: PropTypes.func,
  changeDone: PropTypes.bool.isRequired
};

ExclTaxButton.defaultProps = {
  amountType: '',
  setChangeDone: () => {}
};
