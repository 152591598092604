import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, InputNumber } from 'antd';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const { Option } = Select;

/**
 * Generates fields configuration for the claims page.
 *
 * @returns {Object} An object containing the fields configuration.
 */
export const useFieldsExcess = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});

  const fieldsExcess = [
    {
      label: ['amount'],
      name: ['amount'],
      rules: [{ required: true }],
      input: <InputNumber controls={false} />
    },
    {
      label: ['amount_or_percentage'],
      name: ['amount_or_percentage'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(enums?.amountOrPercentage || []).map((option) => (
            <Option key={option} value={option}>
              {t(`claims.form.${option}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['wording'],
      name: ['wording'],
      rules: [{ required: false }]
    }
  ];
  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/property-claim-accounts/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsExcess,
    isFieldsLoading
  };
};
