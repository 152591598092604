import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { SelectContract } from '../../../components/SelectContract';
import { SelectEntity } from '../../../components/SelectEntity';
import { SelectSite } from '../../../components/SelectSite';
import { SelectManager } from '../../../components/SelectManager';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Option } = Select;

/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @returns
 * @property {Array} fields - An array of field configurations for the form.
 * @property {boolean} isFieldsLoading - A flag to determine whether the form fields are being loaded.
 * @hook
 */

export const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const [selectedEntity, setSelectedEntity] = useState(null);
  const dateFormat = 'DD-MM-YYYY';

  const handleEntitySelect = (entityId) => {
    setSelectedEntity(entityId);
  };

  const fields = [
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: (
        <SelectContract
          dbKey="contract"
          sub_programme="AD_VALOREM"
          programme_types="TRANSPORT"
        />
      ),
      startWithDivider: {
        title: t('transports.form.main_informations')
      }
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: <SelectEntity dbKey="entity" onSelect={handleEntitySelect} />
    },
    {
      label: ['site'],
      name: ['site'],
      rules: [{ required: true }],
      input: <SelectSite dbKey="site" entityId={selectedEntity} />
    },
    {
      label: ['customer_reference_number'],
      name: ['customer_reference_number'],
      rules: [{ required: true }]
    },
    {
      label: ['shipper_name'],
      name: ['shipper_name'],
      rules: [{ required: true }]
    },
    {
      label: ['recipient_name'],
      name: ['recipient_name'],
      rules: [{ required: true }]
    },
    {
      label: ['departure_date'],
      name: ['departure_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['estimated_delivery_date'],
      name: ['estimated_delivery_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['parcel_quantity'],
      name: ['parcel_quantity'],
      rules: [{ required: true }]
    },
    {
      label: ['weight'],
      name: ['weight'],
      rules: [{ required: true }],
      input: <Input suffix="kg" style={{ width: '100%' }} />
    },
    {
      label: ['means_of_transport'],
      name: ['means_of_transport'],
      input: (
        <Select
          placeholder={t('documents.filter_by_type')}
          style={{ width: '100%' }}
        >
          {(enums?.means_of_transport || []).map((mean) => (
            <Option key={mean} value={mean}>
              {t(`transports.form.${mean}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['nature_of_the_goods'],
      name: ['nature_of_the_goods'],
      rules: [{ required: true }]
    },
    {
      label: ['value_of_the_goods'],
      name: ['value_of_the_goods'],
      rules: [{ required: true }]
    },
    {
      label: ['street_number'],
      name: ['departure_street_number'],
      rules: [{ required: true }],
      startWithDivider: {
        title: t('transports.form.departure_address')
      }
    },
    {
      label: ['street_name'],
      name: ['departure_street_name'],
      rules: [{ required: true }]
    },
    {
      label: ['additional_address'],
      name: ['departure_additional_address']
    },
    {
      label: ['postal_code'],
      name: ['departure_postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['departure_city'],
      rules: [{ required: true }]
    },
    {
      label: ['country'],
      name: ['departure_country'],
      rules: [{ required: true }]
    },
    {
      label: ['street_number'],
      name: ['arrival_street_number'],
      rules: [{ required: true }],
      startWithDivider: {
        title: t('transports.form.arrival_address')
      }
    },
    {
      label: ['street_name'],
      name: ['arrival_street_name'],
      rules: [{ required: true }]
    },
    {
      label: ['additional_address'],
      name: ['arrival_additional_address']
    },
    {
      label: ['postal_code'],
      name: ['arrival_postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['arrival_city'],
      rules: [{ required: true }]
    },
    {
      label: ['country'],
      name: ['arrival_country'],
      rules: [{ required: true }]
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/transports/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
