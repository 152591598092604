import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Skeleton,
  Flex,
  Row,
  Col,
  Card,
  Popconfirm,
  message,
  Spin
} from 'antd';
import {
  EditOutlined,
  WarningOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { DescriptionList } from '../../../../components';
import { ListResourceWithModal } from '../../../../components/ListResource/ListResourceWithModal';
import { useColumns } from './columns';
import CreateUpdateEntityModal from './CreateUpdateEntityModal';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Component to display company information and associated entities.
 *
 * @component
 * @param {boolean} isLoading - Indicates if data is being loaded.
 * @param {Array} listContent - Array of objects containing label, span, and content properties.
 * @returns {JSX.Element} CompanyAndEntitiesData component.
 */
const CompanyAndEntitiesData = ({ isLoading, listContent }) => {
  const columns = useColumns();
  const { t } = useTranslation();
  const { dispatchAPI, company, permissions, verifPermissions } =
    useAuthContext();
  const { errorMessage } = useErrorMessage();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [purpose, setPurpose] = useState('edit');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [canAdd, setcanAdd] = useState(true);
  const [canDelete, setDelete] = useState(true);
  const [entityId, setEntityId] = useState('');
  const iconSize = 18;

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setcanAdd(
        !verifPermissions('/entities/create', permissions?.notPermittedRoutes)
      );
      setDelete(
        !verifPermissions('/entities/delete', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  const deleteEntity = async (id) => {
    try {
      setIsSubmitting(true);
      const { data } = await dispatchAPI('DELETE', {
        url: `/entities/${id}`
      });
      const entityName = data.name;
      message.success(
        t('workflows.messages.success.entity_successfully_deleted', {
          entityName
        })
      );
      setIsSubmitting(false);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      errorMessage(e);
    }
  };

  const actionColumn = () => [
    {
      key: 'name',
      sorter: false,
      render: (record) => (
        <Flex align="center" wrap="nowrap" justify="end" gap="small">
          <EditOutlined
            style={{ fontSize: iconSize }}
            onClick={() => {
              setIsModalOpen(true);
              setPurpose('edit');
              setEntityId(record?._id);
            }}
          />
          {canDelete && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteEntity(record?._id)}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          )}
        </Flex>
      )
    }
  ];

  return (
    <Spin spinning={isSubmitting}>
      <Row gutter={[24, 24]} style={{ width: '100%' }}>
        <Col span={24}>
          <Card
            title={t('companies.form.company_informations')}
            style={{ width: '100%' }}
          >
            <Skeleton
              active
              loading={isLoading}
              paragraph={{ rows: 5 }}
              style={{ width: '100%' }}
            >
              <DescriptionList data={listContent} layout="vertical" translate />
            </Skeleton>
          </Card>
        </Col>
        <Col span={24}>
          <Card title={t(`companies.form.entities`)} style={{ width: '100%' }}>
            <ListResourceWithModal
              populate="documents,documents.file"
              resourceName="entities"
              extraQuery={`company=${company}&deleted!=true`}
              columns={[...columns, ...actionColumn()]}
              withPageHeader={false}
              withCreateButton
              withSearchBar
              forceRefresh={forceRefresh}
              style={{ width: '100%' }}
              customActionColumn
              openModal
              setPurpose={setPurpose}
              setIsModalOpen={setIsModalOpen}
              canAdd={canAdd}
              onDoubleClickAction={false}
            />
            {isModalOpen && (
              <CreateUpdateEntityModal
                isModalOpen={isModalOpen}
                entityId={entityId}
                setIsModalOpen={setIsModalOpen}
                purpose={purpose}
                forceRefresh={forceRefresh}
                setForceRefresh={setForceRefresh}
              />
            )}
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

CompanyAndEntitiesData.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  listContent: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      span: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired
    })
  ).isRequired
};

export default CompanyAndEntitiesData;
