import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Card, Divider } from 'antd';
import { Link } from 'react-router-dom';

export const TileLink = ({ image, altImage, link, target }) => {
  const { t } = useTranslation();
  return (
    <Link to={`/${target}/${link}`}>
      <Card
        className={target === 'claims' ? 'widget claims' : 'widget program'}
      >
        <Row justify="center" className="tile-icon-wrapper">
          {image}
        </Row>
        <Divider />
        <Row justify="center" className="tile-content title">
          <h3>{t(`tiles.${altImage}`)}</h3>
        </Row>
      </Card>
    </Link>
  );
};

TileLink.propTypes = {
  image: PropTypes.element.isRequired,
  altImage: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired
};
