import { Route, Routes } from 'react-router-dom';
import { ListConstructionInsurance } from './ListConstructionInsurance';
import { ConstructionInsuranceCreateUpdate } from './ConstructionInsuranceCreateUpdate';
import { AmendmentCreateUpdate } from './Amendment/AmendmentCreateUpdate';
import { ConstructionInsuranceShow } from './ConstructionInsuranceShow';
import { Exception } from '../../../../components';

export const ConstructionInsuranceRouter = () => (
  <Routes>
    <Route
      path="amendment/create/:id"
      element={<AmendmentCreateUpdate purpose="create" />}
    />
    <Route
      path="amendment/edit/:id"
      element={<AmendmentCreateUpdate purpose="edit" />}
    />
    <Route
      path="create"
      element={<ConstructionInsuranceCreateUpdate purpose="create" />}
    />
    <Route
      path="edit/:id"
      element={<ConstructionInsuranceCreateUpdate purpose="edit" />}
    />
    <Route path="show/:id" element={<ConstructionInsuranceShow />} />
    <Route index element={<ListConstructionInsurance />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
