import PropTypes from 'prop-types';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Row, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerContract';
import { useAuthContext } from '../../contexts/AuthContext';

import { useFields } from './fieldsAmendments';
/**
 * `AmendmentCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * Note: This component does not support managing files.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const AmendmentCreateUpdate = ({ purpose }) => {
  const [selectedType, setSelectedType] = useState('');
  const [selectedProgramme, setSelectedProgramme] = useState([]);
  const [isMandatory, setIsMandatory] = useState(true);
  const [contractId, setContractId] = useState('');
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [idWithoutParams, setIdWithoutParams] = useState(null);
  const [resetField, setResetField] = useState(false);
  const [newPurpose, setNewPurpose] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [contractNumber, setContractNumber] = useState('');
  const [containsContractDocuments, setContainsContractDocuments] =
    useState(false);
  const { fields } = useFields(
    setSelectedType,
    selectedType,
    selectedProgramme,
    setSelectedProgramme,
    isMandatory,
    setIsMandatory,
    setContractId,
    setContractNumber,
    isFieldsLoading,
    setIsFieldsLoading,
    isDisabled,
    containsContractDocuments
  );
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();
  const config = {
    onGetResource: {
      setFields: (data) => {
        setSelectedType(data.document_type);
        setSelectedProgramme(data.programme_types);
        setIsMandatory(data.is_pending);
        if (purpose === 'edit' && data.document_type === undefined) {
          setSelectedType('AMENDMENT');
          setIsDisabled(true);
        }
        if (data.documents && data.documents.length > 0) {
          const isContractDocuments = data.documents.some(
            (item) =>
              item.document_type.includes('AMENDMENT') &&
              item.is_pending === false
          );
          setContainsContractDocuments(isContractDocuments);
        }

        return {
          ...data,
          validity_start_date:
            data.validity_start_date && moment(data.validity_start_date),
          validity_end_date:
            data.validity_end_date && moment(data.validity_end_date),
          amendment_validity_start_date:
            data.amendment_validity_start_date &&
            moment(data.amendment_validity_start_date),
          is_pending: Boolean(data.is_pending),
          ...(purpose === 'edit' &&
            data.document_type === undefined && { document_type: 'AMENDMENT' }),
          contract_number: data.contract_number
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        is_pending: Boolean(data.is_pending),
        company_Id: company
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        is_pending: Boolean(data.is_pending),
        company_Id: company,
        ...(contractNumber !== '' && { contract_number: contractNumber })
      })
    }
  };

  useEffect(() => {
    if (purpose !== 'edit') {
      if (selectedType === 'AMENDMENT' || selectedType === '') {
        setNewPurpose('create');
        setIdWithoutParams(null);
        setContractId('');
        setResetField(true);
        setBaseUrl('contracts');
      } else {
        setNewPurpose('edit');
        setResetField(false);
        setBaseUrl('contracts/documents');
      }
      if (contractId !== '') {
        setIdWithoutParams(contractId);
      }
    }
    if (purpose === 'edit') {
      setBaseUrl('contracts/documents');
    }
  }, [selectedType, contractId]);

  return (
    <Skeleton loading={isFieldsLoading}>
      {baseUrl !== '' ? (
        <CreateUpdateContainer
          fields={fields}
          purpose={newPurpose || purpose}
          urlFileKeys="contracts"
          baseUrl={baseUrl}
          resource="documents"
          config={config}
          extraTitle={
            <Row justify="center">{`${t('folder')} ${companyName}`}</Row>
          }
          withFilesManager={false}
          idWithoutParams={idWithoutParams}
          resetField={resetField}
        />
      ) : null}
    </Skeleton>
  );
};

AmendmentCreateUpdate.propTypes = {
  purpose: PropTypes.string
};

AmendmentCreateUpdate.defaultProps = {
  purpose: null
};
