import React from 'react';
import { Button, Card, Col, Row, Select, Upload, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  FolderOpenFilled,
  HourglassOutlined,
  ExclamationOutlined
} from '@ant-design/icons';
import { useDownloadDocument } from '../../../utils/downloadDoc';
import { useDraggerProps } from './utils/draggerProps';
import { handleFileTypeChange } from './utils/handleFileTypeChange';
import { handleFileDelete } from './utils/handleFileDelete';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Dragger } = Upload;
const { Option } = Select;

/**
 * `FilesManager` is a React component for managing a list of files. It allows for file deletion, viewing,
 * downloading, and type changing. It uses a custom Dragger component for file uploads.
 * It also displays a list of missing files.
 *
 * @component
 * @param {Object} props - Properties passed to the component.
 * @param {Array} props.filesList - Array of file objects.
 * @param {Function} props.setFilesList - Function to set the new file list.
 * @param {Array} props.filesKeys - Array of file keys.
 * @param {Array} props.filesConfiguration - The current configuration of the files.
 * @param {Function} props.setFilesConfiguration - Function to set the new files configuration.
 * @param {string} props.purpose - The purpose of the file usage.
 * @param {Function} props.deleteFile - Function to delete a specific file.
 * @returns {JSX.Element} A JSX element representing the component.
 */
const FilesManager = ({
  filesList,
  setFilesList,
  filesKeys,
  filesConfiguration,
  setFilesConfiguration,
  purpose,
  deleteFile,
  draggerFilesKeysMandatory,
  noDelete
}) => {
  const { t } = useTranslation();
  const { downloadDocument, viewDocument } = useDownloadDocument();
  const { message } = useErrorMessage();

  const draggerProps = useDraggerProps(filesList, setFilesList, message, t);

  const missingFileKeys = filesKeys.filter(
    (key) => !filesList.some((file) => file.fileKey === key)
  );
  return (
    <Card title={t('files.title')} className="file-manager">
      <Row gutter={[16, 16]}>
        <Col xs={24} xxl={16}>
          {filesList?.map((item) => (
            <Card size="small" key={item.name}>
              <Flex
                justify="space-between"
                align="center"
                gap="small"
                className="fileList-item"
              >
                <Flex className="file-name">{item.name}</Flex>
                <Flex justify="end" align="center" gap="small">
                  <Select
                    value={item.fileKey}
                    onChange={(value) =>
                      handleFileTypeChange(
                        item,
                        value,
                        filesConfiguration,
                        setFilesConfiguration
                      )
                    }
                    style={{ width: 200 }}
                    defaultValue={item?.fileKey}
                  >
                    {filesKeys.map((key) => (
                      <Option key={key + key} value={key}>
                        {t(`files.keys.${key}`)}
                      </Option>
                    ))}
                  </Select>
                  <Flex>
                    {purpose === 'edit' && item?.file && (
                      <>
                        <Button
                          onClick={() => viewDocument(item.file, 'show')}
                          type="link"
                          icon={<EyeOutlined />}
                        />
                        <Button
                          onClick={() => downloadDocument(item.file)}
                          type="link"
                          icon={<DownloadOutlined />}
                        />
                      </>
                    )}
                    {!noDelete ? (
                      <Button
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() =>
                          handleFileDelete(
                            { name: item.name, id: item?._id },
                            deleteFile,
                            filesList,
                            setFilesList,
                            filesConfiguration,
                            setFilesConfiguration,
                            t
                          )
                        }
                      />
                    ) : null}
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          ))}
          {/* <Flex vertical> */}
          {missingFileKeys.map((key) => (
            <Card size="small" key={key}>
              <Flex justify="space-between" align="center">
                <Flex>
                  <HourglassOutlined />
                  &nbsp; {t(`files.keys.${key}`)}
                </Flex>
                {draggerFilesKeysMandatory.includes(key) && (
                  <ExclamationOutlined
                    style={{ color: 'var(--warningColor)' }}
                  />
                )}
              </Flex>
            </Card>
          ))}
          {/* </Flex> */}
        </Col>

        <Col xs={24} xxl={8}>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <FolderOpenFilled />
            </p>
            <p className="ant-upload-text">{t('files.create.action')}</p>
          </Dragger>
        </Col>
      </Row>
    </Card>
  );
};

FilesManager.propTypes = {
  filesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filesKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  filesConfiguration: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setFilesList: PropTypes.func.isRequired,
  setFilesConfiguration: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired,
  draggerFilesKeysMandatory: PropTypes.arrayOf(PropTypes.string),
  noDelete: PropTypes.bool
};

FilesManager.defaultProps = {
  draggerFilesKeysMandatory: [],
  noDelete: false
};

export default FilesManager;
