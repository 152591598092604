// TODO: Check if dynamic config is required before deleting comments
import { useTranslation } from 'react-i18next';
// import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
// import { useAuthContext } from '../../../../contexts/AuthContext';
// import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = () => {
  const { t } = useTranslation();
  // const { dispatchAPI, company: companyId } = useAuthContext();
  // const { message } = useErrorMessage();
  // const [config, setConfig] = useState({});

  const renderProducts = (text, record) => {
    const {
      fleet_sub_programmes,
      civil_liability_sub_programmes,
      transport_sub_programmes,
      construction_sub_programmes
    } = record.contract || {};
    const subProgrammesParts = [
      ...(fleet_sub_programmes || []),
      ...(civil_liability_sub_programmes || []),
      ...(transport_sub_programmes || []),
      ...(construction_sub_programmes || [])
    ];
    const subProgrammes = subProgrammesParts
      .filter((part) => typeof part === 'string')
      .map((part) => t(`${part.toLowerCase()}.title`))
      .join(', ');
    return subProgrammes || '-';
  };

  const renderAddress = (text, record) => {
    const { number, street, additional } = record.company_id || {};
    return number || street || additional
      ? `${number || ''} ${street || ''} ${additional || ''}`
      : '-';
  };

  const allColumns = [
    {
      title: t('civil-liability.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('civil-liability.form.company_name'),
      key: 'company_name',
      dataIndex: ['company_id', 'name'],
      sorter: true
    },
    {
      title: t('civil-liability.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? moment(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('civil-liability.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? moment(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('civil-liability.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date
          ? moment(validity_end_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('civil-liability.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      sorter: true
    },
    {
      title: t('subscriptions.form.products'),
      key: 'products',
      render: renderProducts
    },
    {
      title: t('subscriptions.form.subscriber_last_name_first_name'),
      key: 'subscriber_last_name_first_name',
      dataIndex: ['contract', 'subscriber_last_name_first_name'],
      sorter: true
    },
    {
      title: t('subscriptions.form.adress'),
      key: 'adress',
      render: renderAddress
    },
    {
      title: t('subscriptions.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['contract', 'postal_code'],
      sorter: true
    },
    {
      title: t('subscriptions.form.city'),
      key: 'city',
      dataIndex: ['contract', 'city'],
      sorter: true
    },
    {
      title: t('civil-liability.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      sorter: true
    },
    {
      title: t('civil-liability.form.APE_code'),
      key: 'APE_code',
      dataIndex: ['APE_code'],
      sorter: true
    },
    {
      title: t(
        'civil-liability.form.eighteen_months_span_statistic_number_of_employees'
      ),
      key: 'eighteen_months_span_statistic_number_of_employees',
      dataIndex: 'eighteen_months_span_statistic_number_of_employees',
      sorter: true,
      render: (eighteen_months_span_statistic_number_of_employees) =>
        t(
          `civil-liability.form.enums.${eighteen_months_span_statistic_number_of_employees}`
        ) || '-'
    },
    {
      title: t('civil-liability.form.description_of_the_activity'),
      key: 'description_of_the_activity',
      dataIndex: 'description_of_the_activity',
      sorter: true
    }
  ];

  // const getConfig = useCallback(async () => {
  //     try {
  //       const { data } = await dispatchAPI('GET', {
  //         url: `/civil-liability-executive-configs/${companyId}`
  //       });
  //       setConfig((data && data[0]) || {});
  //     } catch (e) {
  //       message(e);
  //     }
  //   }, [dispatchAPI, message]);

  //   useEffect(() => {
  //     getConfig();
  //   }, []);

  //   const filteredColumns = allColumns.filter((column) => config[column.key]);

  //   return filteredColumns || [];
  // };
  return allColumns;
};
