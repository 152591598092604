import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Radio, Input, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectManager } from '../../components/SelectManager';
import { SelectEnumsClaimsType } from '../../components/SelectEnumsClaimsType';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * A hook for generating form fields configuration based on the provided parameters.
 *
 * @hook
 * @param {boolean} isOther - Indicates whether the "claim_description_if_other" field is visible.
 * @param {Function} setIsOther - A function to set the visibility of the "claim_description_if_other" field.
 * @returns {Object} An object containing an array of form fields configuration.
 */

const { Group } = Radio;
const { Option } = Select;

export const useFields = (isOther, setIsOther) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const dateFormat = 'DD-MM-YYYY';

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      rules: [{ required: false }],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['insurance_company_reference'],
      name: ['insurance_company_reference'],
      rules: [{ required: false }]
    },
    {
      label: ['sinister_date'],
      name: ['sinister_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['insured_status'],
      name: ['insured_status'],
      input: (
        <Select
          placeholder={t('documents.filter_by_type')}
          style={{ width: '100%' }}
        >
          {(enums?.insuredStatus || []).map((status) => (
            <Option key={status} value={status}>
              {t(`claims.form.${status}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['claim_type'],
      name: ['claim_type'],
      rules: [{ required: true }],
      input: (
        <SelectEnumsClaimsType
          dbKey="claim_type"
          url="transport_claims"
          setIsOther={setIsOther}
          multiple="multiple"
        />
      )
    },
    {
      label: ['claim_type_if_other'],
      name: ['claim_type_if_other'],
      hidden: !isOther
    },
    {
      label: ['goods_to_be_appraised'],
      name: ['goods_to_be_appraised'],
      rules: [{ required: true }],
      input: (
        <Group>
          {(enums?.goodToBeAppraised || []).map((option) => (
            <Radio key={option} value={option}>
              {t(`claims.form.${option}`)}
            </Radio>
          ))}
        </Group>
      )
    },
    {
      label: ['goods_type'],
      name: ['goods_type'],
      rules: [{ required: true }]
    },
    {
      label: ['customer_email'],
      name: ['customer_email'],
      rules: [{ required: true, type: 'email' }]
    },
    {
      name: ['customer_phone_number'],
      label: 'customer_phone_number',
      rules: [{ required: true }],
      input: (
        <Input.Group>
          <Form.Item
            noStyle
            name={['customer_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['customer_phone_number', 'number']}>
            <Input style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/transport_claims/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsInformations,
    isFieldsLoading
  };
};
