import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMailingFields } from '../MailingFields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerSendMail';
import 'react-quill/dist/quill.snow.css';

export const MailingBlankClaim = ({
  isModalMailingOpen,
  setIsModalMailingOpen,
  idWithoutParams,
  templateMail,
  datas,
  resource,
  baseUrl,
  reference,
  templateName
}) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState();
  const [mailObject, setMailObject] = useState();
  const [templateReLoaded, setTemplateReLoaded] = useState(false);
  const { mailingFields } = useMailingFields(
    editorState,
    setEditorState,
    mailObject
  );
  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        recipient: `${datas?.customer_manager?.first_name} ${datas?.customer_manager?.last_name}`,
        recipient_email_address: datas?.customer_manager?.email,
        sender: `${datas?.unit_manager?.first_name} ${datas?.unit_manager?.last_name}`,
        sender_email_address: datas?.unit_manager?.email,
        mail_object: mailObject && mailObject,
        template_content: editorState && editorState
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        template: templateName
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        template: templateName
      })
    }
  };

  const resetEditorContent = () => {
    setTemplateReLoaded(!templateReLoaded);
  };
  useEffect(() => {
    if (templateMail[0]) {
      const processedTemplateContent = templateMail[0]?.template_content
        .replace('{contract_number}', reference)
        .replace('{unit_counter}', datas?.unit_counter);
      setMailObject(templateMail[0]?.object);
      setEditorState(processedTemplateContent);
    }
  }, [templateMail, datas]);
  return (
    <Modal
      footer={null}
      title={t('mailing.send_an_email')}
      open={isModalMailingOpen}
      onOk={() => setIsModalMailingOpen(false)}
      onCancel={() => setIsModalMailingOpen(false)}
      className="mail-modal"
    >
      {templateMail && editorState && mailObject && (
        <>
          <CreateUpdateContainer
            fields={mailingFields}
            purpose="edit"
            resource={resource}
            baseUrl={baseUrl}
            tradKey="mailing"
            config={config}
            withFilesManager={false}
            WithPageHeaderCustom={false}
            idWithoutParams={idWithoutParams}
            templateReLoaded={templateReLoaded}
          />
          <Button
            onClick={() => {
              resetEditorContent();
            }}
          >
            {t('buttons.reset_to_base_editor_content')}
          </Button>
        </>
      )}
      <Button type="link" onClick={() => setIsModalMailingOpen(false)}>
        {t('buttons.cancel')}
      </Button>
    </Modal>
  );
};

MailingBlankClaim.propTypes = {
  isModalMailingOpen: PropTypes.bool.isRequired,
  setIsModalMailingOpen: PropTypes.func.isRequired,
  idWithoutParams: PropTypes.string,
  templateMail: PropTypes.arrayOf(
    PropTypes.shape({
      template_content: PropTypes.string,
      object: PropTypes.string,
      _id: PropTypes.string
    })
  ),
  datas: PropTypes.shape({
    entry_id: PropTypes.shape({
      contract: PropTypes.shape({
        contract_number: PropTypes.string
      })
    }),
    unit_manager: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string
    }),
    unit_counter: PropTypes.string,
    customer_manager: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string
    })
  }),
  resource: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired
};

MailingBlankClaim.defaultProps = {
  templateMail: {},
  idWithoutParams: null,
  datas: {}
};
