import { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { claimStatus } from '../../../utils/constants/tagColors';

/**
 * Generates custom table columns configuration for a claims list.
 *
 * @returns {Array} An array of column configuration objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enumsClaim, setEnumsClaim] = useState();
  const [enums, setEnums] = useState();

  const getEnumsClaim = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/vehicle_claims/enums'
      });
      setEnumsClaim(data);
    } catch (e) {
      message(e);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/vehicles/enums'
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnumsClaim();
      await getEnums();
    })();
  }, []);

  return [
    {
      key: 'documents',
      dataIndex: 'missing_documents',
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : null
    },
    {
      title: t('claims.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      sorter: true
    },
    {
      title: t('claims.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      sorter: true
    },
    {
      title: t('claims.form.sinister_date'),
      key: 'sinister_date',
      dataIndex: ['sinister_date'],
      render: (occurence_date) =>
        occurence_date ? moment(occurence_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.claim_type'),
      key: 'claim_type',
      dataIndex: 'claim_type',
      sorter: true,
      filters: enumsClaim?.claimType?.map((type) => ({
        text: t(`claims.form.${type}`),
        value: t(`claims.form.${type}`)
      })),
      render: (type) => t(`claims.form.${type}`)
    },
    {
      title: t('claims.form.brand'),
      key: 'vehicle_brand',
      dataIndex: ['entry_id', 'vehicle_brand'],
      sorter: true,
      filters: enums?.vehicleBrand?.map((brand) => ({
        text: `${brand}`,
        value: `${brand}`
      }))
    },
    {
      title: t('claims.form.model'),
      key: 'vehicle_model',
      dataIndex: ['entry_id', 'vehicle_model'],
      sorter: true,
      filters: enums?.vehicle_model?.map((model) => ({
        text: `${model}`,
        value: `${model}`
      }))
    },
    {
      title: t('claims.form.registration_or_serial_number'),
      key: 'registration_or_serial_number',
      dataIndex: ['entry_id', 'registration_or_serial_number'],
      sorter: true
    },
    {
      title: t('claims.form.opening_date'),
      key: 'opening_date',
      dataIndex: ['opening_date'],
      render: (opening_date) =>
        opening_date ? moment(opening_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.claim_status'),
      key: 'claim_status',
      dataIndex: 'claim_status',
      render: (status) =>
        status ? (
          <Tag color={claimStatus[status]}>
            {t(`claims.form.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          <Tag color="orange">{t('claims.form.reception_pending')}</Tag>
        ),
      sorter: true
    }
  ];
};
