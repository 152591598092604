import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../../components';
import { StepperAmendment } from './StepperAmendment';
import { useListContent } from './listContentAmendment';
import { DocumentsFieldDisplay } from '../../../../components/DocumentsFieldDisplay';

export const ShowAmendment = ({
  amendment,
  title,
  setForceRefresh,
  forceRefresh,
  constructionInsuranceId,
  isRequest
}) => {
  const [status, setStatus] = useState();
  const [amendmentId, setAmendmentId] = useState();
  const [amendmentNumber, setAmendmentNumber] = useState();
  const { t } = useTranslation();
  const listContent = useListContent(amendment);

  useEffect(() => {
    if (amendment) {
      setStatus(amendment.amendment_status);
      setAmendmentNumber(amendment.amendment_number);
      setAmendmentId(amendment._id);
    }
  }, [constructionInsuranceId]);
  return (
    <Card>
      {amendmentId && status && (
        <>
          <DescriptionList
            data={listContent}
            translate
            layout="vertical"
            title={t(`amendments.form.${title}`)}
            extra={`${t(`amendments.form.number`)} ${amendmentNumber}`}
          />
          {status !== 'ACCEPTED' && status !== 'REFUSED' && (
            <StepperAmendment
              id={amendmentId}
              status={status}
              constructionInsuranceId={constructionInsuranceId}
              setForceRefresh={setForceRefresh}
              forceRefresh={forceRefresh}
              isRequest={isRequest}
            />
          )}
          {amendment?.documents?.length > 0 && (
            <DocumentsFieldDisplay
              baseUrl="amendments"
              fieldName="amendment_request_documents"
              idWitoutParams={amendmentId}
              title="amendments.form.related_documents"
            />
          )}
        </>
      )}
    </Card>
  );
};

ShowAmendment.propTypes = {
  constructionInsuranceId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setForceRefresh: PropTypes.func,
  forceRefresh: PropTypes.bool,
  amendment: PropTypes.shape({
    _id: PropTypes.string,
    amendment_number: PropTypes.string,
    amendment_status: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  isRequest: PropTypes.bool
};

ShowAmendment.defaultProps = {
  setForceRefresh: () => {},
  forceRefresh: false,
  isRequest: false
};
