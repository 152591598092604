import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Typography, Card, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  EditOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Policy, Flood } from '@carbon/icons-react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';

const { Title } = Typography;

/**
 * `ShowContacts` is a React component for displaying company contacts.
 * It fetches contacts associated with a company and displays them in a structured format.
 * Users can also navigate to edit the existing contacts or create new ones.
 *
 * @component
 *
 * @returns {JSX.Element} A rendered component showcasing the company's contacts in two categories: customer managers and claims service.
 */

export const ShowContacts = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { company, dispatchAPI, user } = useAuthContext();
  const [companyContacts, setCompanyContacts] = useState(null);

  const categories = [
    'vehicle',
    'real_estate',
    'golf',
    'construction',
    'civil_liability',
    'transport'
  ];

  let extraHeader;

  if (user?.role === 'admins:SUPER-ADMIN') {
    if (companyContacts) {
      extraHeader = (
        <Link to={`/contacts/edit/${company}`}>
          <Button type="primary">
            <EditOutlined>{t('buttons.edit')}</EditOutlined>
          </Button>
        </Link>
      );
    } else {
      extraHeader = (
        <Link to="/contacts/create">
          <Button type="primary">
            <PlusOutlined> {t('buttons.create')}</PlusOutlined>
          </Button>
        </Link>
      );
    }
  }

  const getCompanyContacts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contacts/${company}`
      });
      setCompanyContacts(data[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    await getCompanyContacts();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    getSelectOptions();
  }, [getSelectOptions]);

  const renderContactRow = (category, type) => (
    <>
      <Row>
        <Col xs={24} lg={6}>
          {t(`contacts.${category}`)}
        </Col>
        <Col xs={24} sm={16} lg={14} align="start">
          <MailOutlined />{' '}
          {companyContacts?.[`${category}_contacts_mail_${type}`] || '-'}
        </Col>
        <Col xs={24} sm={8} lg={4} align="end">
          <PhoneOutlined />{' '}
          {companyContacts?.[`${category}_contacts_phone_${type}`] || '-'}
        </Col>
      </Row>
      <Divider />
    </>
  );

  return (
    <>
      <PageHeaderCustom title={t(`contacts.title`)} extra={extraHeader} />
      {!isFieldsLoading ? (
        <ContentCustom>
          <Row gutter={[24, 24]}>
            <Col xs={24} xxl={12}>
              <Card className="contact-card">
                <Row style={{ width: '100%', textAlign: 'center' }}>
                  <Col span={24}>
                    <Policy size={40} />
                  </Col>
                  <Col span={24}>
                    <Title level={4}>{t('contacts.customer_managers')}</Title>
                  </Col>
                </Row>
                <Divider className="contact-card-head-divider" />
                {categories.map((category) => (
                  <React.Fragment key={`${category}customer`}>
                    {renderContactRow(category, 'customer')}
                  </React.Fragment>
                ))}
              </Card>
            </Col>
            <Col xs={24} xxl={12}>
              <Card className="contact-card">
                <Row style={{ width: '100%', textAlign: 'center' }}>
                  <Col span={24}>
                    <Flood size={40} />
                  </Col>
                  <Col span={24}>
                    <Title level={4}>{t('contacts.claims_service')}</Title>
                  </Col>
                </Row>
                <Divider className="contact-card-head-divider" />
                {categories.map((category) => (
                  <React.Fragment key={`${category}claim`}>
                    {renderContactRow(category, 'claim')}
                  </React.Fragment>
                ))}
              </Card>
            </Col>
          </Row>
        </ContentCustom>
      ) : null}
    </>
  );
};
