import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFields } from './utils/fieldsTransport';
import { useAuthContext } from '../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';

/**
 * Component for creating or updating transport subscriptions.
 * @component
 * @param {Object} props - The component's properties.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {JSX.Element} The JSX element for creating or updating transport subscriptions.
 */
export const TransportSubscriptionCreateUpdate = ({
  purpose,
  subProgramme
}) => {
  const { company } = useAuthContext();
  const program = 'TRANSPORT';
  const { t } = useTranslation();
  const { fieldsInformations } = useFields(subProgramme);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        desired_effective_date:
          data.desired_effective_date && dayjs(data.desired_effective_date)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_id: company,
        programme_types: program,
        sub_programme: subProgramme
      })
    }
  };

  return (
    <CreateUpdateContainer
      title={t(`subscriptions.form.${purpose}`)}
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="subscriptions"
      resource="subscriptions"
      config={config}
      withFilesManager={false}
      messageOnSuccess
    />
  );
};

TransportSubscriptionCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired,
  subProgramme: PropTypes.string.isRequired
};
