import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFields } from '../fieldsClaimsVehicles';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';

/**
 * Provides a component for creating or updating Heavy vehicles claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "update".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for Heavy vehicles claims.
 */
export const HeavyVehiclesCreateUpdate = ({ purpose }) => {
  const { dispatchAPI, company } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [vehicle, setVehicle] = useState();
  const [usualDrivers, setUsualDrivers] = useState([]);
  const [unusual, setUnusual] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [isComplaint, setIsComplaint] = useState(false);
  const [entryDate, setEntryDate] = useState(null);
  const { fieldsInformations } = useFields(
    isOther,
    setIsOther,
    setIsComplaint,
    unusual,
    setUnusual,
    usualDrivers
  );

  const draggerFilesKeysOverRide = [
    'car_registration_document',
    'driver_license',
    'auto_report',
    'filing_complaint'
  ];
  const mandatoryDocuments = isComplaint
    ? ['driver_license', 'filing_complaint']
    : ['driver_license'];
  const draggerFilesKeysMandatory = isComplaint
    ? ['driver_license', 'filing_complaint']
    : ['driver_license'];

  const getVehicle = async () => {
    if (purpose === 'create') {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `vehicle/${id}?populate=entity,site,contract,company,secondary_drivers,principal_driver`
        });
        setVehicle(data);
        setEntryDate(data?.created_at);
        setUsualDrivers([
          {
            first_name: data?.principal_driver?.first_name,
            last_name: data?.principal_driver?.last_name,
            _id: data?.principal_driver?._id
          },
          ...(data?.secondary_drivers?.map((driver) => ({
            first_name: driver?.first_name,
            last_name: driver?.last_name,
            _id: driver?._id
          })) || [])
        ]);
      } catch (e) {
        message(e);
      }
    } else {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `vehicle_claims/documents/${id}`
        });
        setVehicle(data);
        setUsualDrivers([
          {
            first_name: data?.entry_id?.principal_driver?.first_name,
            last_name: data?.entry_id?.principal_driver?.last_name,
            _id: data?.entry_id?.principal_driver?._id
          },
          ...(data?.entry_id?.secondary_drivers?.map((driver) => ({
            first_name: driver?.first_name,
            last_name: driver?.last_name,
            _id: driver?._id
          })) || [])
        ]);
      } catch (e) {
        message(e);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getVehicle();
    })();
  }, []);

  const config = {
    onGetResource: {
      setFields: (data) => {
        if (data.claim_type === 'OTHER') {
          setIsOther(true);
        }
        if (data.unusual_driver === 'YES') {
          setUnusual(true);
        }
        return {
          ...data,
          sinister_date: data.sinister_date && dayjs(data.sinister_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: id,
        company,
        opening_date: dayjs(Date.now()),
        entity: vehicle?.entity?._id,
        site: vehicle?.site?._id,
        fleet_type: 'HEAVY_VEHICLE',
        heavy_vehicle_type: vehicle?.heavy_vehicle_type,
        claim_date_before_entry_date: dayjs(entryDate).isBefore(
          dayjs(Date.now())
        )
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        unusual_driver_name:
          data.unusual_driver === 'YES' ? data.unusual_driver_name : null,
        driver_time_accident:
          data.unusual_driver === 'NO' ? data.driver_time_accident : null
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="vehicle_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      mandatoryDocuments={mandatoryDocuments}
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      draggerFilesKeysMandatory={draggerFilesKeysMandatory}
    />
  );
};

HeavyVehiclesCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
