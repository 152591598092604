import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Select, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

/**
 * Generates a custom Select component for selecting claim types from enums.
 *
 * @param {Object} props - Props passed to the component.
 * @param {string} props.dbKey - The database key for the select component.
 * @returns {JSX.Element} JSX element containing the custom Select component.
 */
export const SelectEnumsClaimsType = ({
  dbKey,
  url,
  setIsOther,
  setIsComplaint,
  multiple
}) => {
  const { t } = useTranslation();
  const [enums, setEnums] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${url}/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        onChange={(value) => {
          if (value === 'OTHER') {
            setIsOther(true);
          } else {
            setIsOther(false);
          }
          if (value === 'THEFT' || value === 'VANDALISM') {
            setIsComplaint(true);
          } else {
            setIsComplaint(false);
          }
        }}
        mode={multiple}
        showSearch
        filterOption={(input, option) =>
          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
        }
      >
        {(enums?.claimType || []).map((type) => (
          <Option key={type} value={type}>
            {t(`claims.form.${type}`)}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

SelectEnumsClaimsType.propTypes = {
  dbKey: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  setIsOther: PropTypes.func,
  setIsComplaint: PropTypes.func,
  multiple: PropTypes.bool
};

SelectEnumsClaimsType.defaultProps = {
  setIsOther: () => {},
  setIsComplaint: () => {},
  multiple: false
};
