import { Link } from 'react-router-dom';
import { Button, Flex } from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  FormOutlined,
  DownloadOutlined,
  MailOutlined
} from '@ant-design/icons';
import { Flood } from '@carbon/icons-react';

const iconSize = 18;

/**
 * Generates an array of action columns for a table in a React component for managing golf properties.
 *
 * @param {boolean} edit - A boolean indicating whether the "Edit" action is enabled.
 * @param {function} generateAttestation - A function to generate an Attestation for a golf property record.
 * @returns {Object[]} An array of action column objects for rendering actions in a table.
 */
export const useActionColumn = (edit, generateAttestation) => [
  {
    key: 'action',
    align: 'right',
    render: (record) => (
      <Flex gap="small" align="center" justify="end" wrap="nowrap">
        <Link to={`/programmes/golfs/show/${record?._id}`}>
          <EyeOutlined style={{ fontSize: iconSize }} />
        </Link>
        {edit && (
          <Link to={`/programmes/golfs/edit/${record?._id}`}>
            <EditOutlined style={{ fontSize: iconSize }} />
          </Link>
        )}
        <Link to={`/claims/golfs/create/${record?._id}`}>
          <Flood size={20} />
        </Link>
        <FormOutlined style={{ fontSize: iconSize }} />
        {record?.contract?.insurance_company === 'AXA' && (
          <Button
            type="link"
            onClick={() => generateAttestation(record?._id)}
            style={{ padding: 0 }}
          >
            <DownloadOutlined style={{ fontSize: iconSize }} />
          </Button>
        )}
        <MailOutlined style={{ fontSize: iconSize }} />
      </Flex>
    )
  }
];
