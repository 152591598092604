import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

export const usePermissionsData = (userId) => {
  const [dataTree, setDataTree] = useState([]);
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${userId}`
      });
      if (data?.permissions) {
        const { permissions } = data;
        setDataTree(permissions);
      }
      const { permissions } = [];
      setDataTree(permissions);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  return { dataTree };
};
