import { useTranslation } from 'react-i18next';
import { useColumns } from '../../../components/columnsSubscriptionTypeContract';
import { useActionColumn } from './actionColumn';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * `ListOtherConstruction` is a React component designed to list and manage light vehicles.
 * It provides filtering options by vehicle type, entity, missing documents and site.
 * The component fetches the data based on the filters applied and displays it in a tabular format.
 *
 * @component
 * @returns {ReactElement} Rendered `ListFOtherConstruction` component.
 */

export const ListOtherConstruction = () => {
  const { company } = useAuthContext();
  const columns = useColumns();
  const actionColumn = useActionColumn();
  const { t } = useTranslation();

  return (
    <ListResource
      extraQuery={`company_Id=${company}&sub_programme=GENERAL_CONTRACTOR,CONSTRUCTION_COMPANY_AND_ENGINEERING,PROPERTY_DEVELOPER`}
      resourceName="construction"
      exportUrl={`exports/company/${company}/sub-programme/OTHER`}
      importUrl={`imports-constructions/company/${company}/sub-programme/OTHER`}
      tradKey="constructions"
      exportTemplateUrl="export-templates/template/own-account"
      columns={[...columns, ...actionColumn]}
      noCreateButton
      populate="entity,site,contract,company_Id"
      sheetName={t('constructions.title')}
      headers={[]}
      editAction={false}
      showAction={false}
      duplicateAction={false}
      printAction={false}
      deleteAction={false}
    />
  );
};
