import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Col, Modal, Row, Spin, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columnsDocuments';
import { useActionColumn } from './actionColumn';
import { ListResource } from '../../components/ListResource/ListResource';
import { SelectByEntity } from '../components/SelectByEntity';
import { SelectByType } from './SelectByType';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Represents the `ListDocuments` component that displays a list of documents.
 *
 * It provides filtering options based on entity and document type. Filter changes result in a
 * navigation update, changing the URL to reflect the applied filters.
 *
 * The list can be extended with custom action columns. Data for the documents is fetched based on the company
 * in the auth context.
 *
 * @component
 * @returns {React.ElementType} A list of documents.
 */

export const ListDocuments = () => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [filterUser, setFilterUser] = useState('');
  const [entity, setEntity] = useState('');
  const [type, setType] = useState('');
  const [show, setShow] = useState(true);
  const [edit, setEdit] = useState(true);

  const { company, permissions, verifPermissions, dispatchAPI } =
    useAuthContext();
  const navigate = useNavigate();

  const generateAttestation = async (id) => {
    const modal = Modal.info({
      content: (
        <Row
          style={{ flexDirection: 'column' }}
          justify="center"
          align="middle"
        >
          <Col>
            <Spin />
          </Col>
          <Col>{t('files.modal.document-loading')}</Col>
        </Row>
      ),
      footer: null,
      maskClosable: false
    });
    try {
      const { data, headers } = await dispatchAPI('GET', {
        url: `documents/attestation/${id}`,
        responseType: 'arraybuffer'
      });
      const blob = new Blob([data], {
        type: headers['content-type']
      });
      modal.destroy();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = headers['content-disposition'];
      document.body.appendChild(a);

      a.click();

      window.URL.revokeObjectURL(url);
    } catch (e) {
      modal.destroy();
      message.error(e);
    }
  };

  const columns = useColumns();
  const actionColumn = useActionColumn(edit, show, generateAttestation);

  let filterParameter = `document_type=${type}__entity=${entity}__`;

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setFilterUser('&document_type!=CONTRACT');
      setShow(
        !verifPermissions(
          'documents/contract/show',
          permissions?.notPermittedRoutes
        )
      );
      setEdit(
        !verifPermissions(
          'documents/contract/edit',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  const onFilterEntity = (entityId) => {
    setEntity(entityId || '');
  };

  const onFilterType = (typeId) => {
    setType(typeId || '');
  };

  useEffect(() => {
    filterParameter = `document_type=${type}__entity=${entity}__`;
    navigate({
      pathname: '/documents',
      search: `?f=${filterParameter}`
    });
  }, [entity, type]);

  return (
    <ListResource
      extraQuery={`company_Id=${company}${filterUser}`}
      resourceName="documents"
      customActionColumn
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <>
          <SelectByEntity onChange={onFilterEntity} />
          <SelectByType onChange={onFilterType} permissions={permissions} />
        </>
      }
      setLoaded={() => setLoaded()}
      loaded={loaded}
      populate="entity,file,previous_amendment"
      withCreateButton={edit}
      withUploadButton={edit}
      onDoubleClickAction={false}
    />
  );
};
