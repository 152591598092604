import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, message } from 'antd';
import { useColumns } from './utils/columnsOtherVehicles';
import { useActionColumn } from './utils/actionColumn';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { SelectByEntity } from '../../../components/SelectByEntity';
import { SelectBySite } from '../../../components/SelectBySite';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../../components/FilterByMissingDocuments';
import { GenerateGreenCard } from '../../../../utils/GenerateGreenCard';

/**
 * `ListOtherVehicles` is a React component designed to list and manage light vehicles.
 * It provides filtering options by vehicle type, entity, missing documents and site.
 * The component fetches the data based on the filters applied and displays it in a tabular format.
 *
 * @component
 * @returns {ReactElement} Rendered `ListOtherVehicles` component.
 */

export const ListOtherVehicles = () => {
  const [filter, setFilter] = useState('');
  const [entity, setEntity] = useState('');
  const [site, setSite] = useState('');
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [showData, setShowData] = useState(true);
  const [edit, setEdit] = useState(true);
  const [reintegrate, setReintegrate] = useState(true);

  const { company, verifPermissions, permissions, dispatchAPI } =
    useAuthContext();
  const { t } = useTranslation();

  const handleGenerateGreenCard = async (vehicleId) => {
    await GenerateGreenCard(vehicleId, dispatchAPI, t, message);
  };

  const columns = useColumns();
  const actionColumn = useActionColumn(
    edit,
    handleGenerateGreenCard,
    t,
    message,
    dispatchAPI
  );

  const navigate = useNavigate();
  const resourceName = 'vehicles';

  let filterParameter = `other_vehicle_type=${filter}__entity=${entity}`;

  const translate = {
    1: '',
    2: 'CONSTRUCTION_MACHINE',
    3: 'MACHINERY',
    4: 'LIFTING_EQUIPMENT',
    5: 'GOLF_CART',
    6: 'ROBOT_LAWN_MAWER'
  };

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShowData(
        !verifPermissions(
          'datas/other_vehicles',
          permissions?.notPermittedRoutes
        )
      );
      setEdit(
        !verifPermissions(
          '/programmes/other-vehicles/edit',
          permissions?.notPermittedRoutes
        )
      );
      setReintegrate(
        !verifPermissions(
          '/programmes/other-vehicles/reintegrate',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  const onChange = (activeKey) => {
    setFilter(translate[activeKey]);
  };

  const onFilterEntity = (entityId) => {
    setEntity(entityId || '');
  };

  const onFilterSite = (siteId) => {
    setSite(siteId || '');
  };

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (site === '') {
      filterParameter = `other_vehicle_type=${filter}__entity=${entity}__missing_documents=${isMissingDocuments}__`;
      navigate({
        pathname: '/programmes/other-vehicles',
        search: `?f=${filterParameter}`
      });
    } else {
      filterParameter = `other_vehicle_type=${filter}__entity=${entity}__site=${site}__missing_documents=${isMissingDocuments}__`;
      navigate({
        pathname: '/programmes/other-vehicles',
        search: `?f=${filterParameter}`
      });
    }
  }, [filter, navigate, isMissingDocuments, entity, site]);

  const tabs = [
    {
      label: t(`${resourceName}.form.all`),
      key: '1'
    },
    {
      label: t(
        `${resourceName}.form.enums.other_vehicle_type.CONSTRUCTION_MACHINE`
      ),
      key: '2'
    },
    {
      label: t(`${resourceName}.form.enums.other_vehicle_type.MACHINERY`),
      key: '3'
    },
    {
      label: t(
        `${resourceName}.form.enums.other_vehicle_type.LIFTING_EQUIPMENT`
      ),
      key: '4'
    },
    {
      label: t(`${resourceName}.form.enums.other_vehicle_type.GOLF_CART`),
      key: '5'
    },
    {
      label: t(
        `${resourceName}.form.enums.other_vehicle_type.ROBOT_LAWN_MAWER`
      ),
      key: '6'
    }
  ];

  return (
    <ListResource
      extraQuery={`company=${company}&fleet_type=OTHER_VEHICLE`}
      resourceName="vehicles"
      exportUrl={`exports/company/${company}/fleet-type/OTHER_VEHICLE`}
      importUrl={`imports-vehicles/company/${company}/vehicle-type/other_vehicle_type`}
      tradKey="other_vehicle"
      exportTemplateUrl="export-templates/template/other-vehicle"
      withExtraPageHeader={
        <Tabs
          defaultActiveKey="1"
          onChange={onChange}
          items={tabs}
          type="card"
        />
      }
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <>
          <SelectByEntity onChange={onFilterEntity} />
          <SelectBySite onChange={onFilterSite} />
          <FilterByMissingDocuments onChange={onFilterMissingDocuments} />
        </>
      }
      populate="entity,site,principal_driver,contract,company"
      editAction={false}
      showAction={false}
      sheetName={t('other_vehicle.title')}
      headers={[]}
      withUploadButton={showData}
      permissionReintegrate={reintegrate}
    />
  );
};
