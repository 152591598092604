import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Popconfirm, Table } from 'antd';
import {
  CopyOutlined,
  ContainerOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  PrinterOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const iconSize = 18;

/**
 * A datatable component that fetches and displays data.
 *
 * @component
 * @param {Object} props The properties for the Datatable component.
 * @param {string} props.resourceName The resource name to fetch data for.
 * @param {string} [props.path=null] Optional path to be used for resource URIs.
 * @param {Array} props.columns The column configurations for the table.
 * @param {boolean} [props.customActionColumn=false] Flag to indicate whether to add custom actions or not.
 * @param {string} [props.populate=null] Fields to populate when fetching data.
 * @param {Object} [props.style=null] Optional styles to apply to the table.
 * @param {string} [props.extraQuery=null] Extra query string to be added to the API call.
 * @param {boolean} [props.forceRefresh=null] Flag to forcefully refresh the table.
 * @param {boolean|Object} [props.showAction=true] Configuration for show action.
 * @param {boolean|Object} [props.editAction=true] Configuration for edit action.
 * @param {boolean} [props.deleteAction=true] Flag to enable or disable the delete action.
 * @param {boolean|Object} [props.duplicateAction=false] Configuration for duplicate action.
 * @param {boolean|Object} [props.printAction=false] Configuration for print action.
 * @param {boolean|Object} [props.onDoubleClickAction=true] Configuration for action on double-click.
 * @param {Object} [props.scroll=null] Scroll configuration for the table.
 * @param {Object} [props.expandable=undefined] Configuration for expandable rows.
 * @param {string} [props.rowKey='_id'] Key field name for each row of data.
 * @param {boolean} props.isArchived Flag to indicate if the resources are archived.
 * @param {Function} [props.setLoaded=undefined] Function to set the loading state.
 * @param {boolean} [props.loaded=false] Current loading state.
 * @param {string} [props.dataToFetch=null] Data resource name to fetch if different from `resourceName`.
 * @param {boolean} [props.unarchive=false] Flag to unarchive resources.
 * @returns {JSX.Element} The datatable component.
 */

export const Datatable = ({
  resourceName,
  path,
  columns,
  customActionColumn,
  populate,
  style,
  extraQuery,
  forceRefresh,
  showAction,
  editAction,
  deleteAction,
  duplicateAction,
  printAction,
  onDoubleClickAction,
  scroll,
  expandable,
  rowKey,
  isArchived,
  setLoaded,
  loaded,
  dataToFetch,
  unarchive,
  permissionReintegrate,
  setExportDatas,
  changeDone,
  noPagination
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('k');
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  let bigTotal = 0;
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total) =>
      bigTotal ? `${total} sur ${bigTotal} éléments` : `${total} éléments`,
    showSizeChanger: true
  });

  const fetchData = useCallback(
    async (page = pagination) => {
      setIsLoading(true);

      const searchURL = searchValue ? `/search/${searchValue}` : null;

      let sortingParameter;
      if (currentSorter) sortingParameter = `sort=${currentSorter}&`;
      let filterParameter;
      if (currentFilters)
        filterParameter = `${currentFilters.replaceAll('__', '&')}`;
      try {
        const { data, headers } = await dispatchAPI('GET', {
          url: `/${dataToFetch || resourceName}${
            searchURL || ''
          }?deleted=${isArchived}&${extraQuery ? `${extraQuery}&` : ''}${
            sortingParameter || ''
          }${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }limit=${pageSize}&skip=${(currentPage - 1) * pageSize}`
        });
        setPagination({
          ...page,
          total: parseInt(headers?.['x-total-count'] || 0, 10)
        });
        if (headers['x-total-result'])
          bigTotal = parseInt(headers['x-total-result'], 10);
        setExportDatas(data);
        setResources(data);
        if (setLoaded && loaded !== true) setLoaded(true);
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    },
    [
      searchValue,
      currentPage,
      pageSize,
      currentSorter,
      currentFilters,
      forceRefresh,
      extraQuery,
      isArchived,
      changeDone
    ]
  );

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });
      await fetchData();
    } catch (e) {
      message(e);
    }
  };
  const reintegrate = async (id) => {
    try {
      await dispatchAPI('PATCH', { url: `/${resourceName}/reintegrate/${id}` });
      await fetchData();
    } catch (e) {
      message(e);
    }
  };

  const duplicateResource = async (id) => {
    try {
      await dispatchAPI('POST', { url: `/${resourceName}/duplicate/${id}` });
      await fetchData();
    } catch (e) {
      message(e);
    }
  };

  const printResource = async (id) => {
    try {
      await dispatchAPI('GET', { url: `/files/${id}` });
      await fetchData();
    } catch (e) {
      message(e);
    }
  };

  const handlePageChange = (page, filters, sorters = {}) => {
    let sortingParameter;
    if (sorters) {
      if (!sorters.order) {
        sortingParameter = null;
      } else if (sorters.order === 'descend') {
        sortingParameter = `&s=-${sorters.columnKey}`;
      } else {
        sortingParameter = `&s=${sorters.columnKey}`;
      }
    }
    let filterParameter = '';
    Object.entries(filters || {}).forEach((el) => {
      if (el[1] && el[1].length) filterParameter += `${el[0]}=${[...el[1]]}__`;
    });
    navigate({
      pathname,
      search: `?p=${page.current}${
        page && page.pageSize ? `&pS=${page.pageSize}` : ''
      }${sortingParameter || ''}${
        filterParameter ? `&f=${filterParameter}` : ''
      }${searchValue ? `&k=${searchValue}` : ''}`
    });
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          {duplicateAction && (
            <CopyOutlined
              onClick={() => duplicateResource(record?.[rowKey])}
              style={{ fontSize: iconSize }}
            />
          )}
          {showAction && (
            <Link
              to={{
                pathname: showAction.pathname
                  ? showAction.pathname(record)
                  : `${path || pathname}/show/${record?.[rowKey]}`
              }}
            >
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
          )}
          {editAction && (
            <Link
              to={{
                pathname: editAction.pathname
                  ? editAction.pathname(record)
                  : `${path || pathname}/edit/${record?.[rowKey]}`
              }}
            >
              <EditOutlined style={{ fontSize: iconSize }} />
            </Link>
          )}
          {printAction && (
            <PrinterOutlined
              onClick={() => printResource(record?.[rowKey])}
              style={{ fontSize: iconSize }}
            />
          )}
          {deleteAction && !unarchive && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => deleteResource(record?.[rowKey])}
              icon={<WarningOutlined />}
            >
              <ContainerOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          )}
          {deleteAction && unarchive && permissionReintegrate && (
            <Popconfirm
              title={t('datatable.column.action.unarchive.title')}
              okText={t('datatable.column.action.unarchive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.unarchive.cancel')}
              onConfirm={() => reintegrate(record?.[rowKey])}
              icon={<WarningOutlined />}
            >
              <CheckCircleOutlined
                style={{ color: 'var(--addColor)', fontSize: iconSize }}
              />
            </Popconfirm>
          )}
        </Flex>
      )
    }
  ];

  const adjustedColumns = columns.map((col) => {
    let order;
    let orderKey;
    if (currentSorter) {
      order = 'ascend';
      orderKey = currentSorter;
    }
    if (currentSorter && currentSorter.charAt(0) === '-') {
      order = 'descend';
      orderKey = currentSorter.substring(1);
    }
    const filters = {};
    if (currentFilters) {
      const filtersList = currentFilters.split('__');
      filtersList.forEach((f) => {
        if (f.split('=').length) {
          const [key, values] = f.split('=');
          filters[key] = values;
        }
      });
    }

    return {
      ...col,
      sortOrder: col.key === orderKey ? order : false,
      filteredValue: filters[col.key] ? filters[col.key].split(',') : []
    };
  });

  return (
    <Table
      style={style}
      scroll={scroll}
      rowKey={rowKey}
      rowClassName="rowStyle"
      onRow={(record) => ({
        ...(onDoubleClickAction
          ? {
              onDoubleClick: () =>
                onDoubleClickAction.action
                  ? onDoubleClickAction.action(record)
                  : navigate(`${path || pathname}/show/${record[rowKey]}`)
            }
          : {})
      })}
      dataSource={resources}
      loading={isLoading}
      onChange={handlePageChange}
      pagination={
        noPagination ? false : { ...pagination, current: currentPage, pageSize }
      }
      columns={
        customActionColumn
          ? adjustedColumns
          : [...adjustedColumns, ...actionColumn]
      }
      expandable={
        !isLoading && resources.length && expandable
          ? {
              ...expandable,
              expandedRowKeys:
                expandable.expandedRowKeys || resources.map((r) => r.key)
            }
          : undefined
      }
    />
  );
};

Datatable.propTypes = {
  resourceName: PropTypes.string.isRequired,
  path: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string.isRequired,
      dataIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
      ]),
      render: PropTypes.func,
      sorter: PropTypes.bool,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  ).isRequired,
  customActionColumn: PropTypes.bool,
  populate: PropTypes.string,
  style: PropTypes.shape({}),
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({
    expandedRowKeys: PropTypes.arrayOf(PropTypes.string)
  }),
  rowKey: PropTypes.string,
  isArchived: PropTypes.bool.isRequired,
  setLoaded: PropTypes.func,
  loaded: PropTypes.bool,
  dataToFetch: PropTypes.string,
  unarchive: PropTypes.bool,
  permissionReintegrate: PropTypes.bool,
  setExportDatas: PropTypes.func.isRequired,
  changeDone: PropTypes.bool.isRequired,
  noPagination: PropTypes.bool
};

Datatable.defaultProps = {
  path: null,
  customActionColumn: false,
  populate: null,
  style: null,
  extraQuery: null,
  forceRefresh: null,
  editAction: true,
  showAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  rowKey: '_id',
  setLoaded: undefined,
  loaded: false,
  dataToFetch: null,
  unarchive: false,
  permissionReintegrate: true,
  noPagination: false
};
