import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../../contexts/AuthContext';

import useFields from './utils/fieldsAutomissions';

/**
 * `AutomissionsCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const AutomissionsCreateUpdate = ({ purpose }) => {
  const resource = 'vehicles';
  const fleet_type = 'AUTOMISSION';
  const mandatoryDocuments = [
    'collaborator_vehicle_registration_document',
    'collaborator_driving_licence'
  ];
  const draggerFilesKeysOverRide = [
    'collaborator_vehicle_registration_document',
    'collaborator_driving_licence'
  ];
  const draggerFilesKeysMandatory = [
    'collaborator_vehicle_registration_document',
    'collaborator_driving_licence'
  ];
  const { fieldsAutomissions, isFieldsLoading } = useFields();
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();
  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        start_of_the_mission:
          data.start_of_the_mission && dayjs(data.start_of_the_mission),
        planned_end_of_the_mission:
          data.planned_end_of_the_mission &&
          dayjs(data.planned_end_of_the_mission),
        created_at: data.created_at && dayjs(data.created_at),
        desired_effective_date:
          data.desired_effective_date && dayjs(data.desired_effective_date),
        departure_time: data.departure_time && dayjs(data.departure_time)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company,
        programmes_types: 'FLEET',
        fleet_type
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };
  return (
    <CreateUpdateContainer
      fields={fieldsAutomissions}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      mandatoryDocuments={mandatoryDocuments}
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      draggerFilesKeysMandatory={draggerFilesKeysMandatory}
      title={t('vehicles.form.title.automissions')}
      baseUrl="vehicles"
      resource={resource}
      config={config}
      extraTitle={
        <Row justify="center" className="form-company-name">{`${t(
          'folder'
        )} ${companyName}`}</Row>
      }
      withFilesManager
      populate="?populate=documents.file"
    />
  );
};

AutomissionsCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
