import { useTranslation } from 'react-i18next';
import { useColumns } from '../../../components/columnsSubscriptionTypeContract';
import { useActionColumn } from './actionColumnFreightedGoods';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../../contexts/AuthContext';

/**
 * `ListFreightedGoods` is a React component designed to list and manage light vehicles.
 * It provides filtering options by vehicle type, entity, missing documents and site.
 * The component fetches the data based on the filters applied and displays it in a tabular format.
 *
 * @component
 * @returns {ReactElement} Rendered `ListFreightedGoods` component.
 */

export const ListFreightedGoods = () => {
  const { company } = useAuthContext();
  const columns = useColumns();
  const actionColumn = useActionColumn();
  const { t } = useTranslation();

  return (
    <ListResource
      extraQuery={`company_Id=${company}&sub_programme=FREIGHTED_GOODS`}
      resourceName="transports"
      exportUrl={`exports/company/${company}/sub-programme/FREIGHTED_GOODS`}
      importUrl={`imports-transports/company/${company}/sub-programme/FREIGHTED_GOODS`}
      tradKey="freighted-goods"
      exportTemplateUrl="export-templates/template/freighted-goods"
      columns={[...columns, ...actionColumn]}
      noCreateButton
      populate="contract,entity,company_Id"
      sheetName={t('transports.title')}
      headers={[]}
      editAction={false}
      showAction={false}
      duplicateAction={false}
      printAction={false}
      deleteAction={false}
    />
  );
};
