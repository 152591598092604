import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Tabs } from 'antd';
import { EditOutlined, MailOutlined } from '@ant-design/icons';
import {
  Building,
  ContainerServices,
  UserSettings,
  Calculator
} from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContent } from './Entities/listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  ExportButton
} from '../../../components';
import CompanyAndEntitiesData from './Entities/CompanyAndEntitiesData';
import Organization from './Organization/Organization';
import { PermissionsManagement } from './PermissionsManagement/PermissionsManagement';
import { CreateUpdateCalculator } from './Calculator/CreateUpdateCalculator';
import { routes } from '../../../utils/constants/adminRoutes';

export const ShowCompany = () => {
  const { company, user } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState({});
  const listContent = useListContent(companyData);
  const [activeTabKey, setActiveTabKey] = useState('1');

  const getCompany = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${company}?populate=unit_manager,unit_support`
      });
      setCompanyData(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [company]);

  useEffect(() => {
    (async () => {
      await getCompany();
    })();
  }, [getCompany]);

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const tabs = [
    {
      key: '1',
      label: (
        <>
          <Building />
          {t(`companies.form.entities`)}
        </>
      ),
      children: (
        <CompanyAndEntitiesData
          isLoading={isLoading}
          listContent={listContent}
        />
      )
    },
    {
      key: '2',
      label: (
        <>
          <ContainerServices />
          {t(`companies.organisation`)}
        </>
      ),
      children: <Organization isLoading={isLoading} listContent={listContent} />
    },
    {
      key: '3',
      label: (
        <>
          <UserSettings />
          {t(`companies.rights_management`)}
        </>
      ),
      children: <PermissionsManagement />
    }
  ];

  if (user?.role === 'admins:SUPER-ADMIN' || user?.role === 'admins:ADMIN') {
    tabs.push({
      key: '4',
      label: (
        <>
          <Calculator />
          {t(`companies.rate`)}
        </>
      ),
      children: <CreateUpdateCalculator />
    });
  }

  return (
    <>
      <PageHeaderCustom
        title={`${t('companies.show.title')}${companyData.name}`}
        extra={
          <Col>
            <Row justify="end">
              <Link
                to={{
                  pathname: `${routes.COMPANIES}/edit/${company}`
                }}
              >
                <Button>
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            </Row>
            {activeTabKey === '3' ? (
              <Row align="middle">
                <ExportButton />
                <Button icon={<MailOutlined />}>
                  {t('companies.send_data')}
                </Button>
              </Row>
            ) : null}
          </Col>
        }
      />
      <ContentCustom>
        <Tabs
          activeKey={activeTabKey}
          onChange={handleTabChange}
          items={tabs}
          type="card"
        />
      </ContentCustom>
    </>
  );
};
