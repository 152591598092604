import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Button,
  Skeleton,
  Row,
  Col,
  Card,
  Steps,
  Modal,
  Form,
  Input
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './utils/listContentSubscription';
import { handleStatusUtils } from './utils/handleStatusUtils';
import { useListItemsStep } from './utils/listItemsStep';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { ContractCreateUpdate } from './ContractCreateUpdate';
import { Documents } from './utils/Documents';
import { routes } from '../../utils/constants/adminRoutes';
import { History } from './utils/History';
import { SubscriptionContext } from './SubscriptionContext';

/**
 * Displays detailed information about a subscription.
 * @component
 * @returns {JSX.Element} The JSX element representing the subscription show page.
 */
export const SubscriptionShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [visible, setVisible] = useState(false);
  const { current, setCurrent, history, setHistory, onChange } =
    handleStatusUtils(setVisible);
  const { dispatchAPI, permissions, verifPermissions } = useAuthContext();
  const { items } = useListItemsStep();
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const [formattedLink, setFormattedLink] = useState('');
  const [datas, setDatas] = useState([]);
  const [contractNumber, setContractNumber] = useState();
  const [contractId, setContractId] = useState();
  const [isContractCreated, setIsContractCreated] = useState(false);
  const [accept, setAccept] = useState(true);
  const [edit, setEdit] = useState(true);
  const [draggerFilesKeysOverRide, setDraggerFilesKeysOverRide] = useState([]);
  const listContent = useListContent(subscription);
  const { forceRefresh, subProgramme, setSubProgramme } =
    useContext(SubscriptionContext);

  const formatLink = (program) => program.toLowerCase().replace('_', '-');

  const getSubscription = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/subscriptions/${id}?populate=customer_manager,unit_manager,company_id,documents,contract,contract.documents`
      });
      setContractId(
        data.contract?.documents?.filter(
          (doc) => doc.document_type === 'CONTRACT'
        )[0]?._id
      );
      setContractNumber(data.contract?.contract_number);
      setSubscription(data);
      setFormattedLink(formatLink(data.programme_types));
      setCurrent(data.step);
      setHistory(data.history);
      setDatas(data);
      setIsContractCreated(data.contract_created);
      setSubProgramme(data.sub_programme[0]);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [forceRefresh]);
  useEffect(() => {
    (async () => {
      await getSubscription();
    })();
  }, []);

  useEffect(() => {
    if (current === 3) {
      setAccept(false);
      setEdit(false);
    }
    if (permissions?.notPermittedRoutes?.length > 0) {
      setAccept(
        !verifPermissions(
          '/subscriptions/contract/accept',
          permissions?.notPermittedRoutes
        )
      );
      setEdit(
        !verifPermissions(
          '/subscriptions/contract/edit',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions, current]);

  useEffect(() => {
    switch (subProgramme) {
      case 'CIVIL_LIABILITY_PRO':
        setDraggerFilesKeysOverRide([
          'company_KBIS',
          'programme_type_document',
          'certificate_of_current_contract_or_copy',
          'others'
        ]);
        break;
      case 'CIVIL_LIABILITY_EXECUTIVE':
        setDraggerFilesKeysOverRide([
          'company_KBIS_lte_3_months',
          'organization_chart_of_the_company'
        ]);
        break;
      case 'OTHER':
        setDraggerFilesKeysOverRide([
          'subscription_form',
          'resume',
          'company_presentation',
          'informations_report_if_insured'
        ]);
        break;

      default:
        break;
    }
  }, [subProgramme]);

  const handleOk = (data) => {
    const { refusal_reason } = data;

    onChange(3, id, true, refusal_reason);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <PageHeaderCustom
        title={`${t(
          `subscriptions.form.${subscription.programme_types}`
        )} - ${t(`subscriptions.form.${subscription.sub_programme}`)} - ${t(
          'subscriptions.form.subscription_request'
        )}`}
        extra={
          <>
            {accept && current === undefined ? (
              <Button
                icon={<CheckOutlined />}
                onClick={() => {
                  onChange(0, id);
                }}
              >
                {`${t('subscriptions.form.acknowledge_receipt')} `}
              </Button>
            ) : null}
            {!isContractCreated && edit && (
              <Link
                to={{
                  pathname: `${routes.SUBSCRIPTIONS}/${formattedLink}/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            )}
          </>
        }
      />
      <ContentCustom>
        <Row>
          {current !== undefined && (
            <Steps
              onChange={(step) => {
                onChange(step, id, datas);
              }}
              items={items}
              current={current}
              size="small"
              style={{ marginBottom: 16 }}
            />
          )}
        </Row>
        <Row style={{ flexWrap: 'nowrap' }}>
          <Col
            span={
              current === 2 && datas && !isContractCreated && edit ? 12 : 24
            }
          >
            <Row gutter={[16, 16]} style={{ flexWrap: 'wrap' }}>
              <Col>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <Card title={t('subscriptions.form.informations')}>
                    <DescriptionList
                      data={listContent}
                      translate
                      layout="vertical"
                    />
                  </Card>
                </Skeleton>
              </Col>
              {formattedLink &&
              formattedLink !== 'transport' &&
              draggerFilesKeysOverRide &&
              draggerFilesKeysOverRide.lenght !== 0 &&
              !isContractCreated ? (
                <Col xs={24} xl={12} xxl={8}>
                  <Documents
                    idWithoutParams={id}
                    baseUrl="subscriptions"
                    resource="Subscription"
                    draggerFilesKeysOverRide={draggerFilesKeysOverRide}
                    setCurrent={setCurrent}
                  />
                </Col>
              ) : null}
              <Card title={t('subscriptions.form.history')}>
                <History history={history} />
              </Card>
              {isContractCreated && (
                <Card>
                  <Link
                    to={{
                      pathname: `${routes.DOCUMENTS}/show/${contractId}`
                    }}
                  >
                    {t(`subscriptions.form.created_contract`)}
                    {`${contractNumber}`}
                  </Link>
                </Card>
              )}
            </Row>
          </Col>
          {current === 2 && datas && !isContractCreated && edit && (
            <Col xs={24} xl={12} xxl={8}>
              <ContractCreateUpdate
                purpose="create"
                programmeType={datas.programme_types}
                subProgrammeType={datas.sub_programme}
                WithPageHeaderCustom={false}
                stepper
                setCurrent={onChange}
                subId={id}
              />
            </Col>
          )}
        </Row>
        <Modal
          title={t('subscriptions.form.confirmation')}
          onCancel={handleCancel}
          visible={visible}
          footer={null}
        >
          <Form onFinish={handleOk}>
            <Form.Item
              name="refusal_reason"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Row>
                <Button type="primary" htmlType="submit">
                  {t('buttons.validate')}
                </Button>
                <Button type="secondary" onClick={handleCancel}>
                  {t('buttons.cancel')}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </ContentCustom>
    </>
  );
};
