import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Card, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * @component
 * @param {Boolean} reload - A boolean to trigger a reload of the component.
 * @param {String} baseUrl - The base url of the API.
 * @param {Function} setAccountingBalanced - A function to set the accounting balance state.
 * @returns {ReactElement} A component to display the amount due.
 */

export const AmountDue = ({ reload, baseUrl, setAccountingBalanced }) => {
  const [amountDue, setAmountDue] = useState(0);
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();

  const getAmountDue = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `${baseUrl}/amount-due/${id}`
    });
    setAmountDue(data);
    if (data === 0) {
      setAccountingBalanced(true);
    } else {
      setAccountingBalanced(false);
    }
  };

  useEffect(() => {
    getAmountDue();
  }, [reload]);

  return (
    <Card>
      {t(`claims.form.amount_due`)} <Divider type="vertical" />
      {amountDue} €
    </Card>
  );
};

AmountDue.propTypes = {
  reload: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  setAccountingBalanced: PropTypes.func.isRequired
};
