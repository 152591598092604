import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columnsAdValorem';
import { useActionColumn } from './actionColumn';
import { ListResource } from '../../../../components/ListResource/ListResourceTransport';
import { SelectByEntity } from '../../../components/SelectByEntity';
import { SelectBySite } from '../../../components/SelectBySite';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../../components/FilterByMissingDocuments';

/**
 * `ListAdValorem` is a React component designed to list and manage ad valorem transports.
 * It provides filtering options by entity, and site.
 * The component fetches the data based on the filters applied.
 *
 * @component
 * @returns {ReactElement} Rendered `ListAdValorem` component.
 */

export const ListAdValorem = () => {
  const [entity, setEntity] = useState('');
  const [site, setSite] = useState('');
  const [isMissingDocuments, setIsMissingDocuments] = useState(false);
  const [showData, setShowData] = useState(true);
  const [edit, setEdit] = useState(true);
  const [reintegrate, setReintegrate] = useState(true);

  const { company, permissions, verifPermissions } = useAuthContext();
  const columns = useColumns();
  const actionColumn = useActionColumn(edit);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setShowData(
        !verifPermissions('datas/ad-valorem', permissions?.notPermittedRoutes)
      );
      setEdit(
        !verifPermissions(
          '/programmes/ad-valorem/edit/',
          permissions?.notPermittedRoutes
        )
      );
      setReintegrate(
        !verifPermissions(
          'programmes/ad-valorem/reintegrate',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  let filterParameter = `entity=${entity}`;
  const onFilterEntity = (entityId) => {
    setEntity(entityId || '');
  };

  const onFilterSite = (siteId) => {
    setSite(siteId || '');
  };

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (site !== '') {
      filterParameter = `entity=${entity}__site=${site}__`;
    } else {
      filterParameter = `entity=${entity}__`;
    }
    navigate({
      pathname: '/programmes/ad-valorem',
      search: `?f=${filterParameter}`
    });
  }, [entity, site, isMissingDocuments]);

  return (
    <ListResource
      extraQuery={`company_Id=${company}&sub_programme=AD_VALOREM`}
      resourceName="transports"
      exportUrl={`exports/company/${company}/sub-programme/AD_VALOREM`}
      importUrl={`imports-transports/company/${company}/sub-programme/AD_VALOREM`}
      tradKey="transport"
      exportTemplateUrl="export-templates/template/advalorem"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <>
          <SelectByEntity onChange={onFilterEntity} />
          <SelectBySite onChange={onFilterSite} />
          <FilterByMissingDocuments onChange={onFilterMissingDocuments} />
        </>
      }
      populate="entity,site,contract,company_Id"
      editAction={false}
      showAction={false}
      sheetName={t('transport.title')}
      headers
      withUploadButton={showData}
      permissionReintegrate={reintegrate}
    />
  );
};
