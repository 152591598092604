import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectManager } from '../../../components/SelectManager';
import { SelectEnumsClaimsType } from '../../../components/SelectEnumsClaimsType';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * A hook for generating form fields configuration based on the provided parameters.
 *
 * @hook
 * @param {Function} setIsComplaint - A function to set the visibility fields in dragger.
 * @returns {Object} An object containing an array of form fields configuration.
 */

const { Group } = Radio;

export const useFields = (setIsComplaint) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const dateFormat = 'DD-MM-YYYY';

  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      rules: [{ required: false }],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['insurance_company_reference'],
      name: ['insurance_company_reference'],
      rules: [{ required: false }]
    },
    {
      label: ['sinister_date'],
      name: ['sinister_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['claim_type'],
      name: ['claim_type'],
      rules: [{ required: true }],
      input: (
        <SelectEnumsClaimsType
          dbKey="claim_type"
          url="construction_claims"
          setIsComplaint={setIsComplaint}
        />
      )
    },
    {
      label: ['lifted_reservations'],
      name: ['lifted_reservations'],
      rules: [{ required: true }],
      input: (
        <Group>
          {(enums?.liftedReservations || []).map((option) => (
            <Radio key={option} value={option}>
              {t(`constructions.form.${option}`)}
            </Radio>
          ))}
        </Group>
      )
    },
    {
      label: ['street_number'],
      name: ['street_number'],
      rules: [{ required: true }],
      startWithDivider: {
        title: t('claims.form.claim_location')
      }
    },
    {
      label: ['additional'],
      name: ['additional']
    },
    {
      label: ['street'],
      name: ['street'],
      rules: [{ required: true }]
    },
    {
      label: ['zip_code'],
      name: ['zip_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: ['country'],
      name: ['country'],
      rules: [{ required: true }]
    },
    {
      label: ['pictures_damages_caused'],
      name: ['pictures_damages_caused'],
      input: 'File',
      maxFilesCount: 5,
      multipleFiles: true,
      startWithDivider: {
        title: t('claims.form.pictures')
      },
      endWithDivider: {
        title: t('claims.form.documents')
      }
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/construction_claims/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsInformations,
    isFieldsLoading
  };
};
