import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Form } from 'antd';
import { AuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Item } = Form;
const { Option } = Select;

export const SelectManager = ({ dbKey, isDisabled }) => {
  const [managers, SetManagers] = useState([]);
  const { company, dispatchAPI } = useContext(AuthContext);
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  let role;
  if (dbKey === 'customer_manager') {
    role = { role: 'users:USER' };
  } else {
    role = { role: 'admins:ADMIN' };
  }

  const getManager = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/users/companies/${company}`,
        body: role
      });
      SetManagers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getManager();
    })();
  }, [getManager]);

  return (
    <Item name={[`${dbKey}`]}>
      <Select disabled={isDisabled}>
        {managers
          ? managers.map((manager) => (
              <Option key={manager._id} value={manager._id}>
                {t(`${manager.first_name}`)}&nbsp;{t(`${manager.last_name}`)}
              </Option>
            ))
          : null}
      </Select>
    </Item>
  );
};

SelectManager.propTypes = {
  dbKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool
};

SelectManager.defaultProps = {
  isDisabled: false
};
