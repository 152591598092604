import { createContext, useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useStateWithLocalStorage } from '../utils';
import { routes as userRoutes } from '../utils/constants/userRoutes';
import { routes as adminRoutes } from '../utils/constants/adminRoutes';
import { routes as superAdminRoutes } from '../utils/constants/superAdminRoutes';
import { routes as gestionnaireRoutes } from '../utils/constants/gestionnaireRoutes';
import { routes as superUserRoutes } from '../utils/constants/superUserRoutes';

/**
 * Context for handling authentication data and functions.
 * @type {React.Context}
 */

export const AuthContext = createContext({ isValid: false });

/**
 * Axios instance to interact with the API.
 * @type {axios.AxiosInstance}
 */

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' }
});

/**
 * Component that provides an authentication context to its children.
 * Manages authentication state and exposes authentication-related functions.
 * @component
 * @param {Object} props - The component properties.
 * @param {React.ReactNode} props.children - Children elements wrapped by the context provider.
 */

export const AuthContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const [user, setUser] = useStateWithLocalStorage('user', {
    first_name: 'John',
    last_name: 'Doe',
    role: 'admins:ADMIN'
  });
  const [token, setToken] = useStateWithLocalStorage('token');
  const [company, setCompany] = useStateWithLocalStorage('company', '');
  const [companyName, setCompanyName] = useStateWithLocalStorage(
    'companyName',
    ''
  );
  const [refreshToken, setRefreshToken] =
    useStateWithLocalStorage('refresh_token');
  const [remember, setRememberMe] = useStateWithLocalStorage(
    'remember_me',
    !!refreshToken
  );
  const [isValid, setIsValid] = useState(!!refreshToken || !!token);
  const [pendingTasksTray, setPendingTasksTray] = useStateWithLocalStorage(
    'pendingTasksTray',
    []
  );
  const [isPermitted, setIsPermitted] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);

  const setSession = (accessToken, logout) => {
    const findRouteKeyByPath = (path, routes) => {
      const routeKeys = Object.keys(routes);
      const foundKey = routeKeys.find((key) => routes[key] === `/${path}`);
      return foundKey || null;
    };

    const prevUrl = window.location.href.split('/').pop();
    const routeKey = findRouteKeyByPath(prevUrl, {
      ...userRoutes,
      ...adminRoutes,
      ...superAdminRoutes,
      ...gestionnaireRoutes,
      ...superUserRoutes
    });

    if (accessToken) {
      setToken(accessToken);
      setIsValid(true);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      setToken(null);
      setRefreshToken(null);
      setUser(null);
      setIsValid(false);
      delete axiosInstance.defaults.headers.common.Authorization;
      if (!logout && routeKey) {
        message.warning(t('login.expiredSession'));
      }
    }
  };

  const loginAPI = async (email, password, rememberMe) => {
    const result = await axiosInstance.get('/login', {
      auth: {
        username: email,
        password
      }
    });
    setUser(result.data.user);
    setRememberMe(rememberMe);
    if (rememberMe) setRefreshToken(result.data.refresh_token);
    setSession(result.data.token, false);

    const role = result?.data?.user?.role;
    if (!['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(role)) {
      setCompany(result?.data?.user?.company_Id);
    }
    if (['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(role)) {
      setCompany('64e480fb7b38bc884859ad2f');
    }
    return result;
  };

  const registerAPI = (values) => axiosInstance.post('/register', values);

  const isTokenExpired = () => {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (Number(decodedToken.exp) < Number(currentTime)) {
      return true;
    }
    return false;
  };

  const logout = () => {
    setSession(null, true);
    setUser(null);
  };

  const isTokenValid = async () => {
    if ((!token || isTokenExpired()) && !refreshToken) {
      setSession(null, false);
      return false;
    }
    try {
      if (remember && refreshToken) {
        const result = await axiosInstance.post('/token/refresh', {
          refresh_token: refreshToken
        });
        setToken(result.data.newToken);
        return { newToken: result.data.newToken };
      }
    } catch (e) {
      message.warning(t('login.expiredSession'));
      setSession(null, false);
      return false;
    }
    if (!isValid) {
      setIsValid(true);
    }
    return true;
  };

  useEffect(() => {
    (async () => {
      await isTokenValid();
    })();
  }, []);

  const fetchAPI = async (
    url,
    method = 'GET',
    body = undefined,
    responseType = 'json',
    cancelToken = undefined
  ) => {
    const { newToken } = await isTokenValid();
    if (
      ['POST', 'PATCH', 'DELETE'].includes(method) &&
      !window.navigator.onLine
    ) {
      setPendingTasksTray([
        ...(pendingTasksTray || []),
        ...[{ url, method, body }]
      ]);
      return new Response();
    }
    const result = await axiosInstance({
      url,
      method,
      responseType,
      cancelToken,
      data: body,
      headers: {
        Authorization: `Bearer ${newToken || token}`
      }
    });
    return result;
  };

  const dispatchAPI = (type, options) => {
    switch (type) {
      case 'LOGIN':
        return loginAPI(options.email, options.password, options.rememberMe);
      case 'REGISTER':
        return registerAPI(options);
      case 'LOGOUT':
        return logout();
      case 'GET':
        return fetchAPI(
          options.url,
          'GET',
          null,
          options.responseType,
          options.cancelToken
        );
      case 'DELETE':
        return fetchAPI(options.url, 'DELETE');
      case 'POST':
      case 'PATCH':
        return fetchAPI(options.url, type, options.body);
      default:
        throw new Error('Unknown dispatchAPI type!');
    }
  };

  const getPermissions = async () => {
    const role = user?.role;

    try {
      const { data } = await dispatchAPI('POST', {
        url: '/permissions/notPermittedRoutes',
        body: { role }
      });
      setPermissions(data);
    } catch (e) {
      message.error(e.message || 'An error occurred');
    }
  };

  const getUserPermissions = async () => {
    if (user?.role !== 'users:USER') return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `permissions/${user?.permissions}`
      });
      const userPermissionsArray = data?.permissions;
      setUserPermissions(userPermissionsArray);
    } catch (e) {
      message.error(e.message || 'An error occurred');
    }
  };

  useEffect(() => {
    if (token) {
      (async () => {
        await getPermissions();
        await getUserPermissions();
      })();
    }
  }, [token]);

  const verifPermissions = (route, notPermittedRoutes) =>
    notPermittedRoutes.includes(route);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        company,
        setCompany,
        companyName,
        setCompanyName,
        token,
        isValid,
        dispatchAPI,
        remember,
        pendingTasksTray,
        setPendingTasksTray,
        verifPermissions,
        permissions,
        setIsPermitted,
        isPermitted,
        userPermissions
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Hook to use the authentication context.
 * @throws Will throw an error if not used within an `AuthContextProvider`.
 * @returns {Object} The authentication context.
 */

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
