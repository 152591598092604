import { Link } from 'react-router-dom';
import { Popconfirm, Flex, Tooltip } from 'antd';
import {
  ClearOutlined,
  EyeOutlined,
  FormOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const iconSize = 18;

/**
 * Creates an action column configuration for a table, including various icons and links.
 *
 * @function
 * @param {string} actionColumnUrl - The URL for the action column.
 * @param {string} deleteUrl - The URL for the delete action.
 * @returns {Array} An array representing the action column configuration.
 */

export const useActionColumn = (actionColumnUrl, deleteUrl) => {
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  const deleteVehicleClaim = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${deleteUrl}/delete/${id}`
      });
    } catch (e) {
      message(e);
    }
  };

  return [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <Flex align="center" wrap="nowrap" justify="end" gap="small">
          <Link to={`/claims/${actionColumnUrl}/show/${record?._id}`}>
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {user.role === 'admins:SUPER-ADMIN' ||
          user.role === 'admins:ADMIN' ? (
            <>
              <Tooltip title={t('light_vehicle.actions.specific_demand')}>
                <FormOutlined style={{ fontSize: iconSize }} />
              </Tooltip>
              <Tooltip title={t('light_vehicle.actions.delete')}>
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={() => deleteVehicleClaim(record?._id)}
                  icon={<WarningOutlined />}
                >
                  <ClearOutlined style={{ fontSize: iconSize, color: 'red' }} />
                </Popconfirm>
              </Tooltip>
            </>
          ) : null}
        </Flex>
      )
    }
  ];
};
