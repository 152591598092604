import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

export const AttestationGenerationButton = () => {
  const { t } = useTranslation();

  return (
    <Button icon={<SyncOutlined />}>
      {`${t('claims.form.generate_take_charge_attestation')} `}
    </Button>
  );
};
