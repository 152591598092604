import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFields } from '../fieldsCivilLiability';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';

/**
 * Provides a component for creating or updating freighted goods insurance claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for freighted goods insurance claims.
 */
export const CivilLiabilityProCreateUpdate = ({ purpose }) => {
  const { dispatchAPI, company } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [isOther, setIsOther] = useState(false);
  const [civilLiabilityPro, setCivilLiabilityPro] = useState();
  const { fieldsInformations } = useFields(isOther, setIsOther);

  const draggerFilesKeysOverRide = ['full_statement'];
  const mandatoryDocuments = ['full_statement'];
  const draggerFilesKeysMandatory = ['full_statement'];

  const getCivilLiabilityProCreateUpdate = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `civil-liability/${id}?populate=entity,site,contract,company_id`
      });
      setCivilLiabilityPro(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getCivilLiabilityProCreateUpdate();
    })();
  }, [id]);

  const config = {
    onGetResource: {
      setFields: (data) => {
        if (data.claim_type === 'OTHER') {
          setIsOther(true);
        }
        return {
          ...data,
          sinister_date: data.sinister_date && dayjs(data.sinister_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: civilLiabilityPro?._id,
        company_id: company,
        contract: civilLiabilityPro?.contract?._id,
        opening_date: dayjs(Date.now()),
        city: civilLiabilityPro?.city,
        entity: civilLiabilityPro?.contract?.entity,
        site: civilLiabilityPro?.contract?.site,
        civil_liability_sub_programmes: 'CIVIL_LIABILITY_PRO'
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="civil_liability_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      mandatoryDocuments={mandatoryDocuments}
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      draggerFilesKeysMandatory={draggerFilesKeysMandatory}
    />
  );
};

CivilLiabilityProCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
