import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});

  const renderAddress = (text, record) => {
    const { number, street, additional } = record.contract || {};
    return number || street || additional
      ? `${number || ''} ${street || ''} ${additional || ''}`
      : '-';
  };

  const allColumns = [
    {
      title: t('transports.form.contract_number'),
      key: 'contract',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('transports.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? moment(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('transports.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? moment(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('transports.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date
          ? moment(validity_end_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('transports.form.company_name'),
      key: 'company_name',
      dataIndex: ['company_Id', 'name'],
      sorter: true
    },
    {
      title: t('transports.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      sorter: true
    },
    {
      title: t('transports.form.sub_entity'),
      key: 'sub_entity',
      dataIndex: ['sub_entity', 'name'],
      sorter: true
    },
    {
      title: t('transports.form.unit_reference_number'),
      key: 'unit_reference_number',
      dataIndex: ['unit_reference_number'],
      sorter: true
    },
    {
      title: t('transports.form.shipper'),
      key: 'shipper',
      dataIndex: 'shipper_name',
      sorter: true
    },
    {
      title: t('transports.form.departure_location'),
      key: 'departure_location',
      dataIndex: '',
      render: (record) => {
        const { departure_city, departure_country } = record;
        return `${departure_city}, ${departure_country}`;
      },
      sorter: true
    },
    {
      title: t('transports.form.parcel_quantity'),
      key: 'parcel_quantity',
      dataIndex: 'parcel_quantity',
      sorter: true
    },
    {
      title: t('transports.form.weight'),
      key: 'weight',
      dataIndex: 'weight',
      sorter: true
    },
    {
      title: t('transports.form.estimated_delivery_date'),
      key: 'estimated_delivery_date',
      dataIndex: ['estimated_delivery_date'],
      render: (estimated_delivery_date) =>
        estimated_delivery_date
          ? moment(estimated_delivery_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('transports.form.recipient'),
      key: 'recipient',
      dataIndex: 'recipient_name',
      sorter: true
    },
    {
      title: t('transports.form.arrival_location'),
      key: 'arrival_location',
      dataIndex: '',
      render: (record) => {
        const { arrival_city, arrival_country } = record;
        return `${arrival_city}, ${arrival_country}`;
      },
      sorter: true
    },
    {
      title: t('transports.form.means_of_transport'),
      key: 'means_of_transport',
      dataIndex: 'means_of_transport',
      sorter: true,
      render: (means_of_transport) =>
        (means_of_transport && t(`transports.form.${means_of_transport}`)) ||
        '-'
    },
    {
      title: t('transports.form.nature_of_the_goods'),
      key: 'nature_of_the_goods',
      dataIndex: 'nature_of_the_goods',
      sorter: true
    },
    {
      title: t('transports.form.value_of_the_goods'),
      key: 'value_of_the_goods',
      dataIndex: 'value_of_the_goods',
      sorter: true
    },
    {
      title: t('transports.form.subscriber_last_name_first_name'),
      key: 'subscriber_last_name_first_name',
      dataIndex: ['contract', 'subscriber_last_name_first_name'],
      sorter: true
    },
    {
      title: t('transports.form.subscriber_address'),
      key: 'subscriber_address',
      render: renderAddress
    },
    {
      title: t('transports.form.subscriber_postal_code'),
      key: 'subscriber_postal_code',
      dataIndex: ['contract', 'postal_code'],
      sorter: true
    },
    {
      title: t('transports.form.subscriber_city'),
      key: 'subscriber_city',
      dataIndex: ['contract', 'city'],
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/advalorem-configs/${company}`
      });
      setConfig(data[0]);
    } catch (e) {
      message(e);
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);
  return filteredColumns || [];
};
