import { Link } from 'react-router-dom';
import { Flex } from 'antd';
import { EditOutlined, EyeOutlined, FormOutlined } from '@ant-design/icons';
import { TrafficIncident } from '@carbon/icons-react';

const iconSize = 18;

/**
 * `useActionColumn` is a hook for generating an action column configuration in a table.
 *
 * @hook
 * @returns {Array} An array containing the action column configuration.
 * @property {string} key - The unique key for the action column.
 * @property {string} align - The alignment of the column (right).
 * @property {Function} render - A function to render the action column for each record.
 * @param {Object} record - The record object for which the action column is rendered.
 * @returns {JSX.Element} JSX elements representing action links.
 */

export const useActionColumn = (edit) => [
  {
    key: 'action',
    align: 'right',
    render: (record) => (
      <Flex gap="small" align="center" justify="end" wrap="nowrap">
        <Link to={`/programmes/ad-valorem/show/${record?._id}`}>
          <EyeOutlined style={{ fontSize: iconSize }} />
        </Link>
        {edit && (
          <Link to={`/programmes/ad-valorem/edit/${record?._id}`}>
            <EditOutlined style={{ fontSize: iconSize }} />
          </Link>
        )}
        <Link to={`/claims/ad-valorem/create/${record?._id}`}>
          <TrafficIncident size={iconSize} />
        </Link>
        <FormOutlined style={{ fontSize: iconSize }} />
      </Flex>
    )
  }
];
