import { Link } from 'react-router-dom';
import { Flex } from 'antd';
import { EditOutlined, EyeOutlined, FormOutlined } from '@ant-design/icons';
import { TrafficIncident } from '@carbon/icons-react';

const iconSize = 18;

export const useActionColumn = () => [
  {
    key: 'action',
    align: 'right',
    render: (record) => (
      <Flex gap="small" align="center" justify="end" wrap="nowrap">
        <Link to={`/programmes/automissions/show/${record?._id}`}>
          <EyeOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Link to={`/programmes/automissions/edit/${record?._id}`}>
          <EditOutlined style={{ fontSize: iconSize }} />
        </Link>
        <Link to={`/claims/automissions/create/${record?._id}`}>
          <TrafficIncident size={20} />
        </Link>
        <FormOutlined style={{ fontSize: iconSize }} />
      </Flex>
    )
  }
];
