import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Card, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';

export const CustomerTotalAgainst = ({ reload, baseUrl }) => {
  const [customerTotalAgainst, setCustomerTotalAgainst] = useState(0);
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();

  const getAmountTotalAgainst = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `${baseUrl}/total-against-appeal/${id}`
    });
    setCustomerTotalAgainst(data);
  };

  useEffect(() => {
    getAmountTotalAgainst();
  }, [reload]);

  return (
    <Card>
      {t(`claims.form.customer_total_against`)} <Divider type="vertical" />
      {customerTotalAgainst} €
    </Card>
  );
};

CustomerTotalAgainst.propTypes = {
  reload: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired
};
