import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Input, DatePicker, Select } from 'antd';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { SelectManager } from '../../../components/SelectManager';
import { SelectEntity } from '../../../components/SelectEntity';
import { SelectSite } from '../../../components/SelectSite';
import { SelectContract } from '../../../components/SelectContract';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { TextArea } = Input;
const { Option } = Select;

/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @returns
 * @property {Array} fields - An array of field configurations for the form.
 * @property {boolean} isFieldsLoading - A flag to determine whether the form fields are being loaded.
 * @hook
 */

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const dateFormat = 'DD-MM-YYYY';

  const fieldsGolf = [
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: <SelectContract dbKey="contract" programme_types="GOLF" />,
      startWithDivider: {
        title: t('vehicles.form.main_informations')
      }
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: <SelectEntity dbKey="entity" />
    },
    {
      label: ['site'],
      name: ['site'],
      rules: [{ required: true }],
      input: <SelectSite dbKey="site" />
    },
    {
      label: ['golf_name'],
      name: ['golf_name'],
      rules: [{ required: true }]
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      label: ['country'],
      name: ['country'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('golfs.form.select_a_country')}
          style={{ width: '100%' }}
        >
          {(enums?.countries || []).map((c) => (
            <Option key={c} value={c}>
              {c}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['total_insured_developed_area'],
      name: ['total_insured_developed_area'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['company_registration_number'],
      name: ['company_registration_number'],
      rules: [{ required: true }]
    },
    {
      label: ['previous_year_revenue'],
      name: ['previous_year_revenue'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['workforce_full_time_equivalent'],
      name: ['workforce_full_time_equivalent'],
      rules: [{ required: true }],
      input: <Input type="Number" />
    },
    {
      label: ['content'],
      name: ['content'],
      rules: [{ required: true }],
      input: <TextArea />,
      endWithDivider: {
        title: t('golfs.form.address')
      }
    },
    {
      name: ['number'],
      rules: [{ required: false }]
    },
    {
      name: ['additional'],
      rules: [{ required: false }]
    },
    {
      name: ['street'],
      rules: [{ required: false }],
      endWithDivider: {
        title: t('properties.form.photos')
      }
    },
    {
      name: ['photos_of_the_exterior'],
      input: 'File',
      maxFilesCount: 2,
      multipleFiles: true
    },
    {
      name: ['photos_of_the_interior'],
      input: 'File',
      maxFilesCount: 2,
      multipleFiles: true,
      endWithDivider: {
        title: t('golfs.form.documents')
      }
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/golfs/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsGolf,
    isFieldsLoading
  };
};
export default useFields;
