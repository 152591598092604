import { createGlobalStyle } from 'styled-components';
import '../SCSS/admin/index.scss';

export const AdminTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: #1B3837;
    --addColor: #47d267;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryBackground: #F3F1EE;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: rgba(49, 75, 73, 0.5);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #e6f7ff;
    --secondaryColor: #314B49;
    --disabledColor: #bfbfbf;
    --bodyBackground: var(--secondaryBackground);
    --textColor: var(--primaryColor);
    --textColorSecondary: var(--secondaryBackground);
    --itemActiveBackground: #e6f7ff;
    --itemHoverBackground: #f0f0f0;
    --borderColor: var(--gray500);
    --contrastBackground: #f5f5f5;
    --errorColor: #e31919;
    --warningColor: #E17A3B;
    --opacitySVG: 1;
    --gray100: #F8F9FA;
    --gray200: #E9ECEF;
    --gray300: #DEE2E6;
    --gray400: #CED4DA;
    --gray500: #ADB5BD;
    --gray600: #6C757D;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;
