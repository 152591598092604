import { Route, Routes } from 'react-router-dom';
import { ShowOtherConstruction } from './ShowOtherConstruction';
import { ListOtherConstruction } from './ListOtherConstruction';
import { Exception } from '../../../../components';

export const OtherConstructionRouter = () => (
  <Routes>
    <Route path="show/:id" element={<ShowOtherConstruction />} />
    <Route index element={<ListOtherConstruction />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
