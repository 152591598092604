import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { FileExcelOutlined } from '@ant-design/icons';
import { Modal, Spin, Typography, Button } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * A button component for exporting data to an XLSX file template.
 *
 * This component fetches data from the specified URL and exports it to an XLSX file.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.url - The URL for fetching data.
 * @param {string} props.sheetName - The name of the XLSX sheet.
 * @param {string} props.dataName - The name of the data source.
 *
 * @returns {ReactElement} The rendered `ExportXlsxTemplateButton` component.
 */

export const ExportXlsxTemplateButton = ({ url, sheetName, dataName }) => {
  const { dispatchAPI, companyName } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();

  const translateKeys = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      const newKey = t(`${dataName}.form.${key}`);
      newObj[newKey] = obj[key];
    });
    return newObj;
  };

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${url}`
      });

      const translatedData = data.map((item) => translateKeys(item, t));

      const updatedTranslatedData = translatedData.map((item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'Contrat')) {
          return { ...item, Contrat: companyName };
        }
        return item;
      });
      const ws = XLSX.utils.json_to_sheet(updatedTranslatedData);

      const colWidths = Object.keys(ws)
        .filter((key) => key.indexOf('1') === 1)
        .map((key) => ({ wch: ws[key].v.toString().length + 1 }));

      ws['!cols'] = colWidths;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${sheetName}`);
      XLSX.writeFile(wb, `${companyName}.xlsx`);
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <Modal
        closable={false}
        footer={false}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning size="large" style={{ margin: 16 }} />
        <br />
        <Typography.Text>
          Nous préparons votre fichier. Merci de patienter.
        </Typography.Text>
      </Modal>
      <Button onClick={fetchData} type="link">
        <FileExcelOutlined style={{ fontSize: '14px', marginRight: 8 }} />
        {t(`buttons.export_Xlsx_template`)}
      </Button>
    </>
  );
};

ExportXlsxTemplateButton.propTypes = {
  url: PropTypes.string,
  sheetName: PropTypes.string,
  dataName: PropTypes.string
};

ExportXlsxTemplateButton.defaultProps = {
  url: null,
  sheetName: null,
  dataName: null
};
