import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Custom hook for managing checked keys in a hierarchical data tree for a specific user.
 *
 * @hook
 * @param {string} userId - ID of the user.
 * @param {Array} dataTree - Hierarchical data tree to manage.
 * @returns {{ checkedKeys: Array, onCheck: Function }} - Checked keys and onCheck function.
 */
export const useCheckedKeysUserTwo = (userId, dataTree) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [checkedKeys, setCheckedKeys] = useState([]);

  const getCheckedKeys = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/permissions/${userId}`
      });
      return data;
    } catch (e) {
      message(e);
      return null;
    }
  };

  const fetchUserNodes = async () => {
    try {
      const userCheckedKeys = await getCheckedKeys(userId);
      setCheckedKeys(userCheckedKeys);
    } catch (e) {
      message(e);
    }
  };

  const onCheck = () => checkedKeys;

  useEffect(() => {
    (async () => {
      await fetchUserNodes();
    })();
  }, [userId, dataTree]);

  return { checkedKeys, onCheck };
};
