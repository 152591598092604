import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.constructionsFields - Field configurations for constructions.
 */

export const useConstructionsFields = () => {
  const constructionsFields = [
    {
      name: ['missing_documents'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['contract_number'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['date_added'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['entity'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['status'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['postal_code'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['city'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['desired_guarantees'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['subscriber'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['contracting_owner_if_different_from_the_subscriber'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['construction_site_name'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['construction_permit_number'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['construction_site_address'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['expected_construction_cost'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['expected_construction_starting_date'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['construction_starting_date'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['expected_construction_completion_date'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['construction_completion_date'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['amendment_new_construction_cost'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['reason_of_amendment_demand'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['rental_loss_guarantee'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['comments'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    }
  ];
  return { constructionsFields };
};
