import { Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import { FileTextOutlined, CarOutlined } from '@ant-design/icons';
import {
  Building,
  FloodWarning,
  Departure,
  Arrival,
  Delivery,
  UserSimulation
} from '@carbon/icons-react';
import { routes } from '../../utils/constants/adminRoutes';
import { NotificationsDetails } from './NotificationsDetails';

export const handleNotifMenuItemLink = (
  notifID,
  item,
  deleteNotification,
  t,
  createdAt,
  consulted,
  vehicule,
  golves,
  transports,
  property
) => {
  const action = item?.action;
  const ref = item?.ref;
  const GenerateContentVehicule = (title) => {
    const matchingVehicule = vehicule?.find((v) => v._id === item.type);

    return (
      <Flex justify="space-between" align="center" wrap="wrap" gap="small">
        <Flex align="center" gap="small">
          <FileTextOutlined />
          <Typography.Text strong style={{ marginRight: 4 }}>
            {title}
          </Typography.Text>
        </Flex>
        <Flex align="center" gap="small">
          <CarOutlined />
          <Typography.Text>
            {`${matchingVehicule?.vehicle_brand} `}
            {`${matchingVehicule?.registration_or_serial_number} `}
            {matchingVehicule?.vehicle_model}
          </Typography.Text>
        </Flex>
      </Flex>
    );
  };

  const GenerateContentClaim = (title) => (
    <Flex justify="space-between" align="center" wrap="wrap" gap="small">
      <Flex align="center" gap="small">
        <FloodWarning />
        <Typography.Text strong style={{ marginRight: 4 }}>
          {title}
        </Typography.Text>
      </Flex>
    </Flex>
  );

  const GenerateContentGolves = (title) => {
    const matchingGolf = golves?.find((g) => g._id === item.type);
    return (
      <Flex justify="space-between" align="center" wrap="wrap" gap="small">
        <Flex align="center" gap="small">
          <FileTextOutlined />
          <Typography.Text strong style={{ marginRight: 4 }}>
            {title}
          </Typography.Text>
        </Flex>
        <Flex align="center" gap="small">
          <Building />
          <Typography.Text>{`${matchingGolf?.golf_name} `}</Typography.Text>
          <Building />
          <Typography.Text>{`${matchingGolf?.entity?.name} `}</Typography.Text>
        </Flex>
      </Flex>
    );
  };

  const GenerateContentProperty = (title) => {
    const matchingProperty = property?.find((p) => p._id === item.type);
    return (
      <Flex justify="space-between" align="center" wrap="wrap" gap="small">
        <Flex align="center" gap="small">
          <FileTextOutlined />
          <Typography.Text strong style={{ marginRight: 4 }}>
            {title}
          </Typography.Text>
        </Flex>
        <Flex align="center" gap="small">
          <Building />
          <Typography.Text>{`${matchingProperty?.asset_name} `}</Typography.Text>
          <Building />
          <Typography.Text>{`${matchingProperty?.city} ${matchingProperty?.country}`}</Typography.Text>
        </Flex>
      </Flex>
    );
  };

  const GenerateContentTransports = (title) => {
    const matchingTransport = transports?.find(
      (transport) => transport._id === item.type
    );
    return (
      <Flex justify="space-between" align="center" wrap="wrap" gap="small">
        <Flex align="center" gap="small">
          <Delivery />
          <Typography.Text strong style={{ marginRight: 4 }}>
            {title}
          </Typography.Text>
        </Flex>
        <Flex align="center" gap="small">
          <Departure />
          <Typography.Text>
            {dayjs(matchingTransport?.departure_date).format('DD/MM/YYYY')}
          </Typography.Text>
          <Arrival />
          <Typography.Text>
            {dayjs(matchingTransport?.estimated_delivery_date).format(
              'DD/MM/YYYY'
            )}
          </Typography.Text>
        </Flex>
      </Flex>
    );
  };

  const GenerateContentCivil_Liability = (title) => (
    <Flex justify="space-between" align="center" wrap="wrap" gap="small">
      <Flex align="center" gap="small">
        <UserSimulation />
        <Typography.Text strong style={{ marginRight: 4 }}>
          {title}
        </Typography.Text>
      </Flex>
    </Flex>
  );

  const displayNotificationLink = () => {
    switch (true) {
      case ref === 'generateGreenCard' && action === 'Green Card Generated':
        return (
          <NotificationsDetails
            content={GenerateContentVehicule(
              t('notifications.generate_green_card')
            )}
            pathname={`${routes.PROGRAMMES}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'LIGHT_VEHICLE' &&
        (action === 'create' || action === 'in' || action === 'out'):
        return (
          <NotificationsDetails
            content={GenerateContentVehicule(
              t(`notifications.${action}_light_vehicule`)
            )}
            pathname={`${routes.PROGRAMMES}/light-vehicles/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'OTHER_VEHICLE' &&
        (action === 'create' || action === 'in' || action === 'out'):
        return (
          <NotificationsDetails
            content={GenerateContentVehicule(
              t(`notifications.${action}_other_vehicule`)
            )}
            pathname={`${routes.PROGRAMMES}/other-vehicles/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'HEAVY_VEHICLE' &&
        (action === 'create' || action === 'in' || action === 'out'):
        return (
          <NotificationsDetails
            content={GenerateContentVehicule(
              t(`notifications.${action}_heavy_vehicule`)
            )}
            pathname={`${routes.PROGRAMMES}/heavy-vehicles/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'AUTOMISSION' &&
        (action === 'create' || action === 'in' || action === 'out'):
        return (
          <NotificationsDetails
            content={GenerateContentVehicule(
              t(`notifications.${action}_automission`)
            )}
            pathname={`${routes.PROGRAMMES}/automissions/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Golf' &&
        (action === 'create' || action === 'in' || action === 'out'):
        return (
          <NotificationsDetails
            content={GenerateContentGolves(t(`notifications.${action}_golf`))}
            pathname={`${routes.PROGRAMMES}/golfs/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Property' &&
        (action === 'create' || action === 'in' || action === 'out'):
        return (
          <NotificationsDetails
            content={GenerateContentProperty(
              t(`notifications.${action}_property`)
            )}
            pathname={`${routes.PROGRAMMES}/properties/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'AD_VALOREM' &&
        (action === 'create' || action === 'in' || action === 'out'):
        return (
          <NotificationsDetails
            content={GenerateContentTransports(
              t(`notifications.${action}_ad_valorem`)
            )}
            pathname={`${routes.PROGRAMMES}/ad-valorem/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'CIVIL_LIABILITY_PRO' &&
        (action === 'create' || action === 'in' || action === 'out'):
        return (
          <NotificationsDetails
            content={GenerateContentCivil_Liability(
              t(`notifications.${action}_civil_liability_pro`)
            )}
            pathname={`${routes.PROGRAMMES}/civil-liability-pro/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'CIVIL_LIABILITY_EXECUTIVE' &&
        (action === 'create' || action === 'in' || action === 'out'):
        return (
          <NotificationsDetails
            content={GenerateContentCivil_Liability(
              t(`notifications.${action}_civil_liability_executive`)
            )}
            pathname={`${routes.PROGRAMMES}/civil-liability-executive/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      // TODO: add the civil liability
      case ref === 'claim_LIGHT_VEHICLE' && action === 'patch':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.patch_light_vehicule_claim')
            )}
            pathname={`${routes.CLAIMS}/light-vehicles/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_OTHER_VEHICLE' && action === 'patch':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.patch_other_vehicule_claim')
            )}
            pathname={`${routes.CLAIMS}/other-vehicles/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_HEAVY_VEHICLE' && action === 'patch':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.patch_heavy_vehicule_claim')
            )}
            pathname={`${routes.CLAIMS}/heavy-vehicles/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_AUTOMISSION' && action === 'patch':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.new_automission_claim')
            )}
            pathname={`${routes.CLAIMS}/automissions/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_LIGHT_VEHICLE' && action === 'create':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.new_light_vehicule_claim')
            )}
            pathname={`${routes.CLAIMS}/light-vehicles/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_OTHER_VEHICLE' && action === 'create':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.new_other_vehicule_claim')
            )}
            pathname={`${routes.CLAIMS}/other-vehicles/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_HEAVY_VEHICLE' && action === 'create':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.new_heavy_vehicule_claim')
            )}
            pathname={`${routes.CLAIMS}/heavy-vehicles/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_AUTOMISSION' && action === 'create':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.new_automission_claim')
            )}
            pathname={`${routes.CLAIMS}/automissions/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claimGolf_claim' && action === 'create':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(t('notifications.create_claim_golf'))}
            pathname={`${routes.CLAIMS}/golfs/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_Golf_claim' && action === 'patch':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(t('notifications.patch_claim_golf'))}
            pathname={`${routes.CLAIMS}/golfs/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_AD_VALOREM' && action === 'patch':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.patch_ad_valorem_claim')
            )}
            pathname={`${routes.CLAIMS}/ad-valorem/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_AD_VALOREM' && action === 'create':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.new_ad_valorem_claim')
            )}
            pathname={`${routes.CLAIMS}/ad-valorem/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_Property_claim' && action === 'create':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.new_property_claim')
            )}
            pathname={`${routes.CLAIMS}/properties/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_Property_claim' && action === 'patch':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.patch_property_claim')
            )}
            pathname={`${routes.CLAIMS}/properties/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_CIVIL_LIABILITY_PRO' && action === 'create':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.new_civil_liability_pro_claim')
            )}
            pathname={`${routes.CLAIMS}/civil-liability-pro/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_CIVIL_LIABILITY_PRO' && action === 'patch':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.patch_civil_liability_pro_claim')
            )}
            pathname={`${routes.CLAIMS}/civil-liability-pro/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_CIVIL_LIABILITY_EXECUTIVE' && action === 'create':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.new_civil_liability_executive_claim')
            )}
            pathname={`${routes.CLAIMS}/civil-liability-executive/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'claim_CIVIL_LIABILITY_EXECUTIVE' && action === 'patch':
        return (
          <NotificationsDetails
            content={GenerateContentClaim(
              t('notifications.patch_civil_liability_executive_claim')
            )}
            pathname={`${routes.CLAIMS}/civil-liability-executive/show/${item?.type}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );

      default:
        return t('notifications.form.unknown-type');
    }
  };

  return displayNotificationLink();
};
