import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useAuthContext } from '../../contexts/AuthContext';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/adminRoutes';

export const AdminNavMenu = ({ setCollapsed, width }) => {
  const { t } = useTranslation();
  const { company } = useAuthContext();
  const location = useLocation();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  return (
    <Menu
      style={{
        padding: '16px 0',
        background: 'var(--primaryColor)',
        maxHeight: 'calc(100% - 191px)',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
      selectedKeys={activeKeys}
      defaultOpenKeys={activeKeys}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      items={Object.entries(routes)
        .filter(([menuItem]) => {
          if (company === '64e480fb7b38bc884859ad2f') {
            // If it's this specific company, only allow 'HOME' and 'USERS'
            return menuItem.includes('HOME') || menuItem.includes('USERS');
          }
          if (company === '') {
            return null;
          }
          // For other companies, allow all menu items
          return true;
        })
        .map(([menuItem, p]) =>
          subRoutes[menuItem]
            ? {
                key: p,
                icon: navMenuLogos[menuItem],
                label: t(`basiclayout.menu.${menuItem.toLowerCase()}`),
                children: Object.entries(subRoutes[menuItem]).map(
                  ([subMenuItem, subPath]) => ({
                    key: subPath,
                    disabled: subPath === '',
                    label: (
                      <NavLink
                        to={`${p}${subPath}${
                          pathSearches[subMenuItem]
                            ? pathSearches[subMenuItem]
                            : ''
                        }`}
                      >
                        <span>
                          {t(
                            `basiclayout.submenu.${subMenuItem.toLowerCase()}`
                          )}
                        </span>
                      </NavLink>
                    )
                  })
                )
              }
            : {
                key: p,
                disabled: p === '',
                label: (
                  <NavLink
                    to={`${p}${
                      pathSearches[menuItem] ? pathSearches[menuItem] : ''
                    }`}
                  >
                    {navMenuLogos[menuItem]}
                    <span>
                      {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                    </span>
                  </NavLink>
                )
              }
        )}
    />
  );
};

AdminNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired
};
