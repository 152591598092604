// TODO delete comment after customer validation
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, InputNumber } from 'antd';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const { Option } = Select;

/**
 * Generates fields configuration for the claims page.
 *
 * @returns {Object} An object containing the fields configuration.
 */
export const useFieldsInvoicesPayment = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});

  const fieldsInvoicesPayment = [
    {
      label: ['amount'],
      name: ['amount'],
      rules: [{ required: true }],
      input: <InputNumber controls={false} />
    },
    // {
    //   label: ['incl_or_excl_tax'],
    //   name: ['incl_or_excl_tax'],
    //   rules: [{ required: true }],
    //   input: (
    //     <Select>
    //       {(enums?.inclOrExclTax || []).map((option) => (
    //         <Option key={option} value={option}>
    //           {t(`claims.form.${option}`)}
    //         </Option>
    //       ))}
    //     </Select>
    //   )
    // },
    {
      label: ['immediate_or_deferred_payment'],
      name: ['immediate_or_deferred_payment'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(enums?.immediateOrDeferredPayment || []).map((option) => (
            <Option key={option} value={option}>
              {t(`claims.form.${option}`)}
            </Option>
          ))}
        </Select>
      )
    }
  ];
  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/property-claim-accounts/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsInvoicesPayment,
    isFieldsLoading
  };
};
