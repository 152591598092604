import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { permissionVerification } from '../utils/permissionVerification';
import { useAuthContext } from '../contexts/AuthContext';
import { outOfNavRoutes } from '../utils/constants/adminRoutes';
import { OtherLayout } from '../components/layouts/OtherLayout';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { UserLayout } from '../components/layouts/UserLayout';

/**
 * `RequireAuth` is a higher-order component that wraps around components
 * which need authentication and permission verification before rendering.
 *
 * - If the user is not authenticated, they are redirected to the login page.
 * - If the user is authenticated but lacks the required permissions for the
 *   current route, permissions are verified and the app acts accordingly.
 *
 * @function
 * @returns {React.Element} Either a redirection to the login page if not
 * authenticated or the wrapped component if authenticated and has the required
 * permissions.
 *
 */

const RequireAuth = () => {
  const { isValid, token, setIsPermitted, permissions, user } =
    useAuthContext();
  const location = useLocation();
  const { '*': urlToken } = useParams();

  const isPostRoute = urlToken?.includes('post-pwd');

  useEffect(() => {
    if (token && permissions) {
      const permit = permissionVerification(permissions, location?.pathname);
      setIsPermitted(permit);
    }
  }, [location, token]);

  if (!isValid && !isPostRoute) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  const renderLayout = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
        return <OtherLayout />;
      case 'admins:SUPER-ADMIN':
        return <AdminLayout />;
      case 'users:USER':
        return <OtherLayout />;
      case 'users:SUPER-USER':
        return <OtherLayout />;
      default:
        return <UserLayout />;
    }
  };

  return renderLayout();
};

/**
 * PropTypes for RequireAuth.
 *
 * @property {Object} location - The location object containing details about the current route.
 * @property {string} location.pathname - The path of the current route.
 * @property {Object} location.state - An optional state object which might contain additional data.
 * @property {string} location.state.type - The type of the location state (if available).
 * @property {Object} location.state.model - An optional model object.
 * @property {string} location.state.model.name - The name of the model (if available).
 */

RequireAuth.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

/**
 * Default props for RequireAuth.
 */

RequireAuth.defaultProps = {
  location: { pathname: '/programmes' }
};

export default RequireAuth;
