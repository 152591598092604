import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  DownloadOutlined,
  MailOutlined
} from '@ant-design/icons';
import { useDownloadDocument } from '../../utils/downloadDoc';

const iconSize = 18;

/**
 * Generates an array of action columns for a table in a React component for managing documents.
 *
 * @param {boolean} edit - A boolean indicating whether the "Edit" action is enabled.
 * @param {boolean} show - A boolean indicating whether the "Show" action is enabled.
 * @returns {Object[]} An array of action column objects for rendering actions in a table.
 */
export const useActionColumn = (edit, show) => {
  const { downloadDocument } = useDownloadDocument();

  return [
    {
      key: 'action',
      align: 'right',
      render: (record) => {
        let content;
        switch (record.document_type) {
          case 'GUARANTEES':
            content = (
              <Flex gap="small" align="center" justify="end" wrap="nowrap">
                <Link to={`/documents/edit-guarantees/${record._id}`}>
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Link>
                {record.file && (
                  <DownloadOutlined
                    style={{ fontSize: iconSize }}
                    onClick={() => downloadDocument(record.file)}
                  />
                )}
                <MailOutlined style={{ fontSize: iconSize }} />
              </Flex>
            );
            break;

          case 'ATTESTATION':
            content = (
              <Flex gap="small" align="center" justify="end" wrap="nowrap">
                {record?.insurance_company === 'AXA' && (
                  <DownloadOutlined
                    style={{ fontSize: iconSize }}
                    onClick={() => downloadDocument(record.file)}
                  />
                )}
                <MailOutlined style={{ fontSize: iconSize }} />
              </Flex>
            );
            break;

          case 'CONTRACT':
            content = (
              <Flex gap="small" align="center" justify="end" wrap="nowrap">
                {show && (
                  <Link to={`/documents/show/${record._id}`}>
                    <EyeOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {edit && (
                  <Link to={`/documents/edit/${record._id}`}>
                    <EditOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {record.file && (
                  <DownloadOutlined
                    style={{ fontSize: iconSize }}
                    onClick={() => downloadDocument(record.file)}
                  />
                )}
                <MailOutlined style={{ fontSize: iconSize }} />
              </Flex>
            );
            break;

          case 'AMENDMENT':
            content = (
              <Flex gap="small" align="center" justify="end" wrap="nowrap">
                {show && (
                  <Link to={`/documents/show/${record._id}`}>
                    <EyeOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {edit && (
                  <Link to={`/documents/edit-amendments/${record._id}`}>
                    <EditOutlined style={{ fontSize: iconSize }} />
                  </Link>
                )}
                {record.file && (
                  <DownloadOutlined
                    style={{ fontSize: iconSize }}
                    onClick={() => downloadDocument(record.file)}
                  />
                )}
                <MailOutlined style={{ fontSize: iconSize }} />
              </Flex>
            );
            break;

          default:
            content = null;
            break;
        }

        return content;
      }
    }
  ];
};
