import { useTranslation } from 'react-i18next';
import { Input, DatePicker, TimePicker, InputNumber } from 'antd';
import { SelectManager } from '../../../../components/SelectManager';
import { SelectEntity } from '../../../../components/SelectEntity';
import { SelectSite } from '../../../../components/SelectSite';
import { SelectContract } from '../../../../components/SelectContract';

/**
 * The `useFields` hook generates an array of field configurations tailored for automission forms.
 *
 * @hook
 *
 * @returns {Object} An object containing arrays of field configurations:
 * - `fieldsAutomissions` : Array of main info field configurations.
 */

const useFields = () => {
  const { t } = useTranslation();
  const dateFormat = 'DD-MM-YYYY';
  const timeFormat = 'HH:mm';

  const fieldsAutomissions = [
    {
      noLabel: true,
      name: ['fleet_type'],
      input: <Input hidden />
    },
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: (
        <SelectContract
          dbKey="contract"
          sub_programme="AUTOMISSION"
          programme_types="FLEET"
        />
      ),
      startWithDivider: {
        title: t('vehicles.form.main_informations')
      }
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['desired_effective_date'],
      name: ['desired_effective_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: <SelectEntity dbKey="entity" />
    },
    {
      label: ['site'],
      name: ['site'],
      input: <SelectSite dbKey="site" />
    },
    {
      label: ['collaborator_last_name'],
      name: ['collaborator_last_name'],
      rules: [{ required: true }]
    },
    {
      label: ['collaborator_first_name'],
      name: ['collaborator_first_name'],
      rules: [{ required: true }]
    },
    {
      label: ['vehicle_registration_number'],
      name: ['vehicle_registration_number'],
      rules: [{ required: true }]
    },
    {
      label: ['departure_time'],
      name: ['departure_time'],
      rules: [{ required: true }],
      input: <TimePicker format={timeFormat} style={{ width: '100%' }} />
    },
    {
      label: ['start_of_the_mission'],
      name: ['start_of_the_mission'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['journey_lenght'],
      name: ['journey_lenght'],
      rules: [{ required: true }],
      input: <InputNumber style={{ width: '100%' }} addonAfter="km" />
    },
    {
      label: ['planned_end_of_the_mission'],
      name: ['planned_end_of_the_mission'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['departure_street_number'],
      name: ['departure_street_number'],
      rules: [{ required: true }],
      startWithDivider: {
        title: t('vehicles.form.departure_location')
      }
    },
    {
      label: ['departure_street_name'],
      name: ['departure_street_name'],
      rules: [{ required: true }]
    },
    {
      label: ['departure_additional_address'],
      name: ['departure_additional_address']
    },
    {
      label: ['departure_zip_code'],
      name: ['departure_zip_code'],
      rules: [{ required: true }]
    },
    {
      label: ['departure_city'],
      name: ['departure_city'],
      rules: [{ required: true }]
    },
    {
      label: ['departure_country'],
      name: ['departure_country'],
      rules: [{ required: true }]
    },
    {
      label: ['arrival_street_number'],
      name: ['arrival_street_number'],
      rules: [{ required: true }],
      startWithDivider: {
        title: t('vehicles.form.arrival_location')
      }
    },
    {
      label: ['arrival_street_name'],
      name: ['arrival_street_name'],
      rules: [{ required: true }]
    },
    {
      label: ['arrival_additional_address'],
      name: ['arrival_additional_address']
    },
    {
      label: ['arrival_zip_code'],
      name: ['arrival_zip_code'],
      rules: [{ required: true }]
    },
    {
      label: ['arrival_city'],
      name: ['arrival_city'],
      rules: [{ required: true }]
    },
    {
      label: ['arrival_country'],
      name: ['arrival_country'],
      rules: [{ required: true }],
      endWithDivider: {
        title: t('vehicles.form.documents')
      }
    }
  ];

  return {
    fieldsAutomissions
  };
};
export default useFields;
