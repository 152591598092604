import React from 'react';

export const AddIcon = () => (
  <span role="img" className="anticon anticon-file-text">
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 11.25V6H11.25V11.25H6V12.75H11.25V18H12.75V12.75H18V11.25H12.75Z"
        fill="currentColor"
      />
    </svg>
  </span>
);

export const CivilTile = () => (
  <span role="img" className="anticon anticon-file-text">
    <svg
      width="210"
      height="125"
      viewBox="0 0 210 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_948_494103)">
        <path
          d="M42.2695 39.6091V48.0429L40.2812 48.5367V39.4792C40.2812 39.4792 44.0758 33.3715 51.7559 36.3603C59.4231 39.3492 42.2695 39.6091 42.2695 39.6091Z"
          fill="currentColor"
        />
        <path
          d="M83.2819 45.4049L61.1772 43.3127L42.2693 48.0429L40.294 48.5367L36.2266 49.5634V104.416L40.7229 105.169"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M130.337 58.53V26.0682L112.963 24.9376"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M83.2822 53.8647V31.7341L93.4444 26.9908"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M141.63 65.3264V49.3035C141.63 49.3035 145.437 43.1958 153.105 46.1846C160.772 49.1735 143.618 49.4334 143.618 49.4334V66.7559"
          fill="currentColor"
        />
        <path
          d="M144.242 67.2237L146.139 66.496L174.105 68.8091V117.892L168.309 118.528"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M92.3396 24.3918C90.7802 24.4958 89.4287 23.2223 89.3247 21.5719C89.2208 19.9215 90.4033 18.492 91.9627 18.4011"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M113.184 24.2489C114.73 24.4828 116.185 23.3392 116.432 21.6888C116.679 20.0385 115.614 18.531 114.067 18.2971"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M90.1955 51.0318C42.8673 70.0047 37.5003 100.036 41.5288 122.531L167.425 124.831C171.688 101.934 166.009 71.3432 115.887 52.0454"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M93.6779 27.4457L91.9365 46.9773"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M113.054 46.3406L111.599 27.9135"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M113.573 20.8442C113.573 27.6016 108.739 33.0726 102.775 33.0726C96.8098 33.0726 91.9756 27.6016 91.9756 20.8442C91.9756 15.841 93.8729 9.70734 97.6674 7.82305C99.0059 7.1603 101.228 8.61576 102.775 8.61576C105.763 8.61576 109.636 7.19929 111.598 9.40846C113.56 11.6176 113.573 17.4654 113.573 20.8442Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M71.7422 79.2312V123.077"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M95.1464 6.05572C95.1464 6.05572 97.1607 -0.909659 104.555 0.103959C111.949 1.11758 113.197 5.80881 114.964 5.97775C116.731 6.14669 119.07 5.08109 119.33 5.73084C119.837 6.99137 119.083 8.62875 116.315 10.604C113.885 12.3324 114.73 20.2464 113.846 20.9351C113.535 21.182 112.69 20.7402 112.612 20.3503C112.248 18.557 112.209 10.1882 111.286 10.0192C110.364 9.85029 109.519 12.4493 105.75 12.3713C101.982 12.2934 98.954 8.34286 97.9404 8.68073C96.9268 9.01861 98.0184 13.1251 96.0951 14.1387C94.1718 15.1523 93.3271 14.4766 93.1582 15.6461C92.9893 16.8157 92.8593 20.0515 92.3395 20.3244C91.8197 20.5973 91.001 19.9865 91.001 19.4017C91.001 18.8169 91.4039 16.5688 90.897 15.893C90.3902 15.2173 87.7132 12.8782 89.1297 9.94126C90.5462 7.00436 95.1724 6.08171 95.1724 6.08171L95.1464 6.05572Z"
          fill="currentColor"
        />
        <path
          d="M83.1523 53.9947L84.2699 64.0659L91.0664 64.2089C91.0664 64.2089 85.7254 67.8735 85.8293 68.2114C85.9333 68.5492 99.2793 95.3971 99.2793 95.3971L103.711 103.246"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M120.383 53.8647L120.63 63.5071L114.73 64.9366L119.629 67.7565L108.661 92.8241L94.1328 123.493"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M115.939 52.1234L103.958 86.7814L90.1309 51.0968"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M101.281 51.5646L99.1494 57.1265L101.06 59.0368L98.0059 71.5641L103.958 86.7814L109.442 70.6674L105.751 59.1408L107.544 56.9056L104.244 51.4606L101.281 51.5646Z"
          fill="currentColor"
        />
        <path
          d="M91.9373 46.9773L101.216 51.5126L98.6817 58.452L90.1699 50.9538L91.9373 46.9773Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M115.964 52.0194L113.053 46.3406L104.217 51.4217L108.349 58.3351L115.964 52.0194Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M99.0545 19.6267C99.3683 19.6267 99.6227 19.1604 99.6227 18.5851C99.6227 18.0098 99.3683 17.5434 99.0545 17.5434C98.7407 17.5434 98.4863 18.0098 98.4863 18.5851C98.4863 19.1604 98.7407 19.6267 99.0545 19.6267Z"
          fill="currentColor"
        />
        <path
          d="M107.502 19.8996C107.816 19.8996 108.07 19.4333 108.07 18.858C108.07 18.2827 107.816 17.8163 107.502 17.8163C107.188 17.8163 106.934 18.2827 106.934 18.858C106.934 19.4333 107.188 19.8996 107.502 19.8996Z"
          fill="currentColor"
        />
        <path
          d="M132.897 79.2312V124.207"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_948_494103">
          <rect
            width="138.528"
            height="125"
            fill="white"
            transform="translate(35.9014)"
          />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export const ConstructionTile = () => (
  <span role="img" className="anticon anticon-file-text">
    <svg
      width="210"
      height="125"
      viewBox="0 0 210 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_948_494146)">
        <g clipPath="url(#clip1_948_494146)">
          <path
            d="M135.221 50.195V67.8793L129.508 68.6861V103.008L112.173 105.581L91.2178 101.416V80.1558L102.949 81.1371V50.1296L111.977 47.5565L135.221 50.195Z"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M85.6135 27.6263L85.2864 108.263L63.1538 111.948L37.5977 104.556V31.0061V12.7767L41.7189 11.0758L43.725 10.2254L62.8921 2.3318L68.4961 3.20402V25.0968L85.6135 27.6263Z"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M26.1934 107.871L63.6117 119.689H154.977"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M21.9849 105.407H40.4542C40.4542 105.407 49.8306 93.0865 44.8589 91.8436C39.8872 90.6007 35.4825 97.6003 35.4825 97.6003C35.4825 97.6003 43.0926 81.3769 36.3547 80.7664C29.6168 80.1558 27.6543 93.0865 27.6543 93.0865C27.6543 93.0865 28.7228 79.5453 21.9849 82.9251C15.2469 86.305 21.9849 105.407 21.9849 105.407Z"
            fill="currentColor"
          />
          <path
            d="M68.54 17.901L73.1846 19.9289L74.2095 22.7854L78.7014 22.1749L80.0098 23.4178L85.7228 27.539L68.54 25.075"
            fill="currentColor"
          />
          <path
            d="M99.591 59.3969L97.694 66.7018L95.3608 69.3185L94.4885 73.854L90.8906 77.3647V80.1558L102.949 81.1589V51.089L101.052 58.8082L99.591 59.3969Z"
            fill="currentColor"
          />
          <path
            d="M90.541 107.871L119.869 114.216H174.732"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M172.007 68.4244L159.948 66.3093C160.406 63.1257 161.518 61.0106 163.699 61.1414C167.166 61.3594 169.935 64.6739 172.007 68.4244Z"
            fill="currentColor"
          />
          <path
            d="M174.646 109.506V74.3774C175.692 77.2557 176.237 79.4581 176.237 79.4581C176.237 79.4581 179.443 60.2256 185.723 61.1414C192.003 62.0572 188.23 93.4354 188.23 93.4354C188.23 93.4354 192.591 85.5636 196.146 87.3299C199.7 89.1179 194.423 109.223 194.423 109.223"
            fill="currentColor"
          />
          <path
            d="M174.733 68.926V109.528L155.02 110.269L154.039 110.291L129.508 105.777V68.6861L135.221 67.8793L153.821 65.2626L160.014 66.3529L172.072 68.4681L174.733 68.926Z"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M140.868 114.216V85.2583C140.868 85.2583 144.728 79.0656 152.491 82.0965C160.253 85.1275 142.874 85.3892 142.874 85.3892V114.063"
            fill="currentColor"
          />
          <path
            d="M48.6094 115.067V72.9382C48.6094 72.9382 52.469 66.7454 60.2317 69.7764C67.9945 72.8074 50.6155 73.069 50.6155 73.069V115.59"
            fill="currentColor"
          />
          <path
            d="M68.5615 10.8578H128.243L133.891 16.7017H68.5615"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M101.292 10.8578L68.6709 5.45"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M122.051 16.7017V52.8335"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M116.425 48.0145V29.4797H117.82V48.2325"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M125.997 49.1047V30.57H127.393V49.3228"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M153.93 65.3063V46.7715H155.303V65.5243"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M173.337 68.6425V50.1078H174.732V68.8606"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M133.825 50.0206V31.3768H135.221V50.1296"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M102.949 50.0424V31.5077H104.345L104.432 49.6281"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M108.487 48.516V37.9403H109.883L110.014 48.1017"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M114.484 58.0886L115.858 63.1911H128.702L129.835 58.0232L114.484 58.0886Z"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M123.948 53.9238H120.371C120.328 53.9238 120.284 53.8801 120.284 53.8365V52.8989C120.284 52.8553 120.328 52.8117 120.371 52.8117H123.948C123.991 52.8117 124.035 52.8553 124.035 52.8989V53.8365C124.035 53.8801 123.991 53.9238 123.948 53.9238Z"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M120.284 53.902L114.745 58.0014"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M123.882 53.8801L129.987 58.0014"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M142.896 66.8981V51.547H144.292L144.401 66.5274"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M164.026 67.0507V57.4344H165.4V67.1815L164.026 67.0507Z"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_948_494146">
          <rect
            width="208.333"
            height="125"
            fill="white"
            transform="translate(0.833008)"
          />
        </clipPath>
        <clipPath id="clip1_948_494146">
          <rect
            width="218.056"
            height="218.056"
            fill="white"
            transform="translate(-4.0293 -45.1389)"
          />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export const FreightTile = () => (
  <span role="img" className="anticon anticon-file-text">
    <svg
      width="259"
      height="227"
      viewBox="0 0 259 227"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_948_494113)">
        <path
          d="M201.841 126.74C201.841 126.74 192.58 90.7764 199.287 88.1965C206.537 85.4103 211.619 104.63 211.619 104.63C211.619 104.63 206.434 62.1657 215.902 62.4753C225.37 62.7849 226.299 88.2739 226.299 88.2739C226.299 88.2739 227.821 78.0319 231.794 78.9606C235.767 79.8894 233.006 126.74 233.006 126.74H201.841Z"
          fill="currentColor"
        />
        <path
          d="M125.22 148.72C125.22 153.157 122.614 156.743 119.415 156.743L113.61 156.176C110.463 156.176 107.909 152.719 107.806 148.41L115.339 147.095C115.339 147.095 115.055 142.348 117.248 140.49L119.415 140.697C122.614 140.697 125.22 144.283 125.22 148.72Z"
          fill="currentColor"
        />
        <path
          d="M156.333 139.226C156.333 141.935 154.734 144.128 152.773 144.128L149.213 143.767C147.407 143.767 145.91 141.883 145.704 139.458L155.972 137.085C156.204 137.756 156.333 138.478 156.333 139.226Z"
          fill="currentColor"
        />
        <path
          d="M172.689 134.737C172.689 136.853 171.451 138.581 169.903 138.581L167.116 138.297C165.749 138.297 164.614 136.956 164.382 135.176L172.483 133.318C172.612 133.757 172.689 134.247 172.689 134.737Z"
          fill="currentColor"
        />
        <path
          d="M183.603 132.028C183.603 133.293 182.855 134.324 181.952 134.324L180.301 134.17C179.398 134.17 178.649 133.164 178.649 131.925L183.345 130.842C183.5 131.151 183.603 131.59 183.603 132.028Z"
          fill="currentColor"
        />
        <path
          d="M83.3485 146.114C82.6261 149.442 80.4074 151.842 77.776 151.842L71.9713 151.274C68.9786 151.274 66.502 148.152 66.1924 144.128L83.3485 146.114Z"
          fill="currentColor"
        />
        <path
          d="M185.228 130.351H219.669"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M42.4062 130.351H63.0451"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M62.9935 126.404H42.4062V85.2039L162.912 64.565L219.669 78.5994V126.404H185.305"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M70.0629 103.16L68.2828 114.253L62.9941 119.929V126.404H49.166V103.16H70.0629Z"
          fill="currentColor"
        />
        <path
          d="M165.44 103.16V106.333L154.295 103.16H165.44Z"
          fill="currentColor"
        />
        <path
          d="M197.663 103.16V126.404H185.305V112.034L176.146 109.403V103.16H197.663Z"
          fill="currentColor"
        />
        <path
          d="M185.305 112.034V130.351L183.344 130.816L178.649 131.899L172.483 133.318L164.382 135.176L155.998 137.111L145.73 139.484L140.519 140.671L137.062 139.742L137.087 126.404L137.165 100.399L140.519 99.1867L154.295 103.16L165.44 106.333L176.146 109.403L185.305 112.034Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M137.165 98.1032V100.399L137.088 126.404L137.062 139.742V141.342L125.246 144.154C125.246 144.154 123.518 139.355 118.977 139.768C118.255 139.82 117.687 140.103 117.223 140.49C115.03 142.348 115.314 147.095 115.314 147.095L107.781 148.411L105.871 148.746L83.3492 146.114L66.1932 144.128L62.9941 143.767V119.929L68.2829 114.253L70.063 103.16L71.7399 92.6855L105.562 86.984L137.165 98.1032Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M188.271 152.951H204.937C204.937 152.951 213.528 143.638 209.117 142.399C204.705 141.161 201.635 147.662 201.635 147.662C201.635 147.662 207.285 134.428 202.616 134.299C197.946 134.17 195.624 147.301 195.624 147.301C195.624 147.301 196.372 136.388 192.064 137.007C187.781 137.627 190.593 148.281 190.593 148.281C190.593 148.281 186.801 141.651 183.963 143.612C181.125 145.573 188.271 152.951 188.271 152.951Z"
          fill="currentColor"
        />
        <path
          d="M35.3633 146.759H47.6434C47.6434 146.759 56.8535 133.009 51.3068 132.286C45.7601 131.564 42.0967 140.929 42.0967 140.929C42.0967 140.929 50.3007 123.824 43.1287 123.67C35.9567 123.515 35.3633 146.759 35.3633 146.759Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_948_494113">
          <rect
            width="257.986"
            height="257.986"
            fill="white"
            transform="translate(0.277344 -30.9931)"
          />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export const GolfTile = () => (
  <span role="img" className="anticon anticon-file-text">
    <svg
      width="210"
      height="125"
      viewBox="0 0 210 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_948_494123)">
        <path
          d="M93.3613 121.591C93.3613 121.591 167.558 91.1738 209.161 89.0012"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M0.896484 87.3097C0.896484 87.3097 103.394 87.9642 133.142 106.95"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M0.896484 57.1315C0.896484 57.1315 45.1566 36.1282 209.158 42.8882"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M179.874 24.9032V26.3526V110.124"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M179.808 26.3527L169.91 38.0617H179.808V26.3527Z"
          fill="currentColor"
        />
        <path
          d="M179.874 110.363C182.653 110.363 184.906 109.599 184.906 108.657C184.906 107.714 182.653 106.95 179.874 106.95C177.094 106.95 174.841 107.714 174.841 108.657C174.841 109.599 177.094 110.363 179.874 110.363Z"
          fill="currentColor"
        />
        <path
          d="M31.6934 3.64581V32.1235"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M31.6934 3.64581L35.0584 7.62653H31.6934V3.64581Z"
          fill="currentColor"
        />
        <path
          d="M31.6695 32.2072C32.6152 32.2072 33.3819 31.9476 33.3819 31.6274C33.3819 31.3072 32.6152 31.0476 31.6695 31.0476C30.7237 31.0476 29.957 31.3072 29.957 31.6274C29.957 31.9476 30.7237 32.2072 31.6695 32.2072Z"
          fill="currentColor"
        />
        <path
          d="M35.991 88.6605L60.8705 90.4117C60.8705 90.4117 72.5855 73.3353 66.7669 71.2224C60.9482 69.1095 58.2735 78.8641 58.2735 78.8641C58.2735 78.8641 69.6478 46.4206 60.5209 46.9167C51.3939 47.4098 48.5398 67.7946 48.5398 67.7946C48.5398 67.7946 47.9451 51.9972 41.8485 53.8411C35.7519 55.6851 39.0483 77.5103 39.0483 77.5103C39.0483 77.5103 35.3813 65.0063 30.4443 66.5215C25.5072 68.0366 35.991 88.6605 35.991 88.6605Z"
          fill="currentColor"
        />
        <path
          d="M146.402 41.5493H139.465C139.465 41.5493 135.822 34.7684 137.093 33.5341C138.363 32.2998 141.253 36.4629 141.253 36.4629C141.253 36.4629 139.675 26.0448 142.618 26.3616C145.562 26.6784 145.508 35.2435 145.508 35.2435C145.508 35.2435 147.011 31.2808 148.658 32.0368C151.291 33.2442 146.402 41.5493 146.402 41.5493Z"
          fill="currentColor"
        />
        <path
          d="M125.413 41.7107L134.065 41.6061C134.065 41.6061 138.458 36.7169 136.16 36.1102C133.862 35.5036 132.314 38.8925 132.314 38.8925C132.314 38.8925 135.156 31.9861 132.738 31.9532C130.32 31.9203 129.194 38.7401 129.194 38.7401C129.194 38.7401 129.508 33.0739 127.284 33.4205C125.061 33.7642 126.591 39.2811 126.591 39.2811C126.591 39.2811 124.577 35.8711 123.127 36.9052C121.678 37.9422 125.413 41.7137 125.413 41.7137V41.7107Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_948_494123">
          <rect
            width="208.333"
            height="118.083"
            fill="white"
            transform="translate(0.833496 3.64581)"
          />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export const RealestateTile = () => (
  <span role="img" className="anticon anticon-file-text">
    <svg
      width="212"
      height="125"
      viewBox="0 0 212 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_948_494083)">
        <path
          d="M180.397 54.7726L197.807 56.2043V102.74L208.129 101.107L211.314 38.3529L199.145 36.1237L193.866 47.2783L191.562 5.22532L184.105 1.40083L180.397 54.7726Z"
          fill="currentColor"
          stroke="currentColor"
        />
        <mask
          id="mask0_948_494083"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="212"
          height="125"
        >
          <path d="M211.314 0H0.5V124.999H211.314V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_948_494083)">
          <path
            d="M28.8575 67.8361V96.4887H0.661914C0.661914 96.4887 -0.293905 78.3333 4.00271 78.1754C8.30671 78.0101 8.14881 90.2742 8.14881 90.2742C8.14881 90.2742 9.26288 67.0231 12.9272 67.0171C15.7008 67.0101 15.6434 75.4373 15.4493 79.547C15.7152 76.745 16.4769 72.2548 18.6611 72.219C21.3773 72.1617 21.4276 81.2714 21.3843 83.9658C21.4919 80.668 22.2252 67.4398 27.1041 67.0245C27.4419 66.9943 27.7817 67.0534 28.0895 67.1959C28.3972 67.3384 28.6621 67.5589 28.8575 67.8361Z"
            fill="currentColor"
            stroke="currentColor"
          />
          <path
            d="M36.2649 20.4334V30.7646L33.8384 31.3753V20.2755C33.8384 20.2755 38.4944 12.7892 47.8933 16.4534C57.2922 20.1176 36.2649 20.4334 36.2649 20.4334Z"
            fill="currentColor"
            stroke="currentColor"
          />
          <path
            d="M59.4379 24.9737L36.265 30.7644L33.8364 31.3752L28.8569 32.6174V99.8357L59.761 105.246L86.5194 102.544V27.5239L59.4379 24.9737Z"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M144.182 3.83715V57.39L132.958 59.7177V106.834L117.725 107.825L86.519 102.408V10.7843L117.108 0.367081L144.182 3.83715Z"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M27.1035 103.337L99.6798 116.846L110.423 115.123L165.38 123.565"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M76.8452 103.047V52.1102C76.8452 52.1102 81.5043 44.624 90.9021 48.2874C100.3 51.9509 79.2731 52.2687 79.2731 52.2687V102.866"
            fill="currentColor"
          />
          <path
            d="M76.8452 103.047V52.1102C76.8452 52.1102 81.5043 44.624 90.9021 48.2874C100.3 51.9509 79.2731 52.2687 79.2731 52.2687V102.866"
            stroke="currentColor"
          />
          <path
            d="M158.004 54.2609V32.3014C158.004 32.3014 162.664 24.8151 172.063 28.4768C181.461 32.1386 160.434 32.4585 160.434 32.4585V54.2588"
            fill="currentColor"
          />
          <path
            d="M158.004 54.2609V32.3014C158.004 32.3014 162.664 24.8151 172.063 28.4768C181.461 32.1386 160.434 32.4585 160.434 32.4585V54.2588"
            stroke="currentColor"
          />
          <path
            d="M197.807 56.2043V116.345L163.856 120.024L132.959 115.534V59.7177L144.183 57.39L163.54 53.3738L197.807 56.2043Z"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
          <path
            d="M195.638 124.999H171.106C171.106 124.999 166.327 108.275 171.744 107.479C177.16 106.683 177.103 116.56 177.103 116.56C177.103 116.56 174.93 97.1265 181.46 96.5685C187.991 96.0105 189.425 121.815 189.425 121.815C189.425 121.815 191.018 108.436 196.913 108.754C202.807 109.072 195.638 124.999 195.638 124.999"
            fill="currentColor"
          />
          <path
            d="M195.638 124.999H171.106C171.106 124.999 166.327 108.275 171.744 107.479C177.16 106.683 177.103 116.56 177.103 116.56C177.103 116.56 174.93 97.1265 181.46 96.5685C187.991 96.0105 189.425 121.815 189.425 121.815C189.425 121.815 191.018 108.436 196.913 108.754C202.807 109.072 195.638 124.999 195.638 124.999"
            stroke="currentColor"
          />
          <path
            d="M0.570312 103.526L83.3349 120.027"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_948_494083">
          <rect
            width="210.998"
            height="124.999"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export const VehicleTile = () => (
  <span role="img" className="anticon anticon-file-text">
    <svg
      width="269"
      height="245"
      viewBox="0 0 269 245"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_948_494093)">
        <path
          d="M65.2749 70.076V65.0431L135.414 50.3192"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M118.12 60.3582L135.414 62.9015V50.3459L160.311 55.8607V64.8557L164.086 65.6589"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M173.429 116.791V123.966L118.12 131.917L54.2183 112.749V71.95L118.12 60.3583"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M48.65 141.929H79.0616C79.0616 141.929 91.8581 120.138 84.6032 118.076C77.3483 116.015 74.9122 128.088 74.9122 128.088C74.9122 128.088 85.9685 87.6377 74.9122 89.0298C63.8558 90.4219 62.1157 115.453 62.1157 115.453C62.1157 115.453 60.0543 96.2847 52.7727 99.0421C45.5178 101.8 51.3806 128.088 51.3806 128.088C51.3806 128.088 45.839 113.177 39.9762 115.453C34.1402 117.728 48.65 141.929 48.65 141.929Z"
          fill="currentColor"
        />
        <path
          d="M125.776 140.028V97.9445C125.776 97.9445 130.327 90.6093 139.537 94.1966C148.746 97.7839 128.159 98.1051 128.159 98.1051V139.868"
          fill="currentColor"
        />
        <path
          d="M178.596 130.15V95.8564C178.596 95.8564 183.147 88.5211 192.356 92.1084C201.565 95.6957 180.978 96.017 180.978 96.017V129.989"
          fill="currentColor"
        />
        <path
          d="M101.978 151.915L89.6899 154.136"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M181.487 137.378L171.287 139.252"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M144.302 144.178L131.987 146.426"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M159.294 125.974L138.279 128.918L138.226 112.856C138.868 112.829 139.859 112.776 140.555 112.829C144.169 112.99 146.15 115.453 147.06 117.139C147.595 118.103 148.345 118.745 149.255 118.879C151.825 119.281 156.805 120.218 158.063 121.503C159.107 122.6 159.241 124.501 159.294 125.974Z"
          fill="currentColor"
        />
        <path
          d="M150.62 162.489C150.62 162.489 131.024 166.237 126.125 164.952C126.071 164.925 104.842 156.894 101.951 155.823C101.763 155.743 101.629 155.555 101.656 155.368C101.683 154.833 101.763 153.307 101.951 151.915C102.085 150.79 102.299 149.746 102.62 149.371C103.53 148.193 106.02 147.765 107.385 147.203C108.617 146.694 110.785 145.008 112.07 144.66C115.068 143.856 116.701 144.285 121.949 144.553C124.331 144.579 125.964 144.66 126.981 144.713C129.043 144.82 130.703 145.516 131.961 146.426C133.487 147.524 134.504 148.97 135.147 150.121C135.816 151.326 136.78 152.129 137.877 152.316C141.09 152.825 147.354 154.003 148.96 155.636C150.326 157.001 150.54 160.615 150.62 162.489Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M187.725 153.44C187.725 153.44 170.243 156.787 165.88 155.636C165.826 155.609 146.872 148.461 144.329 147.471C144.142 147.417 144.062 147.23 144.062 147.042C144.088 146.587 144.169 145.329 144.302 144.124C144.436 143.053 144.624 142.036 144.918 141.688C145.748 140.644 147.943 140.242 149.175 139.761C150.272 139.305 152.2 137.806 153.351 137.512C156.028 136.789 157.474 137.191 162.159 137.405C164.273 137.431 165.746 137.485 166.656 137.539C168.61 137.646 170.11 138.315 171.287 139.198C172.546 140.162 173.402 141.394 173.938 142.357C174.527 143.455 175.383 144.151 176.374 144.312C179.238 144.767 184.833 145.811 186.252 147.256C187.457 148.541 187.671 151.781 187.725 153.44Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M218.779 145.034C218.779 145.034 203.787 147.899 200.039 146.935C199.986 146.908 183.736 140.778 181.541 139.948C181.38 139.894 181.3 139.761 181.3 139.6C181.327 139.252 181.38 138.342 181.487 137.405C181.594 136.387 181.782 135.343 182.049 134.995C182.745 134.085 184.646 133.764 185.717 133.336C186.654 132.961 188.314 131.676 189.278 131.408C191.553 130.792 192.811 131.114 196.827 131.328C198.647 131.354 199.906 131.408 200.682 131.435C204.162 131.595 206.036 133.978 206.946 135.584C207.455 136.521 208.205 137.137 209.034 137.271C211.497 137.672 216.289 138.556 217.494 139.814C218.538 140.831 218.725 143.589 218.779 145.034Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M138.225 128.945L138.172 102.174H163.069V125.465"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M227.212 62.8211V116.604L197.817 121.289L173.429 116.791V67.6131L164.086 65.6589V63.1959L197.817 54.4151L227.212 62.8211Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M225.926 123.136H214.522C214.522 123.136 208.525 111.972 210.613 109.965C212.702 107.93 217.467 114.783 217.467 114.783C217.467 114.783 214.87 97.65 219.715 98.1854C224.561 98.6941 224.481 112.802 224.481 112.802C224.481 112.802 226.944 106.297 229.647 107.528C233.958 109.483 225.926 123.136 225.926 123.136Z"
          fill="currentColor"
        />
        <path
          d="M161.917 89.003L158.223 91.6266C157.446 89.0566 156.054 86.9417 154.287 85.737C153.404 85.1213 152.44 84.7197 151.396 84.5859C150.031 84.3985 148.692 84.6394 147.407 85.2819C145.399 86.2724 143.579 88.1731 142.24 90.6628L138.6 86.674C138.948 86.0047 139.349 85.3622 139.751 84.7465C143.017 79.7403 146.952 77.679 151.664 78.3482C155.894 78.9372 159.293 82.2568 161.248 87.0755C161.489 87.6913 161.73 88.3338 161.917 89.003Z"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M202.636 159.865H212.166C212.166 159.865 215.111 157.295 213.585 155.903C212.621 155.02 210.881 156.358 210.881 156.358C210.881 156.358 212.434 151.459 210.105 151.593C207.776 151.727 205.34 157.162 205.34 157.162C205.34 157.162 204.59 152.985 202.448 154.351C200.334 155.689 202.636 159.865 202.636 159.865Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_948_494093">
          <rect
            width="267.708"
            height="267.708"
            fill="white"
            transform="translate(0.971191 -23.3542)"
          />
        </clipPath>
      </defs>
    </svg>
  </span>
);
