import { Link } from 'react-router-dom';
import { Button, Flex } from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  DownloadOutlined,
  FormOutlined,
  MailOutlined
} from '@ant-design/icons';
import { TrafficIncident } from '@carbon/icons-react';

const iconSize = 18;

/**
 * Generates an array of action columns for a table in a React component.
 *
 * @param {boolean} edit - A boolean indicating whether the "Edit" action is enabled.
 * @param {function} GenerateGreenCard - A function to generate a Green Card for a record.
 * @param {function} t - A function to translate a string.
 * @param {function} message - A function to display a message.
 * @param {function} dispatchAPI - A function to dispatch an API call.
 * @returns {Object[]} An array of action column objects for rendering actions in a table.
 */

export const useActionColumn = (
  edit,
  GenerateGreenCard,
  t,
  message,
  dispatchAPI
) => [
  {
    key: 'action',
    align: 'right',
    render: (record) => (
      <Flex gap="small" align="center" justify="end" wrap="nowrap">
        <Link to={`/programmes/heavy-vehicles/show/${record?._id}`}>
          <EyeOutlined style={{ fontSize: iconSize }} />
        </Link>
        {edit && (
          <Link to={`/programmes/heavy-vehicles/edit/${record?._id}`}>
            <EditOutlined style={{ fontSize: iconSize }} />
          </Link>
        )}
        {['MMA', 'Groupama'].includes(record?.contract?.insurance_company) && (
          <Button
            type="link"
            onClick={() =>
              GenerateGreenCard(record._id, dispatchAPI, t, message)
            }
          >
            <DownloadOutlined style={{ fontSize: iconSize }} />
          </Button>
        )}
        <MailOutlined style={{ fontSize: iconSize }} />
        <Link to={`/claims/heavy-vehicles/create/${record?._id}`}>
          <TrafficIncident size={20} />
        </Link>
        <FormOutlined style={{ fontSize: iconSize }} />
      </Flex>
    )
  }
];
