import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { SetNodes } from '../Organization/SetNodes';
import { useCompanyContext } from '../../CompanyContext';

export const useOrganizationData = () => {
  const [dataTree, setDataTree] = useState([]);
  const { setNodeParent } = SetNodes();
  const { message } = useErrorMessage();
  const { dispatchAPI, company } = useAuthContext();
  const { forceRefresh } = useCompanyContext();

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contracts/companies/${company}?deleted=false`
      });
      const nodesTree = setNodeParent(data);
      setDataTree(nodesTree);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [company, forceRefresh]);

  return { dataTree };
};
