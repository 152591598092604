import { Route, Routes } from 'react-router-dom';
import { CreateUpdateCompany } from './CreateUpdateCompany';
import { ShowCompany } from './ShowCompany/ShowCompany';
import { Exception } from '../../components';

export const CompanyRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateCompany purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateCompany purpose="edit" />} />
    <Route index element={<ShowCompany />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
