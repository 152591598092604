import { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { FileExclamationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { claimStatus } from '../../../../utils/constants/tagColors';

/**
 * Generates custom table columns configuration for a claims list.
 *
 * @returns {Array} An array of column configuration objects.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/transport_claims/enums'
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return [
    {
      key: 'documents',
      dataIndex: 'missing_documents',
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : null
    },
    {
      title: t('claims.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      sorter: true
    },
    {
      title: t('claims.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      sorter: true
    },
    {
      title: t('claims.form.sinister_date'),
      key: 'sinister_date',
      dataIndex: 'sinister_date',
      render: (sinister_date) =>
        sinister_date ? moment(sinister_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.shipper_name'),
      key: 'shipper_name',
      dataIndex: ['entry_id', 'shipper_name'],
      sorter: true
    },
    {
      title: t('claims.form.departure_location'),
      key: 'departure_location',
      dataIndex: '',
      render: (record) => {
        const { departure_city, departure_country } = record.entry_id;
        return `${departure_city || null}, ${departure_country || null}`;
      },
      sorter: true
    },
    {
      title: t('claims.form.arrival_location'),
      key: 'arrival_location',
      dataIndex: '',
      render: (record) => {
        const { arrival_city, arrival_country } = record.entry_id;
        return `${arrival_city || null}, ${arrival_country || null}`;
      },
      sorter: true
    },
    {
      title: t('claims.form.opening_date'),
      key: 'opening_date',
      dataIndex: ['opening_date'],
      render: (opening_date) =>
        opening_date ? moment(opening_date).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('claims.form.claim_status'),
      key: 'claim_status',
      dataIndex: 'claim_status',
      render: (status) =>
        status ? (
          <Tag color={claimStatus[status]}>
            {t(`claims.form.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          <Tag color="orange">{t('claims.form.reception_pending')}</Tag>
        ),
      sorter: true,
      filters: enums?.claimStatus?.map((status) => ({
        text: t(`claims.form.${status}`),
        value: status
      }))
    }
  ];
};
