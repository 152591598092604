import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { constructionStatus } from '../../../../utils/constants/tagColors';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});

  const renderAddress = (text, record) => {
    const { number, street, additional } = record || {};
    return number || street || additional
      ? `${number || ''} ${street || ''} ${additional || ''}`
      : '-';
  };

  const allColumns = [
    {
      title: t('constructions.form.company_name'),
      key: 'company_name',
      dataIndex: ['company_Id', 'name'],
      sorter: true
    },
    {
      title: t('constructions.form.contract'),
      key: 'contract',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('constructions.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? moment(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('constructions.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? moment(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('constructions.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date
          ? moment(validity_end_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('constructions.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      sorter: true
    },
    {
      title: t('constructions.form.status'),
      key: 'status',
      dataIndex: ['status'],
      sorter: true,
      render: (status) =>
        status ? (
          <Tag color={constructionStatus[status]}>
            {t(`constructions.form.${status}`)}
          </Tag>
        ) : (
          '-'
        )
    },
    {
      title: t('constructions.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      sorter: true
    },
    {
      title: t('constructions.form.address'),
      key: 'address',
      dataIndex: ['company_Id'],
      sorter: true,
      render: (company_Id) =>
        company_Id
          ? `${company_Id.number || ''} ${company_Id.street || ''} ${
              company_Id.additional || ''
            }`
          : '-'
    },
    {
      title: t('constructions.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['company', 'zip_code'],
      sorter: true
    },
    {
      title: t('constructions.form.city'),
      key: 'city',
      dataIndex: ['company', 'city'],
      sorter: true
    },
    {
      title: t('constructions.form.desired_guarantees'),
      key: 'desired_guarantees',
      dataIndex: 'desired_guarantees',
      sorter: true,
      render: (desired_guarantees) =>
        desired_guarantees ? t(`constructions.form.${desired_guarantees}`) : '-'
    },
    {
      title: t('constructions.form.subscriber'),
      key: 'subscriber',
      dataIndex: 'subscriber',
      sorter: true
    },
    {
      title: t(
        'constructions.form.contracting_owner_if_different_from_the_subscriber'
      ),
      key: 'contracting_owner_if_different_from_the_subscriber',
      dataIndex: 'contracting_owner_if_different_from_the_subscriber',
      sorter: true
    },
    {
      title: t('constructions.form.construction_site_name'),
      key: 'construction_site_name',
      dataIndex: 'construction_site_name',
      sorter: true
    },
    {
      title: t('constructions.form.construction_permit_number'),
      key: 'construction_permit_number',
      dataIndex: 'construction_permit_number',
      sorter: true
    },
    {
      title: t('constructions.form.construction_site_address'),
      key: 'construction_site_address',
      render: renderAddress
    },

    {
      title: t('constructions.form.expected_construction_cost'),
      key: 'expected_construction_cost',
      dataIndex: 'expected_construction_cost',
      sorter: true
    },
    {
      title: t('constructions.form.expected_construction_starting_date'),
      key: 'expected_construction_starting_date',
      dataIndex: 'expected_construction_starting_date',
      sorter: true,
      render: (expected_construction_starting_date) =>
        expected_construction_starting_date &&
        moment(expected_construction_starting_date).format('DD/MM/YYYY')
    },
    {
      title: t('constructions.form.construction_starting_date'),
      key: 'construction_starting_date',
      dataIndex: 'construction_starting_date',
      sorter: true,
      render: (construction_starting_date) =>
        construction_starting_date &&
        moment(construction_starting_date).format('DD/MM/YYYY')
    },
    {
      title: t('constructions.form.expected_construction_completion_date'),
      key: 'expected_construction_completion_date',
      dataIndex: 'expected_construction_completion_date',
      sorter: true,
      render: (expected_construction_completion_date) =>
        expected_construction_completion_date &&
        moment(expected_construction_completion_date).format('DD/MM/YYYY')
    },
    {
      title: t('constructions.form.construction_completion_date'),
      key: 'construction_completion_date',
      dataIndex: 'construction_completion_date',
      sorter: true,
      render: (construction_completion_date) =>
        construction_completion_date &&
        moment(construction_completion_date).format('DD/MM/YYYY')
    },
    {
      title: t('constructions.form.amendment_new_construction_cost'),
      key: 'amendment_new_construction_cost',
      dataIndex: ['current_amendment', 'expected_construction_cost'],
      sorter: true
    },
    {
      title: t('constructions.form.reason_of_amendment_demand'),
      key: 'reason_of_amendment_demand',
      dataIndex: ['current_amendment', 'reason_of_amendment_demand'],
      sorter: true
    },
    {
      title: t('constructions.form.rental_loss_guarantee'),
      key: 'rental_loss_guarantee',
      dataIndex: 'rental_loss_guarantee',
      sorter: true,
      render: (rental_loss_guarantee) =>
        rental_loss_guarantee
          ? t(`constructions.form.${rental_loss_guarantee}`)
          : '-'
    }
  ];

  const getConfig = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/constructions-configs/${companyId}`
      });
      setConfig(data[0]);
    } catch (e) {
      message(e);
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return filteredColumns || [];
};
