import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * `useListContent` is a custom React hook to generate a list content based on a given data.
 *
 * @hook
 * @param {object} data - The input data containing details about the document. Default is an empty object.
 * @returns {Array<object>} A list of objects containing label-content pairs for display, each object has
 * a `label` to indicate the type of data, a `span` to denote the span or size, and a `content` to denote the actual value.
 *
 * */

export const useListContent = (data = {}) => {
  const { t } = useTranslation();

  const { company_Id, contract } = data;

  const subscriberAdressParts = [
    contract?.number,
    contract?.street,
    contract?.additional_address,
    contract?.postal_code,
    contract?.city
  ];
  const subscriberAddress = subscriberAdressParts
    .filter((part) => part)
    .join(' ');

  const subProgrammesParts = [
    ...(contract?.fleet_sub_programmes || []),
    ...(contract?.civil_liability_sub_programmes || []),
    ...(contract?.transport_sub_programmes || []),
    ...(contract?.construction_sub_programmes || [])
  ];
  const subProgrammes = subProgrammesParts
    .filter((part) => typeof part === 'string')
    .map((part) => t(`subscriptions.form.${part}`))
    .join(', ');
  return [
    {
      label: 'subscriptions.form.company',
      span: 1,
      content: company_Id ? company_Id.name : '-'
    },
    {
      label: 'subscriptions.form.entity',
      span: 1,
      content: contract?.entity ? contract?.entity?.name : '-'
    },
    {
      label: 'subscriptions.form.contract_number',
      span: 1,
      content: contract?.contract_number || '-'
    },
    {
      label: 'subscriptions.form.validity_start_date',
      span: 1,
      content:
        (contract?.validity_start_date &&
          moment(contract?.validity_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'subscriptions.form.validity_end_date',
      span: 2,
      content:
        (contract?.validity_end_date &&
          moment(contract?.validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'subscriptions.form.program',
      span: 1,
      content:
        (contract?.programme_types &&
          t(`subscriptions.form.${contract?.programme_types}`)) ||
        '-'
    },
    {
      label: 'subscriptions.form.sub_programme',
      span: 1,
      content: (subProgrammes && t(`${subProgrammes}`)) || '-'
    },
    {
      label: 'subscriptions.form.subscriber_last_name_first_name',
      span: 1,
      content: contract?.subscriber_last_name_first_name || '-'
    },
    {
      label: 'subscriptions.form.subscriber_address',
      span: 1,
      content: subscriberAddress || '-'
    }
  ];
};
