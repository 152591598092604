import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * Provides an array of content used to populate property details.
 * This hook maps the constructions of the passed data into a structured format suitable for presentation.
 *
 * @hook
 * @param {Object} data - The data object containing property details.
 * @returns {Array} An array of objects containing label, span, and content information for each property detail.
 *
 */

export const useListContent = (data = {}) => {
  const { t } = useTranslation();
  const {
    contract,
    company_Id,
    created_at,
    entity,
    site,
    number,
    street,
    additional,
    postal_code,
    city,
    country,
    start_date,
    subscriber,
    construction_site_name,
    construction_permit_number,
    construction_starting_date,
    expected_construction_starting_date,
    expected_construction_cost,
    expected_construction_completion_date,
    desired_guarantees,
    rental_loss_guarantee,
    comments
  } = data;

  const constructionAdress = `${number || ''} ${street || ''} ${
    additional || ''
  } ${postal_code || ''} ${city || ''} ${country || ''}`;

  return [
    {
      label: 'constructions.form.contract_number',
      span: 1,
      content: contract && contract.contract_number
    },
    {
      label: 'constructions.form.contract',
      span: 1,
      content: (company_Id?.name && company_Id.name) || '-'
    },
    {
      label: 'constructions.form.date_added',
      span: 1,
      content: (created_at && moment(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'constructions.form.entity',
      span: 1,
      content: (entity && entity.name) || '-'
    },
    {
      label: 'constructions.form.site',
      span: 1,
      content: (site && site.name) || '-'
    },
    {
      label: 'constructions.form.start_date',
      span: 1,
      content: (start_date && moment(start_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'constructions.form.subscriber',
      span: 1,
      content: (subscriber && subscriber) || '-'
    },
    {
      label: 'constructions.form.construction_site_name',
      span: 1,
      content: (construction_site_name && construction_site_name) || '-'
    },
    {
      label: 'constructions.form.construction_permit_number',
      span: 1,
      content: (construction_permit_number && construction_permit_number) || '-'
    },
    {
      label: 'constructions.form.construction_site_address',
      span: 3,
      content: constructionAdress
    },
    {
      label: 'constructions.form.construction_starting_date',
      span: 1,
      content:
        (construction_starting_date &&
          moment(construction_starting_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'constructions.form.expected_construction_starting_date',
      span: 1,
      content:
        (expected_construction_starting_date &&
          moment(expected_construction_starting_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'constructions.form.expected_construction_cost',
      span: 1,
      content: (expected_construction_cost && expected_construction_cost) || '-'
    },
    {
      label: 'constructions.form.expected_construction_completion_date',
      span: 1,
      content:
        (expected_construction_completion_date &&
          moment(expected_construction_completion_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'constructions.form.desired_guarantees',
      span: 1,
      content:
        (desired_guarantees && t(`constructions.form.${desired_guarantees}`)) ||
        '-'
    },
    {
      label: 'constructions.form.rental_loss_guarantee',
      span: 1,
      content:
        (rental_loss_guarantee &&
          t(`constructions.form.${rental_loss_guarantee}`)) ||
        '-'
    },
    {
      label: 'constructions.form.comments',
      span: 1,
      content: (comments && comments) || '-'
    }
  ];
};
