import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumns } from './columns';
import { useActionColumn } from '../actionColumn';
import { ListResource } from '../../../components/ListResource/ListResource';
import { SelectByEntity } from '../../components/SelectByEntity';
import { SelectBySite } from '../../components/SelectBySite';
import { useAuthContext } from '../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../components/FilterByMissingDocuments';
import { StatusFilter } from '../../components/StatusFilter';
import { claimStatus } from '../../../utils/constants/tagColors';

/**
 * A component that lists 'Golf' filtered by selected entity and site.
 *
 * `ListGolf` is responsible for fetching and listing 'Golf' for a
 * specific entity and site. It provides filter dropdowns (`SelectByEntity` and `SelectBySite`)
 * to allow users to select specific entity or site to filter the results.
 *
 * Once a user chooses an entity or a site, the component updates the browser URL with the
 * filtering parameters.
 *
 * @Component
 *
 * @returns {JSX.Element} The rendered `ListGolf` component with filtering capabilities.
 */

export const ListProperty = () => {
  const [entity, setEntity] = useState('');
  const [site, setSite] = useState('');
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [statusTag, setStatusTag] = useState('');
  const actionColumnUrl = 'properties';
  const url = 'property_claims';
  const enums = 'claimStatus';

  const columns = useColumns();
  const actionColumn = useActionColumn(actionColumnUrl, url);
  const navigate = useNavigate();
  const { company } = useAuthContext();
  const { t } = useTranslation();

  let filterParameter = `entity=${entity}__claim_status=${statusTag}__missing_documents=${isMissingDocuments}`;
  const onFilterEntity = (entityId) => {
    setEntity(entityId || '');
  };

  const onFilterSite = (siteId) => {
    setSite(siteId || '');
  };

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  const onFilterStatus = (status) => {
    setStatusTag(status || '');
  };

  useEffect(() => {
    if (site !== '') {
      filterParameter += `__site=${site}__`;
    } else {
      filterParameter += '__';
    }
    navigate({
      pathname: '/claims/properties',
      search: `?f=${filterParameter}`
    });
  }, [entity, site, isMissingDocuments, statusTag]);
  return (
    <ListResource
      extraQuery={`company=${company}`}
      resourceName="property_claims"
      exportUrl={`exports/company/${company}/programme-type/property_claim`}
      tradKey="claims"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <>
          <SelectByEntity onChange={onFilterEntity} />
          <SelectBySite onChange={onFilterSite} />
          <StatusFilter
            onChange={onFilterStatus}
            url={url}
            enums={enums}
            tagMapping={claimStatus}
          />
          <FilterByMissingDocuments onChange={onFilterMissingDocuments} />
        </>
      }
      populate="entity,site,entry_id,company"
      editAction={false}
      showAction={false}
      deleteAction={false}
      sheetName={t('sheet.property_claim')}
      headers={[]}
      onlyExport
      noCreateButton
      noArchiveFilter
    />
  );
};
