import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Row, Card, Divider } from 'antd';
import { LinksGeneration } from './LinksGeneration';

export const TileMenu = ({ image, altImage, links, target }) => {
  const [menu, setMenu] = useState(true);
  const { t } = useTranslation();
  const displayMenu = () => {
    setMenu(!menu);
  };
  return (
    <Card
      onClick={displayMenu}
      className={target === 'claims' ? 'widget claims' : 'widget program'}
    >
      <Row className="tile-icon-wrapper" justify="center">
        {image}
      </Row>
      <Divider />
      {menu ? (
        <Row justify="center" className="tile-content title">
          <h3>{t(`tiles.${altImage}`)}</h3>
        </Row>
      ) : (
        <Row
          justify="space-between"
          className="tile-content menu"
          gutter={[4, 2]}
        >
          {links.map((link) => (
            <LinksGeneration target={target} link={link} key={link} />
          ))}
        </Row>
      )}
    </Card>
  );
};

TileMenu.propTypes = {
  image: PropTypes.element.isRequired,
  altImage: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.string),
  target: PropTypes.string.isRequired
};

TileMenu.defaultProps = {
  links: []
};
