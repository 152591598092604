import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useListContentClaim = (data = {}) => {
  const { t } = useTranslation();
  const {
    sinister_date,
    opening_date,
    customer_manager,
    unit_manager,
    goods_type,
    insured_status,
    goods_to_be_appraised,
    insurance_company_reference
  } = data;

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'claims.form.sinister_date',
      span: 1,
      content:
        (sinister_date && moment(sinister_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.opening_date',
      span: 2,
      content:
        (opening_date && moment(opening_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'claims.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'claims.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'claims.form.insurance_company_reference',
      span: 1,
      content: insurance_company_reference
    },
    {
      label: 'claims.form.insured_status',
      span: 2,
      content: (insured_status && t(`claims.form.${insured_status}`)) || '-'
    },
    {
      label: 'claims.form.goods_to_be_appraised',
      span: 1,
      content:
        (goods_to_be_appraised && t(`claims.form.${goods_to_be_appraised}`)) ||
        '-'
    },
    {
      label: 'claims.form.goods_type',
      span: 1,
      content: goods_type && goods_type
    } || '-'
  ];
  return labels;
};
