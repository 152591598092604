import { useState } from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Handles status-related functionality for a vehicle claim.
 *
 * @function
 * @param {string} targetUrl - The target URL for making API requests.
 * @returns {Object} An object containing functions and state variables for status handling.
 */

export const handleStatusUtils = (targetUrl) => {
  const { dispatchAPI } = useAuthContext();
  const [current, setCurrent] = useState(0);
  const [toEnclosed, setToEnclose] = useState(false);
  const { message } = useErrorMessage();

  const claimStep = {
    0: 'RECEIVED_DECLARATION',
    1: 'INFORMATIONS_WAITING',
    2: 'EXPERTISE_IN_PROGRESS',
    3: 'UNDERSTUDY_COMPENSATION',
    4: 'APPEAL',
    5: 'AWAITING_CLOSING',
    6: 'CLOSED'
  };
  const onChange = async (value, id, edit) => {
    if (edit === false) {
      return;
    }
    setCurrent(value);
    setToEnclose(value === 5);
    try {
      await dispatchAPI('PATCH', {
        url: `${targetUrl}/${id}`,
        body: JSON.stringify({
          values: JSON.stringify({
            step: value,
            claim_status: claimStep[value],
            to_enclose: value === 5,
            is_closed: value >= 6
          })
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (e) {
      message(e);
    }
  };
  return {
    current,
    setCurrent,
    onChange,
    toEnclosed,
    setToEnclose
  };
};
