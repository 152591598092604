import { Route, Routes } from 'react-router-dom';
import { ListOwnAccounts } from './ListOwnAccounts';
import { ShowOwnAccount } from './ShowOwnAccount';
import { Exception } from '../../../../components';

export const OwnAccountsRouter = () => (
  <Routes>
    <Route path="/show/:id" element={<ShowOwnAccount />} />
    <Route index element={<ListOwnAccounts />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
