/**
 * Provides an array of content used to populate property details.
 * This hook maps the amendments of the passed data into a structured format suitable for presentation.
 *
 * @hook
 * @param {Object} data - The data object containing property details.
 * @returns {Array} An array of objects containing label, span, and content information for each property detail.
 *
 */

export const useListContent = (data = {}) => {
  const {
    amendment_number,
    reason_of_amendment_demand,
    amendment_new_construction_cost
  } = data;

  return [
    {
      label: 'amendments.form.amendment_number',
      span: 1,
      content: (amendment_number && amendment_number) || '-'
    },
    {
      label: 'amendments.form.amendment_new_construction_cost',
      span: 1,
      content:
        (amendment_new_construction_cost && amendment_new_construction_cost) ||
        '-'
    },
    {
      label: 'amendments.form.reason_of_amendment_demand',
      span: 1,
      content: (reason_of_amendment_demand && reason_of_amendment_demand) || '-'
    }
  ];
};
