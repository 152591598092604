import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider, Modal } from 'antd';
import useFields from './fieldsEntity';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

/**
 * Component for creating or updating an entity.
 *
 * @component
 * @param {string} purpose - Purpose of the modal ('create' or 'edit').
 * @param {object} entityData - Data of the entity being edited.
 * @param {string} entityId - ID of the entity being edited.
 * @param {boolean} forceRefresh - Indicates if a refresh is needed.
 * @param {function} setForceRefresh - Function to set the forceRefresh value.
 * @param {boolean} isModalOpen - Indicates if the modal is open.
 * @param {function} setIsModalOpen - Function to set the modal's open state.
 * @returns {JSX.Element} CreateUpdateEntityModal component.
 */
const CreateUpdateEntityModal = ({
  purpose,
  entityId,
  forceRefresh,
  setForceRefresh,
  isModalOpen,
  setIsModalOpen
}) => {
  const { t } = useTranslation();
  const { fieldsEntity } = useFields();
  const { company } = useAuthContext();

  const onCancel = () => {
    setIsModalOpen(false);
    setForceRefresh(!forceRefresh);
  };

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        company,
        ...data
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };

  return (
    <Modal
      title={
        purpose === 'create'
          ? t(`companies.form.create_entity`)
          : t(`companies.form.modify_entity`)
      }
      open={isModalOpen}
      onCancel={onCancel}
      style={{ marginTop: 200 }}
      footer={[]}
    >
      <Divider />
      <CreateUpdateContainer
        purpose={purpose}
        fields={fieldsEntity}
        baseUrl="entities"
        idWithoutParams={entityId}
        resource="entities"
        config={config}
        withFilesManager={false}
        populate="?populate=documents.file"
        withPageHeaderCustom={false}
        isModal
        customModal={onCancel}
      />
    </Modal>
  );
};

CreateUpdateEntityModal.propTypes = {
  purpose: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired
};

export default CreateUpdateEntityModal;
