// TODO: remove comments when tests ok
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Col, Dropdown, Input, Switch, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportButton } from '../ExportButton/ExportButton';
import { ImportButton } from '../importButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { AddIcon } from '../../utils/constants/customIcons';
import { InclTaxButton } from '../../routes/claims/ClaimAccountingOverview/utils/buttons/InclTaxButton';
import { ExclTaxButton } from '../../routes/claims/ClaimAccountingOverview/utils/buttons/ExclTaxButton';

const { Search } = Input;

/**
 * Component for displaying a list of resources with optional modal functionality.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.resourceName - The name of the resource being displayed.
 * @param {string} [props.tradKey] - The translation key for the resource title.
 * @param {string} [props.dataToFetch] - The name of the data to fetch from the server.
 * @param {Array} props.columns - The columns configuration for the data table.
 * @param {boolean} [props.customActionColumn] - Indicates if a custom action column is used.
 * @param {Array} [props.headers] - The headers for export and import functionality.
 * @param {string} [props.extraQuery] - Additional query parameters for data fetching.
 * @param {JSX.Element} [props.extraHeader] - Additional header content.
 * @param {JSX.Element} [props.extraButtons] - Additional buttons to display.
 * @param {string} [props.exportUrl] - The export URL for CSV export.
 * @param {string} [props.populate] - The population field for Mongoose populate.
 * @param {boolean|Object} [props.withCreateButton] - Indicates if a create button is shown. Can also customize the button.
 * @param {boolean} [props.withUploadButton] - Indicates if an upload button is shown.
 * @param {boolean} [props.withPageHeader] - Indicates if a page header is displayed.
 * @param {boolean} [props.withSearchBar] - Indicates if a search bar is displayed.
 * @param {boolean} [props.forceRefresh] - Forces a data refresh on component mount.
 * @param {string} [props.resourceModelName] - The name of the resource model.
 * @param {boolean|Object} [props.editAction] - Indicates if an edit action is shown. Can also customize the action.
 * @param {boolean|Object} [props.showAction] - Indicates if a show action is shown. Can also customize the action.
 * @param {boolean|Object} [props.duplicateAction] - Indicates if a duplicate action is shown. Can also customize the action.
 * @param {boolean|Object} [props.printAction] - Indicates if a print action is shown. Can also customize the action.
 * @param {boolean} [props.deleteAction] - Indicates if a delete action is shown.
 * @param {boolean|Object} [props.onDoubleClickAction] - Indicates if an action on double click is enabled. Can also customize the action.
 * @param {Object} [props.scroll] - The scroll configuration for the data table.
 * @param {Object} [props.expandable] - The expandable configuration for the data table.
 * @param {string} [props.path] - The path for navigation links.
 * @param {string} [props.rowKey] - The key to identify rows in the data table.
 * @param {Function} [props.formatter] - A function for formatting data before display.
 * @param {boolean} [props.openModal] - Indicates if a modal should be opened on create.
 * @param {Function} [props.setIsModalOpen] - Callback to set the modal open state.
 * @param {Function} [props.setPurpose] - Callback to set the purpose (create/edit) of the modal.
 * @param {Function} [props.setRecord] - Callback to set the record for modal editing.
 * @return {JSX.Element} Rendered component.
 */
export const ListResourceWithModal = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  withCreateButton,
  withUploadButton,
  withPageHeader,
  withSearchBar,
  forceRefresh,
  resourceModelName,
  editAction,
  showAction,
  duplicateAction,
  printAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  formatter,
  openModal,
  setIsModalOpen,
  setPurpose,
  setRecord,
  canAdd,
  withSwitch,
  noMenu,
  buttonBelowTable,
  addButtonText,
  noUpperButton,
  amountType,
  noPagination
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);
  const [isArchived, setIsArchived] = useState(false);
  const [exportDatas, setExportDatas] = useState([]);
  const [changeDone, setChangeDone] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    setPurpose('create');
  };

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);
  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  useEffect(() => {
    setChangeDone(false);
  }, [changeDone]);

  const menu = {
    items: [
      ...(headers
        ? [
            {
              key: 'export',
              label: (
                <ExportButton
                  dataName={resourceName}
                  headers={headers}
                  url={`/${exportUrl || resourceName}`}
                  fileName={`${resourceName}.csv`}
                  populate={populate}
                  extraQuery={extraQuery}
                  formatter={formatter}
                  exportDatas={exportDatas}
                />
              )
            }
          ]
        : []),
      {
        key: 'import',
        label: <ImportButton resourceName={resourceModelName} />
      }
    ]
  };
  return (
    <>
      {withPageHeader && (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          extra={extraHeader}
        />
      )}
      <ContentCustom>
        <Row justify={withSearchBar ? 'space-between' : 'end'} gutter={[8, 16]}>
          {withSearchBar && (
            <Col>
              <Search
                allowClear
                placeholder={t('placeholder.search')}
                defaultValue={searchValue}
                onSearch={(value) => searchResource(value)}
              />
            </Col>
          )}
          {!withCreateButton && headers && (
            <Col>
              <Dropdown menu={menu}>
                <Button type="link">
                  <MenuOutlined
                    style={{ fontSize: 16, color: 'var(--textColor)' }}
                  />
                </Button>
              </Dropdown>
            </Col>
          )}
          {withCreateButton && (
            <Col>
              <Row align="middle">
                {extraButtons}
                {withSwitch && (
                  <Switch
                    checkedChildren={t('buttons.archived')}
                    unCheckedChildren={t('buttons.archived')}
                    onChange={() => setIsArchived(!isArchived)}
                  />
                )}
                {openModal && !noUpperButton
                  ? canAdd && (
                      <Button type="add" onClick={showModal}>
                        {withCreateButton?.buttonText ||
                          `${t('buttons.create')}`}
                        &nbsp;
                        {withCreateButton?.buttonIcon || <AddIcon />}
                      </Button>
                    )
                  : null}
                {withUploadButton && !noMenu && (
                  <Dropdown menu={menu}>
                    <Button type="link">
                      <MenuOutlined
                        style={{ fontSize: 16, color: 'var(--textColor)' }}
                      />
                    </Button>
                  </Dropdown>
                )}
              </Row>
            </Col>
          )}
        </Row>
        <Row gutter={[8, 16]}>{children}</Row>
        <Datatable
          style={{ marginTop: 24 }}
          resourceName={dataToFetch || resourceName}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          showAction={showAction}
          isArchived={isArchived}
          duplicateAction={duplicateAction}
          printAction={printAction}
          deleteAction={deleteAction}
          openModal={openModal}
          setPurpose={setPurpose}
          setIsModalOpen={setIsModalOpen}
          setRecord={setRecord}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1200 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
          setExportDatas={setExportDatas}
          changeDone={changeDone}
          setChangeDone={setChangeDone}
          noPagination={noPagination}
        />
        <Row>
          {openModal && buttonBelowTable
            ? canAdd && (
                <>
                  <Button type="add" onClick={showModal}>
                    {withCreateButton?.buttonText || `${t(addButtonText)}`}
                    &nbsp;
                    {withCreateButton?.buttonIcon || <AddIcon />}
                  </Button>
                  {amountType !== 'EXCESS' && (
                    <>
                      <InclTaxButton
                        amountType={amountType}
                        setChangeDone={setChangeDone}
                        changeDone={changeDone}
                      />
                      <ExclTaxButton
                        amountType={amountType}
                        setChangeDone={setChangeDone}
                        changeDone={changeDone}
                      />
                    </>
                  )}
                </>
              )
            : null}
        </Row>
      </ContentCustom>
    </>
  );
};

ListResourceWithModal.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ]),
  withUploadButton: PropTypes.bool,
  withPageHeader: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  resourceModelName: PropTypes.string,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  formatter: PropTypes.func,
  openModal: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setPurpose: PropTypes.func,
  setRecord: PropTypes.func,
  canAdd: PropTypes.bool.isRequired,
  withSwitch: PropTypes.bool,
  noMenu: PropTypes.bool,
  buttonBelowTable: PropTypes.bool,
  addButtonText: PropTypes.string,
  noUpperButton: PropTypes.bool,
  amountType: PropTypes.string,
  noPagination: PropTypes.bool
};

ListResourceWithModal.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  withSearchBar: true,
  withPageHeader: true,
  dataToFetch: null,
  forceRefresh: null,
  resourceModelName: null,
  editAction: true,
  showAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: false,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  formatter: undefined,
  openModal: false,
  withSwitch: false,
  setIsModalOpen: () => {},
  setPurpose: () => {},
  setRecord: () => {},
  noMenu: false,
  buttonBelowTable: false,
  addButtonText: 'buttons.create',
  noUpperButton: false,
  amountType: null,
  noPagination: false
};
