import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Select, Form } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

/**
 * `SelectContract` is a component that renders a dropdown with contract numbers.
 * It fetches contracts based on the company from the AuthContext and filters by the provided `sub_programme` and `programme_types`.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {string} props.dbKey - The database key for the item.
 * @param {string} [props.sub_programme] - The sub-programme used for filtering contracts.
 * @param {string} [props.programme_types] - The type of programme used for filtering contracts.
 * @returns {JSX.Element} The rendered `Select` component containing contract numbers.
 */

export const SelectContract = ({
  dbKey,
  sub_programme,
  programme_types,
  isDisabled
}) => {
  const [contracts, setContracts] = useState([]);
  const { company, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getcontracts = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/contracts/number/companies/${company}`,
        body: {
          programme_types,
          sub_programme
        }
      });
      setContracts(data);
    } catch (e) {
      message(e);
    }
  }, [company, programme_types, sub_programme]);

  useEffect(() => {
    (async () => {
      await getcontracts();
    })();
  }, [sub_programme]);

  return (
    <Item name={[`${dbKey}`]}>
      <Select
        disabled={isDisabled}
        showSearch
        filterOption={(input, option) =>
          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
        }
      >
        {contracts &&
          contracts.map((c) => (
            <Option key={c._id} value={c._id}>
              {c.contract_number}
            </Option>
          ))}
      </Select>
    </Item>
  );
};

SelectContract.propTypes = {
  dbKey: PropTypes.string.isRequired,
  sub_programme: PropTypes.string,
  programme_types: PropTypes.string,
  isDisabled: PropTypes.bool
};

SelectContract.defaultProps = {
  sub_programme: undefined,
  programme_types: undefined,
  isDisabled: false
};
