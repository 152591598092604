import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Input, InputNumber, Radio, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { SelectContract } from '../../../components/SelectContract';
import { SelectEntity } from '../../../components/SelectEntity';
import { SelectSite } from '../../../components/SelectSite';
import { SelectManager } from '../../../components/SelectManager';

const { TextArea } = Input;
const { Group } = Radio;
const { Option } = Select;

/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @returns
 * @property {Array} fields - An array of field configurations for the form.
 * @property {boolean} isFieldsLoading - A flag to determine whether the form fields are being loaded.
 * @hook
 */

export const useFields = (purpose, permit) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const [permitsNumber, setPermitsNumber] = useState(null);
  const [isDisabled, setIsDisabled] = useState(purpose === 'create');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const dateFormat = 'DD-MM-YYYY';

  const handleEntitySelect = (entityId) => {
    setSelectedEntity(entityId);
  };

  const handleConstructionPermitNumber = (e) => {
    const inputValue = e.target.value;
    if (permitsNumber.includes(inputValue) && inputValue !== permit) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const fields = [
    {
      label: ['contract_number'],
      name: ['contract'],
      rules: [{ required: true }],
      input: <SelectContract dbKey="contract" programme_types="CONSTRUCTION" />,
      startWithDivider: {
        title: t('constructions.form.main_informations')
      }
    },
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['start_date'],
      name: ['start_date'],
      rules: [{ required: false }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['entity'],
      name: ['entity'],
      rules: [{ required: true }],
      input: <SelectEntity dbKey="entity" onSelect={handleEntitySelect} />
    },
    {
      label: ['site'],
      name: ['site'],
      rules: [{ required: true }],
      input: <SelectSite dbKey="site" entityId={selectedEntity} />
    },
    {
      label: ['subscriber'],
      name: ['subscriber'],
      rules: [{ required: true }]
    },
    {
      label: ['contracting_owner_if_different_from_the_subscriber'],
      name: ['contracting_owner_if_different_from_the_subscriber']
    },
    {
      label: ['construction_site_name'],
      name: ['construction_site_name'],
      rules: [{ required: true }]
    },
    {
      label: ['construction_permit_number'],
      name: ['construction_permit_number'],
      rules: [{ required: true }],
      input: (
        <Input
          onChange={handleConstructionPermitNumber}
          status={isDisabled ? 'error' : null}
        />
      )
    },
    {
      label: ['construction_permit_number_expiry_date'],
      name: ['construction_permit_number_expiry_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          disabled={isDisabled}
        />
      )
    },
    {
      label: ['number'],
      name: ['number'],
      rules: [{ required: true }],
      input: <Input disabled={isDisabled} />
    },
    {
      label: ['street'],
      name: ['street'],
      rules: [{ required: true }],
      input: <Input disabled={isDisabled} />
    },
    {
      label: ['additional'],
      name: ['additional'],
      rules: [{ required: false }],
      input: <Input disabled={isDisabled} />
    },
    {
      label: ['postal_code'],
      name: ['postal_code'],
      rules: [{ required: true }],
      input: <Input disabled={isDisabled} />
    },
    {
      label: ['city'],
      name: ['city'],
      rules: [{ required: true }],
      input: <Input disabled={isDisabled} />
    },
    {
      label: ['country'],
      name: ['country'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('constructions.select_country')}
          style={{ width: '100%' }}
          showSearch
          filterOption={(input, option) =>
            (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
          }
          disabled={isDisabled}
        >
          {(enums?.countries || []).map((country) => (
            <Option key={country} value={country}>
              {country}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['construction_starting_date'],
      name: ['construction_starting_date'],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          disabled={isDisabled}
        />
      )
    },
    {
      label: ['expected_construction_starting_date'],
      name: ['expected_construction_starting_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          disabled={isDisabled}
        />
      )
    },
    {
      label: ['expected_construction_cost'],
      name: ['expected_construction_cost'],
      rules: [{ required: true }],
      input: <InputNumber type="Number" disabled={isDisabled} />
    },
    {
      label: ['expected_construction_completion_date'],
      name: ['expected_construction_completion_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          disabled={isDisabled}
        />
      )
    },
    {
      label: ['construction_completion_date'],
      name: ['construction_completion_date'],
      input: (
        <DatePicker
          format={dateFormat}
          style={{ width: '100%' }}
          disabled={isDisabled}
        />
      )
    },
    {
      label: ['desired_guarantees'],
      name: ['desired_guarantees'],
      rules: [{ required: true }],
      input: (
        <Group disabled={isDisabled}>
          {(enums?.desired_guarantees || []).map((option) => (
            <Radio key={option} value={option}>
              <Tooltip
                key={option}
                title={t(`constructions.tooltip.${option}`)}
              >
                {t(`constructions.form.${option}`)}
              </Tooltip>
            </Radio>
          ))}
        </Group>
      )
    },
    {
      label: ['rental_loss_guarantee'],
      name: ['rental_loss_guarantee'],
      input: (
        <Group disabled={isDisabled}>
          {(enums?.rental_loss_guarantee || []).map((option) => (
            <Radio key={option} value={option}>
              {t(`constructions.form.${option}`)}
            </Radio>
          ))}
        </Group>
      )
    },
    {
      label: ['comments'],
      name: ['comments'],
      input: <TextArea style={{ resize: 'none' }} disabled={isDisabled} />,
      endWithDivider: {
        title: t('constructions.form.documents')
      }
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/constructions/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getConstructionPermitNumber = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/constructions/permit`
      });
      setPermitsNumber(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return null;
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();
    await getConstructionPermitNumber();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
