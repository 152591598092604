import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Select, Checkbox, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { SelectProgrammes } from './SelectProgrammes';
import { SelectSubProgrammes } from './SelectSubProgrammes';

const { Option } = Select;

/**
 * `useFields` is a custom hook that provides fields for forms and manages their associated states.
 *
 * @returns
 * @property {Array} fields - An array of field configurations for the form.
 * @property {boolean} isFieldsLoading - A flag to determine whether the form fields are being loaded.
 * @hook
 * @example
 * const { fields, isFieldsLoading } = useFields();
 */

export const useFields = (
  setSelectedType,
  selectedType,
  selectedProgramme,
  setSelectedProgramme,
  isMandatory,
  setIsMandatory,
  setContractId,
  setContractNumber,
  isFieldsLoading,
  setIsFieldsLoading,
  isDisabled,
  containsContractDocuments
) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, company } = useAuthContext();
  const [contracts, setContracts] = useState([]);
  const [enums, setEnums] = useState({});
  const [entities, setEntities] = useState([]);
  const [checkBoxState, setCheckBoxState] = useState();
  const handleProgrammeSelect = (programmesArray) => {
    setSelectedProgramme(programmesArray);
  };

  const mirrorCheckBox = (e) => {
    setCheckBoxState(e.target.checked);
  };
  useEffect(() => {
    setIsMandatory(!isMandatory);
  }, [checkBoxState]);

  const isFleetHidden = !selectedProgramme?.includes('FLEET');
  const isCivilLiabilityHidden =
    !selectedProgramme?.includes('CIVIL_LIABILITY');
  const isTransportHidden = !selectedProgramme?.includes('TRANSPORT');
  const isConstructionHidden = !selectedProgramme?.includes('CONSTRUCTION');

  const hidden = selectedType !== 'CONTRACT';

  useEffect(() => {
    setSelectedType(selectedType);
  }, [isFieldsLoading]);

  const fields = [
    {
      name: ['document_type'],
      rules: [{ required: true }],
      input: (
        <Select
          placeholder={t('documents.filter_by_type')}
          style={{ width: '100%' }}
          onChange={(value) => setSelectedType(value)}
          disabled={isDisabled}
        >
          {(enums?.type || [])
            .filter((type) => type !== 'AMENDMENT')
            .map((type) => (
              <Option key={type} value={type}>
                {t(`documents.status.${type}`)}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['insurance_company'],
      rules: [{ required: !hidden }],
      hidden,
      input: (
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
          }
          placeholder={t('documents.form.select_insurance_company')}
          style={{ width: '100%' }}
        >
          {(enums?.insurance_companies || []).map((i) => (
            <Option key={i} value={i}>
              {i}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['is_pending'],
      name: ['is_pending'],
      hidden,
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: (
        <Checkbox
          onChange={mirrorCheckBox}
          disabled={containsContractDocuments}
        />
      )
    },
    {
      label: ['contract_number'],
      name: ['contract_number'],
      rules: [{ required: true }],
      initialValue: null,
      input:
        selectedType === 'CONTRACT' ? (
          <Input />
        ) : (
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value) => {
              const parsedValue = JSON.parse(value);
              setContractId(parsedValue.id);
              setContractNumber(parsedValue.contractNumber);
            }}
          >
            {contracts &&
              contracts.map((c) => (
                <Option
                  key={c._id}
                  value={JSON.stringify({
                    id: c._id,
                    contractNumber: c.contract_number
                  })}
                >
                  {c.contract_number}
                </Option>
              ))}
          </Select>
        )
    },
    {
      name: ['entity'],
      rules: [{ required: !hidden }],
      hidden,
      input: (
        <Select style={{ width: '100%' }} onChange={() => {}}>
          {(entities || []).map((comp) => (
            <Option key={comp._id} value={comp._id}>
              {comp.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['validity_start_date'],
      rules: [{ required: !hidden }],
      hidden,
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['validity_end_date'],
      rules: [{ required: !hidden }],
      hidden,
      input: <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
    },
    {
      name: ['subscriber_last_name_first_name'],
      rules: [{ required: !hidden }],
      hidden
    },
    {
      label: 'number',
      name: ['number'],
      hidden,
      rules: [{ required: !hidden }]
    },
    {
      label: 'street',
      name: ['street'],
      hidden,
      rules: [{ required: !hidden }]
    },
    {
      label: 'additional_address',
      name: ['additional_address'],
      hidden,
      rules: [{ required: !hidden }]
    },
    {
      label: 'postal_code',
      name: ['postal_code'],
      hidden,
      rules: [{ required: !hidden }]
    },
    {
      label: 'city',
      name: ['city'],
      hidden,
      rules: [{ required: !hidden }]
    },
    {
      label: 'programmes_types',
      name: ['programme_types'],
      rules: [{ required: !hidden }],
      hidden,
      input: (
        <SelectProgrammes
          dbKey="programme_types"
          onSelect={handleProgrammeSelect}
        />
      )
    },
    {
      label: 'fleet_sub_programmes',
      name: ['fleet_sub_programmes'],
      rules: [{ required: !isFleetHidden }],
      hidden: isFleetHidden,
      input: <SelectSubProgrammes dbKey="fleet_sub_programmes" />
    },
    {
      label: 'civil_liability_sub_programmes',
      name: ['civil_liability_sub_programmes'],
      rules: [{ required: !isCivilLiabilityHidden }],
      hidden: isCivilLiabilityHidden,
      input: <SelectSubProgrammes dbKey="civil_liability_sub_programmes" />
    },
    {
      label: 'transport_sub_programmes',
      name: ['transport_sub_programmes'],
      rules: [{ required: !isTransportHidden }],
      hidden: isTransportHidden,
      input: <SelectSubProgrammes dbKey="transport_sub_programmes" />
    },
    {
      label: 'construction_sub_programmes',
      name: ['construction_sub_programmes'],
      rules: [{ required: !isConstructionHidden }],
      hidden: isConstructionHidden,
      input: <SelectSubProgrammes dbKey="construction_sub_programmes" />
    },
    {
      name: [selectedType?.toLowerCase()],
      rules: [
        { required: containsContractDocuments === true ? false : !isMandatory }
      ],
      hidden: isMandatory,
      input: 'File',
      maxFilesCount: 1,
      multipleFiles: false
    }
  ];

  const getcontracts = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/contracts/number/companies/${company}`
      });
      setContracts(data);
    } catch (e) {
      message(e);
    }
  };
  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contracts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEntities = async () => {
    try {
      const filters = company ? `?company=${company}` : '';
      const { data } = await dispatchAPI('GET', {
        url: `/entities${filters}`
      });
      setEntities(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getEntities();
    await getcontracts();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
