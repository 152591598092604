import { Link } from 'react-router-dom';
import { Flex, Tooltip } from 'antd';
import {
  CheckOutlined,
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { handleStatusUtils } from './handleStatusUtils';
import { useAuthContext } from '../../../contexts/AuthContext';

const iconSize = 18;

/**
 * Generates the action column configuration for a table.
 *
 * @returns {Array} An array containing the action column configuration.
 * @param {boolean} edit - Whether to show the edit icon or not.
 * @param {boolean} accept - Whether to show the accept icon or not.
 */
export const useActionColumn = (edit, accept) => {
  const { onChange } = handleStatusUtils();
  const { user } = useAuthContext();
  const { t } = useTranslation();

  const getLinkPath = (record) => {
    let linkPath;
    if (record?.programme_types === 'CIVIL_LIABILITY') {
      if (record?.programme_types === 'CIVIL_LIABILITY') {
        linkPath = `/subscriptions/civil-liability/edit/${record?._id}`;
      } else if (record?.programme_types === 'TRANSPORT') {
        linkPath = `/subscriptions/transport/edit/${record?._id}`;
      } else {
        linkPath = `/subscriptions/construction/edit/${record?._id}`;
      }
    } else if (record?.programme_types === 'TRANSPORT') {
      linkPath = `/subscriptions/transport/edit/${record?._id}`;
    } else {
      linkPath = `/subscriptions/construction/edit/${record?._id}`;
    }
    return linkPath;
  };

  return [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          <Link to={`/subscriptions/show/${record?._id}`}>
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {user.role === 'admins:SUPER-ADMIN' ||
          user.role === 'admins:ADMIN' ? (
            <>
              {edit && (
                <Link to={getLinkPath(record)}>
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Link>
              )}
              {record.status !== 'accepted' && record.status !== 'rejected'
                ? accept && (
                    <>
                      <Tooltip
                        title={t('subscriptions.form.accept_request')}
                        placement="topLeft"
                      >
                        <CheckOutlined
                          style={{ fontSize: iconSize }}
                          onClick={() => {
                            onChange(4, record._id);
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title={t('subscriptions.form.reject_request')}
                        placement="topLeft"
                      >
                        <CloseCircleOutlined
                          style={{ fontSize: iconSize, color: 'red' }}
                          onClick={() => {
                            onChange(3, record._id);
                          }}
                        />
                      </Tooltip>
                    </>
                  )
                : null}
            </>
          ) : null}
        </Flex>
      )
    }
  ];
};
