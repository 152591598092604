import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { Alerts } from './AlertCard';

export const NotificationDashboard = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user, company } = useAuthContext();
  const { message } = useErrorMessage();
  const [notifUser, setNotifUser] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vehicule, setVehicule] = useState([]);
  const [golves, setGolfs] = useState([]);
  const [property, setProperty] = useState([]);
  const [transportData, setTransportData] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const getNotifications = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `notifications/${user._id}/${company}`
      });
      setLoading(false);
      setNotifUser(data);
    } catch (e) {
      message(e);
    }
  };

  const getVehicule = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `vehicle?company=${company}&populate=entity,entity.sites`
      });
      setLoading(false);
      setVehicule(data);
    } catch (e) {
      message(e);
    }
  };

  const getProperty = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `property?company=${company}`
      });
      setLoading(false);
      setProperty(data);
    } catch (e) {
      message(e);
    }
  };

  const getGolfs = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `golfs?company=${company}&populate=entity,entity.sites`
      });
      setLoading(false);
      setGolfs(data);
    } catch (e) {
      message(e);
    }
  };

  const getCompany = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${company}`
      });
      setCompanyData(data);
    } catch (e) {
      message(e);
    }
    setLoading(false);
  };
  const getTransports = async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/transports?company=${company}`
      });
      setTransportData(data);
    } catch (e) {
      message(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getCompany();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getNotifications();
      await getVehicule();
      await getGolfs();
      await getTransports();
      await getProperty();
    })();
  }, [forceRefresh]);

  return (
    <>
      <PageHeaderCustom title={`${t('home.title')} ${companyData.name}`} />
      <ContentCustom>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12}>
            <Alerts
              notifications={notifUser}
              t={t}
              dispatchAPI={dispatchAPI}
              user={user}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              message={message}
              loading={loading}
              vehicule={vehicule}
              golves={golves}
              transportData={transportData}
              property={property}
              notifclass="alert"
            />
          </Col>
          <Col xs={24} md={12}>
            <Alerts
              notifications={notifUser}
              t={t}
              dispatchAPI={dispatchAPI}
              user={user}
              forceRefresh={forceRefresh}
              setForceRefresh={setForceRefresh}
              message={message}
              loading={loading}
              vehicule={vehicule}
              golves={golves}
              transportData={transportData}
              property={property}
              notifclass="gestionnaire"
            />
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
