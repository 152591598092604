import { useTranslation } from 'react-i18next';
import { useState, useEffect, useCallback } from 'react';
import { FileExclamationOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

/**
 * `useColumns` is a custom React hook that provides a list of column configurations.
 *
 * The column titles are fetched using the `react-i18next` hook for internationalization.
 *
 * @hook
 * @returns {Array<object>} Returns an array of column configurations to be consumed by a table component.
 */

export const useColumns = () => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});

  const allColumns = [
    {
      title: t('properties.form.missing_documents'),
      key: 'missing_documents',
      dataIndex: ['missing_documents'],
      sorter: true,
      width: 150,
      render: (missing_documents) =>
        missing_documents ? (
          <FileExclamationOutlined
            style={{ fontSize: 18, color: 'var(--warningColor)' }}
          />
        ) : (
          '-'
        )
    },
    {
      title: t('vehicles.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('vehicles.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('vehicles.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? moment(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.validity_start_date'),
      key: 'validity_start_date',
      dataIndex: ['contract', 'validity_start_date'],
      render: (validity_start_date) =>
        validity_start_date
          ? moment(validity_start_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.validity_end_date'),
      key: 'validity_end_date',
      dataIndex: ['contract', 'validity_end_date'],
      render: (validity_end_date) =>
        validity_end_date
          ? moment(validity_end_date).format('DD/MM/YYYY')
          : '-',
      sorter: true
    },
    {
      title: t('vehicles.form.entity'),
      key: 'entity',
      dataIndex: ['entity', 'name'],
      sorter: true
    },
    {
      title: t('vehicles.form.sub_entity'),
      key: 'sub_entity',
      dataIndex: ['sub_entity', 'name'],
      sorter: true
    },
    {
      title: t('vehicles.form.site'),
      key: 'site',
      dataIndex: ['site', 'name'],
      sorter: true
    },
    {
      title: t('vehicles.form.address'),
      key: 'address',
      dataIndex: ['company'],
      sorter: true,
      render: (company) =>
        company
          ? `${company.number || ''} ${company.street || ''} ${
              company.additional || ''
            }`
          : '-'
    },
    {
      title: t('vehicles.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['company', 'zip_code'],
      sorter: true
    },
    {
      title: t('vehicles.form.city'),
      key: 'city',
      dataIndex: ['company', 'city'],
      sorter: true
    },
    {
      title: t('vehicles.form.products'),
      key: 'products',
      dataIndex: 'other_vehicle_type',
      sorter: true,
      render: (other_vehicle_type) =>
        other_vehicle_type &&
        t(`vehicles.form.enums.other_vehicle_type.${other_vehicle_type}`)
    },
    {
      title: t('vehicles.form.vehicle_brand'),
      key: 'vehicle_brand',
      dataIndex: 'vehicle_brand',
      sorter: true
    },
    {
      title: t('vehicles.form.registration_or_serial_number'),
      key: 'registration_or_serial_number',
      dataIndex: 'registration_or_serial_number',
      sorter: true
    },
    {
      title: t('vehicles.form.initial_traffic_release'),
      key: 'initial_traffic_release',
      dataIndex: 'initial_traffic_release',
      sorter: true
    },
    {
      title: t('vehicles.form.vehicle_model'),
      key: 'vehicle_model',
      dataIndex: 'vehicle_model',
      sorter: true
    },
    {
      title: t('vehicles.form.principal_driver'),
      key: 'principal_driver',
      dataIndex: 'principal_driver',
      sorter: true,
      render: (principal_driver) =>
        principal_driver
          ? `${principal_driver.first_name} ${principal_driver.last_name}`
          : '-'
    },
    {
      title: t('vehicles.form.purchase_date_or_lease_start_date'),
      key: 'purchase_date_or_lease_start_date',
      dataIndex: 'purchase_date_or_lease_start_date',
      sorter: true,
      render: (purchase_date_or_lease_start_date) =>
        purchase_date_or_lease_start_date
          ? moment(purchase_date_or_lease_start_date).format('DD/MM/YYYY')
          : '-'
    },
    {
      title: t('vehicles.form.mileage'),
      key: 'mileage',
      dataIndex: 'mileage',
      sorter: true
    },
    {
      title: t('vehicles.form.observations'),
      key: 'observations',
      dataIndex: 'observations',
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/vehicles-other-configs/${companyId}`
      });
      setConfig(data[0]);
    } catch (e) {
      message(e);
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return filteredColumns;
};
