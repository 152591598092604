import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumns } from './utils/columnsAutomissions';
import { useActionColumn } from './utils/actionColumn';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { SelectByEntity } from '../../../components/SelectByEntity';
import { SelectBySite } from '../../../components/SelectBySite';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../../components/FilterByMissingDocuments';

/**
 * A component that lists 'automissions' filtered by selected entity and site.
 *
 * `ListAutomissions` is responsible for fetching and listing 'automissions' for a
 * specific entity and site. It provides filter dropdowns (`SelectByEntity` and `SelectBySite`)
 * to allow users to select specific entity or site to filter the results.
 *
 * Once a user chooses an entity or a site, the component updates the browser URL with the
 * filtering parameters.
 * It allows to filter on a missing_documents key as well.
 *
 * @Component
 *
 * @returns {JSX.Element} The rendered `ListAutomissions` component with filtering capabilities.
 */

export const ListAutomissions = () => {
  const [entity, setEntity] = useState('');
  const [site, setSite] = useState('');
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const columns = useColumns();
  const actionColumn = useActionColumn();
  const { company } = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  let filterParameter = `entity=${entity}`;
  const onFilterEntity = (entityId) => {
    setEntity(entityId || '');
  };

  const onFilterSite = (siteId) => {
    setSite(siteId || '');
  };

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (site === '') {
      filterParameter = `entity=${entity}__missing_documents=${isMissingDocuments}__`;
      navigate({
        pathname: '/programmes/automissions',
        search: `?f=${filterParameter}`
      });
    } else {
      filterParameter = `entity=${entity}__site=${site}__missing_documents=${isMissingDocuments}__`;
      navigate({
        pathname: '/programmes/automissions',
        search: `?f=${filterParameter}`
      });
    }
  }, [entity, site, isMissingDocuments]);

  return (
    <ListResource
      extraQuery={`company=${company}&fleet_type=AUTOMISSION`}
      resourceName="vehicles"
      exportUrl={`exports/company/${company}/fleet-type/AUTOMISSION`}
      importUrl={`imports-vehicles/automission/company/${company}`}
      exportTemplateUrl="export-templates/template/automission"
      tradKey="automissions"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <>
          <SelectByEntity onChange={onFilterEntity} />
          <SelectBySite onChange={onFilterSite} />
          <FilterByMissingDocuments onChange={onFilterMissingDocuments} />
        </>
      }
      populate="entity,site,contract,company"
      editAction={false}
      showAction={false}
      sheetName={t('automissions.title')}
      headers={[]}
      template="template"
    />
  );
};
