import { useState } from 'react';
import { Flex } from 'antd';
import { EditOutlined, MailOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { MailingModalExpertVehicle } from '../../Mailing/MailingModalExpertVehicle';

const iconSize = 18;

/**
 * Creates an action column configuration for a table, including edit and mail icons.
 *
 * @function
 * @param {Function} togglePanelVisibility - Function to toggle panel visibility.
 * @param {Function} setIdWithoutParams - Function to set the ID without params for editing.
 * @param {Function} closeModal - Function to close a modal.
 * @param {String} idWithoutParams - ID without params.
 * @param {String} claimId - ID of the vehicle claim.
 * @returns {Array} An array representing the action column configuration.
 */

export const useActionColumn = (
  togglePanelVisibility,
  setIdWithoutParams,
  idWithoutParams,
  claimId,
  url
) => {
  const [isModalMailingOpen, setIsModalMailingOpen] = useState(false);
  const [templateMail, setTemplateMail] = useState();
  const [claim, setClaim] = useState();
  const [expert, setExpert] = useState();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getExpert = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/experts/${id}` });
      return data;
    } catch (e) {
      message(e);
      return null;
    }
  };
  const getTemplateMail = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/templates-mails/name/ExpertCall?populate=documents,documents.file`
      });
      return data;
    } catch (e) {
      message(e);
      return null;
    }
  };

  const getClaim = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${url}/${claimId}?populate=entry_id,unit_manager,customer_manager`
      });
      return data;
    } catch (e) {
      message(e);
      return null;
    }
  };

  const fetchData = async (id) => {
    try {
      const [expertData, templateMailData, claimData] = await Promise.all([
        getExpert(id),
        getTemplateMail(),
        getClaim()
      ]);

      if (expertData && templateMailData && claimData) {
        setExpert(expertData);
        setTemplateMail(templateMailData);
        setClaim(claimData);
        setIsModalMailingOpen(true);
      }
    } catch (e) {
      message(e);
    }
  };

  const openMailingModal = (id) => {
    fetchData(id);
    setIdWithoutParams(id);
  };

  return [
    {
      key: 'action',
      align: 'right',
      render: (record) => (
        <Flex gap="small" align="center" justify="end" wrap="nowrap">
          <EditOutlined
            style={{ fontSize: iconSize }}
            onClick={() => {
              togglePanelVisibility();
              setIdWithoutParams(record._id);
            }}
          />
          <MailOutlined
            style={{ fontSize: iconSize }}
            onClick={() => {
              openMailingModal(record._id);
            }}
          />
          <MailingModalExpertVehicle
            isModalMailingOpen={isModalMailingOpen}
            setIsModalMailingOpen={setIsModalMailingOpen}
            recipients={expert}
            templateMail={templateMail}
            idWithoutParams={idWithoutParams}
            claim={claim}
          />
        </Flex>
      )
    }
  ];
};
