import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Button } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { MailingBlankClaim } from './Modals/MailingBlankClaim';
import { useErrorMessage } from '../../utils/errorMessage';

export const MailingButtonClaim = ({
  asset,
  templateName,
  tradKey,
  resource,
  baseUrl,
  reference
}) => {
  const [isModalMailingOpen, setIsModalMailingOpen] = useState(false);
  const [templateMail, setTemplateMail] = useState();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const getTemplateMail = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/templates-mails/name/${templateName}?populate=documents,documents.file`
      });
      setTemplateMail(data);
    } catch (e) {
      message(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getTemplateMail();
    })();
  }, [getTemplateMail]);
  return (
    <>
      {tradKey && (
        <Button onClick={() => setIsModalMailingOpen(true)}>
          {`${t(`buttons.${tradKey}`)} `}
          <ImportOutlined />
        </Button>
      )}
      <MailingBlankClaim
        isModalMailingOpen={isModalMailingOpen}
        setIsModalMailingOpen={setIsModalMailingOpen}
        idWithoutParams={asset?._id}
        templateMail={templateMail}
        datas={asset}
        resource={resource}
        baseUrl={baseUrl}
        reference={reference}
        templateName={tradKey}
      />
    </>
  );
};

MailingButtonClaim.propTypes = {
  asset: PropTypes.shape({
    _id: PropTypes.string
  }),
  templateName: PropTypes.string.isRequired,
  tradKey: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired
};

MailingButtonClaim.defaultProps = {
  asset: {}
};
