import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Skeleton, Row, Col, Card, Button, Popconfirm } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContent } from './utils/listContentShowCivilLiability';
import { routes } from '../../../utils/constants/adminRoutes';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { MailingButton } from '../../Mailing/MailingButton';
import { ClaimButton } from '../../components/ClaimButton';

/**
 * Displays detailed information about a subscription.
 * @component
 * @returns {JSX.Element} The JSX element representing the subscription show page.
 */
export const ShowCivilLiability = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [subProgramme, setSubProgramme] = useState([]);
  const [url, setUrl] = useState();
  const listContent = useListContent(datas);
  const navigate = useNavigate();

  const getCivilLiability = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/civil-liabilities/id/${id}`
      });
      setDatas(data);
      setSubProgramme(data?.civil_liability_type);
      setUrl(
        data?.civil_liability_type === 'CIVIL_LIABILITY_PRO'
          ? 'civil-liability-pro'
          : 'civil-liability-executive'
      );
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);
  useEffect(() => {
    (async () => {
      await getCivilLiability();
    })();
  }, []);

  const deleteCivilLiability = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/civil_liabilities/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${datas?.unit_counter || 'xxxx'} - ${t(
          `subscriptions.form.${subProgramme}`
        )}`}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.DOCUMENTS}/edit/${datas?.contract?.documents[0]?._id}`
              }}
            >
              <Button>
                <EditOutlined />
                {`${t('buttons.edit')} `}
              </Button>
            </Link>
            <Button>
              <SyncOutlined />
              {`${t('buttons.generate_attestation')} `}
            </Button>
            <MailingButton
              asset={datas}
              templateName="InsuranceAttestation"
              tradKey="sending_attestation"
              resource="Civil-liabilty"
              baseUrl="civil-liabilities"
            />
            <ClaimButton id={id} url={url} claimType="civilLiability" />
            <MailingButton
              asset={datas}
              templateName="Blank"
              tradKey="specific_demand"
              resource="Civil-liabilty"
              baseUrl="civil-liabilities"
            />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCivilLiability}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row>
          <Col span={12}>
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Card title={t('subscriptions.form.informations')}>
                <DescriptionList
                  data={listContent}
                  translate
                  layout="vertical"
                />
              </Card>
            </Skeleton>
          </Col>
          <Col span={8} />
        </Row>
      </ContentCustom>
    </>
  );
};
