import {
  ListChecked,
  UserMultiple,
  Policy,
  License,
  Flood,
  ContainerServices,
  Catalog,
  DocumentAdd,
  Settings,
  RequestQuote,
  Concept,
  TreeFallRisk
} from '@carbon/icons-react';

const navMenuLogos = {
  HOME: <ListChecked />,
  USERS: <UserMultiple />,
  DOCUMENTS: <License />,
  COMPANIES: <ContainerServices />,
  CONTACTS: <Catalog />,
  WORKFLOWS: <Concept />,
  CONFIGURATIONS: <Settings />,
  CLAIMS: <Flood />,
  TEMPLATE_DOCS: <DocumentAdd />,
  PROGRAMMES: <Policy />,
  TEMPLATE_MAILS: <TreeFallRisk />,
  SUBSCRIPTIONS: <RequestQuote />
};

export default navMenuLogos;
