import { PropTypes } from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Tag } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;

/**
 * A React component for filtering claims by status.
 * @component
 * @returns {JSX.Element} The JSX element representing the status filter.
 */

export const StatusFilter = ({ onChange, url, enums, tagMapping }) => {
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const getStatus = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${url}/enums`
      });
      setSelectedStatus(data[enums]);
    } catch (e) {
      message(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getStatus();
    })();
  }, [getStatus, url]);

  return (
    <Select
      mode="multiple"
      placeholder={t('claims.form.filter_by_status')}
      allowClear
      onChange={onChange}
      maxTagCount={2}
      style={{ minWidth: 220 }}
    >
      {selectedStatus &&
        selectedStatus.length > 0 &&
        selectedStatus.map((s) => (
          <Option key={s} value={s}>
            <Tag color={tagMapping[s]}>{t(`claims.form.${s}`)}</Tag>
          </Option>
        ))}
    </Select>
  );
};

StatusFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  enums: PropTypes.string.isRequired,
  tagMapping: PropTypes.shape({})
};

StatusFilter.defaultProps = {
  tagMapping: null
};
