import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { DriverDrawer } from '../components/DriverDrawer';

import useFields from './utils/fieldsLightVehicles';

/**
 * The `LightVehiclesCreateUpdate` component handles the creation and updating processes for light vehicles.
 * Depending on the `purpose` prop, the component either populates the form with the existing vehicle data for updates or prepares the form for new entries.
 * This component also manages a drawer for adding drivers to the vehicle.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.purpose - Indicates the purpose of the component. Either 'create' for a new vehicle entry or 'edit' for updating an existing one.
 *
 * @returns {JSX.Element} Rendered component.
 */

export const LightVehiclesCreateUpdate = ({ purpose }) => {
  const resource = 'vehicles';
  const fleet_type = 'LIGHT_VEHICLE';
  const draggerFilesKeysMandatory = ['vehicle_registration_document'];
  const [driverId, setDriverId] = useState(null);
  const [isPrincipal, setIsPrincipal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { fieldsLightVehicles, isFieldsLoading } = useFields({
    onAddDriver: () => setIsOpen(true)
  });
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        initial_traffic_release:
          data.initial_traffic_release && dayjs(data.initial_traffic_release),
        purchase_date_or_lease_start_date:
          data.purchase_date_or_lease_start_date &&
          dayjs(data.purchase_date_or_lease_start_date),
        created_at: data.created_at && dayjs(data.created_at),
        desired_effective_date:
          data.desired_effective_date && dayjs(data.desired_effective_date)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company,
        programmes_types: 'FLEET',
        fleet_type,
        ...(driverId && isPrincipal ? { principal_driver: driverId } : {}),
        ...(driverId && !isPrincipal ? { secondary_drivers: driverId } : {})
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...(driverId && isPrincipal ? { principal_driver: driverId } : {}),
        ...(driverId && !isPrincipal ? { secondary_drivers: driverId } : {})
      })
    }
  };

  return (
    <>
      <CreateUpdateContainer
        fields={fieldsLightVehicles}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        draggerFilesKeysMandatory={draggerFilesKeysMandatory}
        baseUrl="vehicles"
        resource={resource}
        config={config}
        extraTitle={
          <Row justify="center" className="form-company-name">{`${t(
            'folder'
          )} ${companyName}`}</Row>
        }
        withFilesManager
        populate="?populate=documents.file"
      />
      <DriverDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setDriverId={setDriverId}
        setIsPrincipal={setIsPrincipal}
      />
    </>
  );
};

LightVehiclesCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
