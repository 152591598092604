import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.transportsFields - Field configurations for transports.
 */

export const useTransportsFields = () => {
  const transportsFields = [
    {
      name: ['missing_documents'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['contract'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['date_added'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['entity'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['sub_entity'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['unit_reference_number'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['shipper'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['departure_location'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['parcel_quantity'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['weight'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['estimated_delivery_date'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['recipient'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['arrival_location'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['means_of_transport'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['nature_of_the_goods'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['value_of_the_goods'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['subscriber_last_name_first_name'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['subscriber_address'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['subscriber_postal_code'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['subscriber_city'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    }
  ];
  return { transportsFields };
};
