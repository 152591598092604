import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('claims.form.amounts'),
      dataIndex: 'amount',
      key: 'amounts'
    },
    {
      title: t('claims.form.incl_or_excl_tax'),
      dataIndex: 'incl_or_excl_tax',
      key: 'incl_or_excl_tax',
      render: (incl_or_excl_tax) =>
        incl_or_excl_tax ? t(`claims.form.${incl_or_excl_tax}`) : '-'
    },
    {
      title: t('claims.form.lawyer_or_expert'),
      dataIndex: 'lawyer_or_expert',
      key: 'lawyer_or_expert',
      render: (lawyer_or_expert) =>
        lawyer_or_expert ? t(`claims.form.${lawyer_or_expert}`) : '-'
    }
  ];
};
