import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useListContent = (data = {}) => {
  const {
    contract,
    created_at,
    entity,
    site,
    customer_manager,
    unit_manager,
    date_of_start,
    validity_end_date,
    other_vehicle_type,
    registration_card_category,
    vehicle_brand,
    vehicle_model,
    registration_or_serial_number,
    initial_traffic_release,
    purchase_date_or_lease_start_date,
    mileage,
    desired_effective_date,
    piston_displacement,
    observations,
    deleted,
    deletion_date
  } = data;

  const { t } = useTranslation();

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'vehicles.form.contract_number',
      span: 1,
      content: (contract && contract.contract_number) || '-'
    },
    {
      label: 'vehicles.form.contract',
      span: 1,
      content: (contract && contract.name) || '-'
    },
    {
      label: 'vehicles.form.date_added',
      span: 1,
      content: (created_at && dayjs(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'vehicles.form.insurance_covering_start',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.entity',
      span: 1,
      content: (entity && entity.name) || '-'
    },
    {
      label: 'vehicles.form.site',
      span: 1,
      content: (site && site.name) || '-'
    },
    {
      label: 'vehicles.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'vehicles.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'vehicles.form.date_of_start',
      span: 1,
      content:
        (date_of_start && dayjs(date_of_start).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'vehicles.form.validity_end_date',
      span: 1,
      content:
        (validity_end_date && dayjs(validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.other_vehicle_type',
      span: 1,
      content:
        (other_vehicle_type &&
          t(`vehicles.form.enums.other_vehicle_type.${other_vehicle_type}`)) ||
        '-'
    },
    {
      label: 'vehicles.form.registration_card_category',
      span: 1,
      content: (registration_card_category && registration_card_category) || '-'
    },
    {
      label: 'vehicles.form.vehicle_brand',
      span: 1,
      content: vehicle_brand || '-'
    },
    {
      label: 'vehicles.form.vehicle_model',
      span: 1,
      content: vehicle_model || '-'
    },
    {
      label: 'vehicles.form.registration_or_serial_number',
      span: 1,
      content:
        (registration_or_serial_number && registration_or_serial_number) || '-'
    },
    {
      label: 'vehicles.form.initial_traffic_release',
      span: 1,
      content:
        (initial_traffic_release &&
          dayjs(initial_traffic_release).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.purchase_date_or_lease_start_date',
      span: 1,
      content:
        (purchase_date_or_lease_start_date &&
          dayjs(purchase_date_or_lease_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.mileage',
      span: 1,
      content: (mileage && `${mileage} km`) || '-'
    },
    {
      label: 'vehicles.form.piston_displacement',
      span: 1,
      content: (piston_displacement && `${piston_displacement} cm³`) || '-'
    },
    {
      label: 'vehicles.form.observations',
      span: 1,
      content: (observations && observations) || '-'
    }
  ];

  if (deleted) {
    labels.push({
      label: 'vehicles.form.deleted',
      span: 1,
      content:
        (deletion_date && dayjs(deletion_date).format('DD/MM/YYYY')) || '-'
    });
  }

  return labels;
};
