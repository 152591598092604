import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Form, Button, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

/**
 * A component that renders multiple address inputs with the option to add and remove.
 *
 * It dynamically creates Form items based on the fields provided and allows users to add
 * new address fields or remove existing ones.
 *
 * @component
 * @param {Object} props - Props for the component.
 * @param {Object[]} props.fields - An array of field objects, where each object describes a form item's properties.
 * @param {string[]} props.fields.name - The name of the field, used for form data mapping.
 * @param {string} props.dbKey - The database key used as the name for the Form.List.
 *
 */

export const MultipleFormFieldsGeneration = ({
  fields,
  dbKey,
  tradKey,
  additionalTranslation
}) => {
  const { t } = useTranslation();
  return (
    <Form.List name={dbKey}>
      {(fieldsData, { add, remove }) => (
        <>
          {fieldsData.map((field, index) => (
            <Form.Item key={field.key} className="license-form">
              <Row justify="space-between">
                <span>
                  {t(`${tradKey}.form.${additionalTranslation || dbKey}`)}&nbsp;
                  {index + 1}
                </span>{' '}
                <CloseOutlined onClick={() => remove(index)} />
                {fields.map(({ name, ...restField }) => (
                  <Row key={name[0]}>
                    <Form.Item
                      name={[field.name, ...name]}
                      label={t(`${tradKey}.form.${name[0]}`)}
                      {...restField}
                    >
                      {restField.input ? restField.input : <Input />}
                    </Form.Item>
                  </Row>
                ))}
              </Row>
            </Form.Item>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block>
              {t(`${tradKey}.form.add`)}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

MultipleFormFieldsGeneration.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ).isRequired,
  dbKey: PropTypes.string.isRequired,
  tradKey: PropTypes.string.isRequired,
  additionalTranslation: PropTypes.string
};

MultipleFormFieldsGeneration.defaultProps = {
  additionalTranslation: undefined
};
