import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Card, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useListContent } from './utils/listContentOtherVehicles';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { routes } from '../../../../utils/constants/adminRoutes';
import { Documents } from '../../../components/Documents';
import { Drivers } from '../components/Drivers';
import { GenerateGreenCard } from '../../../../utils/GenerateGreenCard';
import { MailingButtonVehicles } from '../components/MailingButtonVehicles';
import { ClaimButton } from '../../../components/ClaimButton';

/**
 * OtherVehicleShow Component.
 * Displays details of a specific heavy vehicle including its main information, associated drivers, and vehicle documents.
 *
 * @component
 *
 * @returns {ReactElement} Rendered component.
 */

export const OtherVehicleShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState();
  const [principal_driver, setPrincipal_driver] = useState();
  const [secondary_drivers, setSecondary_drivers] = useState();
  const listContent = useListContent(vehicle);

  const iconSize = 18;

  const handleGenerateGreenCard = async (vehicleId) => {
    await GenerateGreenCard(vehicleId, dispatchAPI, t, message);
  };

  const getVehicle = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/vehicles/${id}?populate=customer_manager,unit_manager,contract,entity,entity.company,site,principal_driver,secondary_drivers`
      });
      setVehicle(data);
      setPrincipal_driver(data.principal_driver);
      setSecondary_drivers(data.secondary_drivers);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getVehicle();
    })();
  }, [getVehicle]);

  const deleteVehicle = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/vehicles/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={`${t('number')} ${vehicle?.unit_counter || 'xxxx'} ${
          vehicle?.vehicle_brand || ''
        } ${vehicle?.vehicle_model || ''} ${
          vehicle?.registration_or_serial_number || ''
        }`}
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.PROGRAMMES}/other-vehicles/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            {['MMA', 'Groupama'].includes(
              vehicle?.contract.insurance_company
            ) && (
              <Tooltip title={t('light_vehicle.actions.generate_green_card')}>
                <Button onClick={() => handleGenerateGreenCard(id)}>
                  <DownloadOutlined style={{ fontSize: iconSize }} />
                  {t('light_vehicle.actions.generate_green_card')}
                </Button>
              </Tooltip>
            )}
            <MailingButtonVehicles
              asset={vehicle}
              templateName="GreenCard"
              tradKey="sending_green_card"
            />
            <ClaimButton id={id} url="heavy-vehicles" claimType="vehicle" />
            <MailingButtonVehicles
              asset={vehicle}
              templateName="Blank"
              tradKey="specific_demand"
            />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteVehicle}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={12}>
            <Skeleton loading={isLoading} title={0} paragraph={0} active />
            <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                    title={t('vehicles.form.main_informations')}
                  />
                </Card>
                <Drivers
                  principal_driver={principal_driver}
                  secondary_drivers={secondary_drivers}
                />
              </Row>
            </Skeleton>
          </Col>
          <Col xs={24} xl={12}>
            <Documents
              idWithoutParams={id}
              baseUrl="vehicles"
              resource="Vehicle"
            />
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};
