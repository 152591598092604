import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';

const { Paragraph, Text } = Typography;

/**
 * A reusable button component to request garage information for a claim.
 *
 * @component
 * @param {string} claimReferenceNumber - The claim reference number to be displayed.
 * @returns {JSX.Element} The JSX element representing the garage information request button.
 */

export const GarageInfosButton = ({ claimReferenceNumber }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleInfoRequest = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        icon={<EnvironmentOutlined />}
        onClick={() => handleInfoRequest(true)}
      >
        {`${t('claims.form.garage_infos_demand')} `}
      </Button>
      <Modal
        open={isModalOpen}
        footer={[
          <Button onClick={() => closeModal()}>
            {t('claims.form.ok_button')}
          </Button>
        ]}
        title={t('claims.form.informations_request')}
      >
        <Typography>
          <Paragraph>
            <Text>{t('claims.form.claim_number')}</Text>
            <Text strong>{`${claimReferenceNumber}`}</Text>
          </Paragraph>
          <Paragraph>
            {t('claims.form.garage_informations_request_mail_sent')}
          </Paragraph>
        </Typography>
      </Modal>
    </>
  );
};

GarageInfosButton.propTypes = {
  claimReferenceNumber: PropTypes.string
};

GarageInfosButton.defaultProps = {
  claimReferenceNumber: null
};
