import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { TileLink } from '../components/TileLink';
import { TileMenu } from '../components/TileMenu';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import {
  CivilTile,
  ConstructionTile,
  FreightTile,
  GolfTile,
  RealestateTile,
  VehicleTile
} from '../../utils/constants/customIcons';

export const ClaimsTilesPage = ({ title }) => {
  const { t } = useTranslation();
  const { user, dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();

  const [properties, setProperties] = useState(true);
  const [golfs, setGolfs] = useState(true);
  const [vehicles, setVehicles] = useState(true);
  const [civil, setCivil] = useState(true);
  const [transport, setTransport] = useState(true);
  const [constructionRight, setConstruction] = useState(true);
  const [fleetDisplay, setFleetDisplay] = useState(false);
  const [civilLiabilityDisplay, setCivilLiabilityDisplay] = useState(false);
  const [transportDisplay, setTransportDisplay] = useState(false);
  const [constructionDisplay, setConstructionDisplay] = useState(false);
  const [golfDisplay, setGolfDisplay] = useState(false);
  const [propertyDisplay, setPropertyDisplay] = useState(false);
  const [vehiclesFleetLinks, setVehiclesFleetLinks] = useState([]);
  const [civilLiabilityLinks, setCivilLiabilityLinks] = useState([]);
  const [freightLinks, setFreightLinks] = useState([]);
  const [constructionLinks, setConstructionLinks] = useState([]);

  const verifIfUserHasPermission = (permission) => {
    if (permission?.val === true) return true;
    return (
      permission?.children?.some((child) => verifIfUserHasPermission(child)) ||
      false
    );
  };
  const setUserPermissions = (permissions) => {
    permissions[1].children.forEach((permission) => {
      const keys = permission?.key.split('-');
      const key = keys.slice(-1);
      switch (key[0]) {
        case 'PROPERTY':
          setProperties(verifIfUserHasPermission(permission));
          break;
        case 'GOLF':
          setGolfs(verifIfUserHasPermission(permission));
          break;
        case 'FLEET':
          setVehicles(verifIfUserHasPermission(permission));
          break;
        case 'CIVIL_LIABILITY':
          setCivil(verifIfUserHasPermission(permission));
          break;
        case 'TRANSPORT':
          setTransport(verifIfUserHasPermission(permission));
          break;
        case 'CONSTRUCTION':
          setConstruction(verifIfUserHasPermission(permission));
          break;
        default:
          break;
      }
    });
  };
  const getUserPermissions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `permissions/${user?.permissions}`
      });
      const permissions = data?.permissions;
      setUserPermissions(permissions);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (user?.permissions && user.role === 'users:USER') {
      (async () => {
        await getUserPermissions();
      })();
    }
  }, [user]);

  const getProgrammesToDisplay = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `claims/${company}`
      });
      const {
        programmesToDisplay,
        vehicleFleet,
        civilLiability,
        freight,
        construction
      } = data;
      setFleetDisplay(programmesToDisplay.includes('FLEET'));
      setGolfDisplay(programmesToDisplay.includes('GOLF'));
      setPropertyDisplay(programmesToDisplay.includes('PROPERTY'));
      setTransportDisplay(programmesToDisplay.includes('TRANSPORT'));
      setCivilLiabilityDisplay(programmesToDisplay.includes('CIVIL_LIABILITY'));
      setConstructionDisplay(programmesToDisplay.includes('CONSTRUCTION'));
      setVehiclesFleetLinks(vehicleFleet);
      setCivilLiabilityLinks(civilLiability);
      setFreightLinks(freight);
      setConstructionLinks(construction);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    (async () => {
      await getProgrammesToDisplay();
    })();
  }, [company]);

  return (
    <>
      <PageHeaderCustom title={t(`home.${title}`)} />
      <ContentCustom>
        <Row gutter={[24, 24]} align="stretch">
          {properties && propertyDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                image={<RealestateTile />}
                altImage="property"
                target="claims"
                link="properties"
              />
            </Col>
          )}
          {golfs && golfDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileLink
                image={<GolfTile />}
                altImage="golf"
                link="golfs"
                target="claims"
              />
            </Col>
          )}
          {vehicles && fleetDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileMenu
                image={<VehicleTile />}
                altImage="vehicles_fleet"
                links={vehiclesFleetLinks}
                target="claims"
              />
            </Col>
          )}
          {civil && civilLiabilityDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileMenu
                image={<CivilTile />}
                altImage="civil_liability"
                target="claims"
                links={civilLiabilityLinks}
              />
            </Col>
          )}
          {transport && transportDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileMenu
                image={<FreightTile />}
                altImage="transport"
                links={freightLinks}
                target="claims"
              />
            </Col>
          )}
          {constructionRight && constructionDisplay && (
            <Col xs={24} md={12} xl={8}>
              <TileMenu
                image={<ConstructionTile />}
                altImage="construction"
                target="claims"
                links={constructionLinks}
              />
            </Col>
          )}
        </Row>
      </ContentCustom>
    </>
  );
};
ClaimsTilesPage.propTypes = {
  title: PropTypes.string.isRequired
};
