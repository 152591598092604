import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * `useListContent` is a hook for formatting and translating vehicle-related data for listing.
 *
 * @hook
 * @param {Object} data - The vehicle data to format and translate.
 * @param {Object} data.contract - The contract data associated with the vehicle.
 * @param {string} data.created_at - The creation date of the vehicle.
 * @param {Object} data.entity - The entity data associated with the vehicle.
 * @param {Object} data.site - The site data associated with the vehicle.
 * @param {Object} data.customer_manager - The customer manager data associated with the vehicle.
 * @param {Object} data.unit_manager - The unit manager data associated with the vehicle.
 * @param {string} data.heavy_vehicle_type - The type of heavy vehicle.
 * @param {string} data.registration_card_category - The registration card category of the vehicle.
 * @param {string} data.vehicle_brand - The brand of the vehicle.
 * @param {string} data.vehicle_model - The model of the vehicle.
 * @param {string} data.registration_or_serial_number - The registration or serial number of the vehicle.
 * @param {string} data.initial_traffic_release - The initial traffic release date of the vehicle.
 * @param {string} data.purchase_date_or_lease_start_date - The purchase or lease start date of the vehicle.
 * @param {number} data.mileage - The mileage of the vehicle.
 * @param {number} data.piston_displacement - The piston displacement of the vehicle.
 * @param {string} data.observations - Observations about the vehicle.
 * @returns {Array} An array of label objects containing formatted and translated vehicle data.
 */

export const useListContent = (data = {}) => {
  const {
    contract,
    created_at,
    entity,
    site,
    customer_manager,
    unit_manager,
    heavy_vehicle_type,
    registration_card_category,
    vehicle_brand,
    vehicle_model,
    registration_or_serial_number,
    desired_effective_date,
    initial_traffic_release,
    purchase_date_or_lease_start_date,
    mileage,
    piston_displacement,
    observations,
    deleted,
    deletion_date
  } = data;

  const { t } = useTranslation();

  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  const labels = [
    {
      label: 'vehicles.form.contract_number',
      span: 1,
      content: (contract && contract.contract_number) || '-'
    },
    {
      label: 'vehicles.form.contract',
      span: 2,
      content: entity?.company?.name || '-'
    },
    {
      label: 'vehicles.form.date_added',
      span: 1,
      content: (created_at && dayjs(created_at).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'vehicles.form.insurance_covering_start',
      span: 1,
      content:
        (desired_effective_date &&
          dayjs(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.entity',
      span: 1,
      content: (entity && entity.name) || '-'
    },
    {
      label: 'vehicles.form.site',
      span: 1,
      content: (site && site.name) || '-'
    },
    {
      label: 'vehicles.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'vehicles.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'vehicles.form.date_of_start',
      span: 1,
      content:
        (contract?.date_of_start &&
          dayjs(contract?.date_of_start).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.validity_end_date',
      span: 1,
      content:
        (contract?.validity_end_date &&
          dayjs(contract?.validity_end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.heavy_vehicle_type',
      span: 1,
      content:
        (heavy_vehicle_type &&
          t(`vehicles.form.enums.heavy_vehicle_type.${heavy_vehicle_type}`)) ||
        '-'
    },
    {
      label: 'vehicles.form.registration_card_category',
      span: 1,
      content: (registration_card_category && registration_card_category) || '-'
    },
    {
      label: 'vehicles.form.vehicle_brand',
      span: 1,
      content: vehicle_brand || '-'
    },
    {
      label: 'vehicles.form.vehicle_model',
      span: 1,
      content: vehicle_model || '-'
    },
    {
      label: 'vehicles.form.registration_or_serial_number',
      span: 1,
      content:
        (registration_or_serial_number && registration_or_serial_number) || '-'
    },
    {
      label: 'vehicles.form.initial_traffic_release',
      span: 1,
      content:
        (initial_traffic_release &&
          dayjs(initial_traffic_release).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.purchase_date_or_lease_start_date',
      span: 1,
      content:
        (purchase_date_or_lease_start_date &&
          dayjs(purchase_date_or_lease_start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'vehicles.form.mileage',
      span: 1,
      content: (mileage && `${mileage} km`) || '-'
    },
    {
      label: 'vehicles.form.piston_displacement',
      span: 1,
      content: (piston_displacement && `${piston_displacement} cm³`) || '-'
    },
    {
      label: 'vehicles.form.observations',
      span: 1,
      content: (observations && observations) || '-'
    }
  ];

  if (deleted) {
    labels.push({
      label: 'vehicles.form.deleted',
      span: 1,
      content:
        (deletion_date && dayjs(deletion_date).format('DD/MM/YYYY')) || '-'
    });
  }

  return labels;
};
