import { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { SubscriptionContext } from '../SubscriptionContext';

/**
 * Utility functions for handling subscription status changes.
 * @param {Function} setVisible A function to set the visibility of the modal.
 * @returns {Object} An object containing utility functions and state variables.
 */
export const handleStatusUtils = (setVisible) => {
  const { dispatchAPI, permissions, verifPermissions } = useAuthContext();
  const [current, setCurrent] = useState(0);
  const [edit, setEdit] = useState(false);
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [history, setHistory] = useState([]);
  const { forceRefresh, setForceRefresh } = useContext(SubscriptionContext);

  const claimStep = {
    0: 'request_received',
    1: 'additional_request',
    2: 'insurance_exchange',
    3: 'rejected',
    4: 'accepted'
  };

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        verifPermissions(
          '/subscriptions/contract/edit',
          permissions?.notPermittedRoutes
        )
      );
    }
  }, [permissions]);

  const steps = [
    {
      title: 'declaration_received',
      description: t('subscriptions.form.request_received')
    },
    {
      title: 'processing_started',
      description: t('subscriptions.form.additional_request')
    },
    {
      title: 'insurance_exchange',
      description: t('subscriptions.form.insurance_exchange')
    },
    {
      title: 'request_rejected',
      description: t('subscriptions.form.rejected')
    },
    {
      title: 'request_accepted',
      description: t('subscriptions.form.accepted')
    }
  ];

  const onChange = async (value, id, test, refusal_reason) => {
    if (edit === true || current === 4) {
      return;
    }
    if ((current === 3 || value >= 3) && test !== true) {
      if (value === 3) {
        setVisible(true);
      }
      return;
    }
    setCurrent(value);
    const description = steps[value]?.description;

    const timestamp = moment(new Date()).format('DD/MM/YYYY hh:mm');
    const historyEntry = `${description} ${timestamp}`;
    setHistory((prevHistory) => [...prevHistory, historyEntry]);
    try {
      await dispatchAPI('PATCH', {
        url: `subscriptions/${id}`,
        body: JSON.stringify({
          values: JSON.stringify({
            step: value,
            status: claimStep[value],
            history: [...history, historyEntry],
            refusal_reason: refusal_reason && refusal_reason
          })
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (e) {
      message(e);
    }
    setForceRefresh(!forceRefresh);
  };

  return {
    current,
    history,
    setHistory,
    setCurrent,
    onChange
  };
};
