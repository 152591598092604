import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { Select, Form } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;
const { Item } = Form;

/**
 * `SelectSite` is a component that renders a dropdown with site names.
 * It fetches sites based on the provided `company` and displays them in the dropdown.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {string} props.dbKey - The database key for the item.
 * @returns {JSX.Element} The rendered `Select` component containing site names.
 */

export const SelectSite = ({ dbKey, isDisabled }) => {
  const [sites, setSites] = useState([]);
  const { dispatchAPI, company } = useAuthContext();
  const { message } = useErrorMessage();

  const getsites = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entities/company/${company}?populate=sites`
      });
      const allSites = data.reduce(
        (acc, currEntity) => acc.concat(currEntity.sites || []),
        []
      );
      setSites(allSites);
    } catch (e) {
      message(e);
    }
  }, [company]);

  useEffect(() => {
    (async () => {
      await getsites();
    })();
  }, [getsites]);

  return (
    <Item name={dbKey}>
      <Select
        disabled={isDisabled}
        showSearch
        filterOption={(input, option) =>
          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
        }
      >
        {sites &&
          sites.map((s) => (
            <Option key={s._id} value={s._id}>
              {s.name}
            </Option>
          ))}
      </Select>
    </Item>
  );
};

SelectSite.propTypes = {
  dbKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool
};

SelectSite.defaultProps = {
  isDisabled: false
};
