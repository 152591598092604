import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Option } = Select;

export const SelectByEntity = ({ onChange }) => {
  const { t } = useTranslation();
  const [entities, setEntities] = useState([]);
  const { company, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const id = company;

  const getEntities = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/entities/company/${id}`
      });
      setEntities(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      await getEntities();
    })();
  }, [getEntities]);

  return (
    <Select
      allowClear
      placeholder={t('vehicles.filter.filter_by_entity')}
      showSearch
      onChange={onChange}
      filterOption={(input, option) =>
        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
      }
    >
      {entities &&
        entities.map((e) => (
          <Option key={e._id} value={e._id} title={e.name}>
            {e.name}
          </Option>
        ))}
    </Select>
  );
};

SelectByEntity.propTypes = {
  onChange: PropTypes.func.isRequired
};
