import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { Modal, Spin, Typography, Button } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

/**
 * A button component for exporting data to an XLSX file.
 *
 * This component fetches data from the specified URL, translates the data,
 * and exports it to an XLSX file.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.url - The URL for fetching data (required).
 * @param {string} props.populate - The optional parameter for data population.
 * @param {string} props.sheetName - The name of the XLSX sheet.
 * @param {string} props.dataName - The name of the data source.
 *
 * @returns {ReactElement} The rendered `ExportXlsxButton` component.
 */

export const ExportXlsxButton = ({
  url,
  populate,
  sheetName,
  dataName,
  exportDatas
}) => {
  const { dispatchAPI, companyName } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const translateKeys = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
      const newKey = t(`${dataName}.form.${key}`);
      newObj[newKey] = obj[key];
    });
    return newObj;
  };

  const fetchData = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `${url}?populate=${populate}`,
        body: exportDatas
      });
      const translatedData = data.map((item) => translateKeys(item, t));
      const ws = XLSX.utils.json_to_sheet(translatedData);

      const colWidths = Object.keys(ws)
        .filter((key) => key.match(/[A-Z]+1$/))
        .map((key) => {
          const value = ws[key].v;
          return { wch: value !== null ? value.toString().length + 1 : 0 };
        });

      ws['!cols'] = colWidths;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${sheetName}`);
      XLSX.writeFile(wb, `${companyName}.xlsx`);
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <Modal
        closable={false}
        footer={false}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin spinning size="large" style={{ margin: 16 }} />
        <br />
        <Typography.Text>
          Nous préparons votre fichier. Merci de patienter.
        </Typography.Text>
      </Modal>
      <Button onClick={fetchData} type="link">
        <DownloadOutlined style={{ fontSize: '14px', marginRight: 8 }} />
        {t(`buttons.export`)}
      </Button>
    </>
  );
};

ExportXlsxButton.propTypes = {
  url: PropTypes.string.isRequired,
  populate: PropTypes.string,
  sheetName: PropTypes.string,
  dataName: PropTypes.string,
  exportDatas: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

ExportXlsxButton.defaultProps = {
  populate: null,
  sheetName: null,
  dataName: null
};
