import { useContext, useState, useCallback, useEffect } from 'react';
import {
  Select,
  Col,
  Divider,
  Row,
  Input,
  Checkbox,
  Button,
  Tree,
  notification
} from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import TreePermissionsUserTwo from './TreePermissionsUserTwo';
import { ContentCustom } from '../../../../components/ContentCustom/ContentCustom';
import { handleSearch, handleSearchUserTwo } from '../utils/searchUtils';

const { Search } = Input;
const { Option } = Select;

/**
 * Component for managing permissions.
 *
 * @component
 * @returns {JSX.Element} PermissionsManagement component.
 */
export const PermissionsManagement = () => {
  const { dispatchAPI, company } = useContext(AuthContext);
  const { message } = useErrorMessage();
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [secondShow, setSecondShow] = useState(false);
  const [secondUserId, setSecondUserId] = useState('');
  const { t } = useTranslation();
  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
  const [selectRead, setSelectRead] = useState(false);
  const [, setKeySearched] = useState('');
  const [keySearchedUserTwo, setKeySearchedUserTwo] = useState('');
  const [nodeChecked, setNodeChecked] = useState([]);
  const [permissionId, setPermissionId] = useState('');
  const [defaultChecked, setDefaultChecked] = useState([]);
  const [treeDataUserOne, setTreeDataUserOne] = useState([]);

  const getUser = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/users/companies/${company}?populate=permissions`,
        body: { role: 'users:USER' }
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    getUser(company);
  }, [company]);

  const getAllSelect = (treeData) => {
    const allSelect = [];
    treeData?.forEach((permission) => {
      if (permission?.children) {
        allSelect.push(...getAllSelect(permission?.children));
      }
      allSelect.push(permission?.key);
    });
    setNodeChecked(allSelect);
    setDefaultChecked(allSelect);
    return allSelect;
  };

  const getVisualisationSelect = (treeData) => {
    const allSelect = [];

    treeData?.forEach((permission) => {
      if (permission?.children) {
        allSelect.push(...getVisualisationSelect(permission?.children));
      }
      if (permission?.title === 'Visualisation') {
        allSelect.push(permission?.key);
      }
    });

    setNodeChecked(allSelect);
    setDefaultChecked(allSelect);
    return allSelect;
  };

  const parsePermissionsChecked = (permissions) => {
    const permissionsChecked = [];

    permissions?.forEach((permission) => {
      if (permission?.val === true) {
        permissionsChecked.push(permission?.key);
      }
      if (permission?.children) {
        permissionsChecked.push(
          ...parsePermissionsChecked(permission?.children)
        );
      }
    });

    return permissionsChecked;
  };

  const showRights = (value) => {
    setPermissionId(value);
    const userInfo = users.find((user) => user.permissions?._id === value);
    setDefaultChecked(
      parsePermissionsChecked(userInfo?.permissions?.permissions)
    );
    setTreeDataUserOne(userInfo?.permissions?.permissions);
    setShow(true);
  };

  const showRightsSecondUser = (value) => {
    setSecondUserId(value);
    setSecondShow(true);
  };

  const handleCancel = () => {
    setShow(false);
    setNodeChecked([]);
    setDefaultChecked([]);
    setTreeDataUserOne([]);
    setSelectAllCheckBox(false);
  };

  const handleSelectRead = () => {
    getVisualisationSelect(treeDataUserOne);
    setSelectRead(!selectRead);
  };

  const handleSelectAll = () => {
    getAllSelect(treeDataUserOne);
    setSelectAllCheckBox(!selectAllCheckBox);
  };

  const handleDefaultChecked = () => defaultChecked;

  const handleOnCkeck = (checkedKeys) => {
    setNodeChecked(checkedKeys);
    setDefaultChecked(checkedKeys);
  };

  const handleSave = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `permissions/${permissionId}`,
        body: {
          newPermissions: nodeChecked
        }
      });
      setShow(false);
      notification.success({ message: t('export.messages.success') });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <ContentCustom>
        <p>{t('companies.form.user_select')}</p>
        <Select style={{ width: '20%' }} onChange={showRights}>
          {users
            ? users.map((user) => (
                <Option key={user._id} value={user?.permissions?._id}>
                  {user.first_name}&nbsp;{user.last_name}
                </Option>
              ))
            : null}
        </Select>
      </ContentCustom>
      {show ? (
        <ContentCustom>
          <Divider style={{ backgroundColor: 'var(--primaryColor)' }} />
          <Row gutter={[24, 24]}>
            <Col
              span={14}
              style={{ borderRight: '1px solid var(--borderColor)' }}
            >
              <Row justify="space-between">
                <Search
                  style={{ width: 240 }}
                  allowClear
                  placeholder={t('placeholder.search')}
                  onSearch={(value) => handleSearch(value, setKeySearched)}
                />
                <Checkbox
                  checked={selectAllCheckBox}
                  onChange={handleSelectAll}
                >
                  {t('companies.form.check_visualize_modify')}
                </Checkbox>
                <Checkbox checked={selectRead} onChange={handleSelectRead}>
                  {t('companies.form.check_all_visualization')}
                </Checkbox>
                <Button
                  type="primary"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  {t('buttons.save')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  {t('buttons.cancel')}
                </Button>
              </Row>
              <Tree
                checkedKeys={handleDefaultChecked()}
                checkable
                autoExpandParent
                isLoading
                treeData={treeDataUserOne}
                onCheck={(checkedKeys) => {
                  handleOnCkeck(checkedKeys);
                }}
              />
            </Col>
            <Col span={10}>
              <Col>
                <p>{t('companies.form.other_user_select')}</p>
                <Select
                  style={{ width: '40%' }}
                  onChange={showRightsSecondUser}
                >
                  {users
                    ? users.map((user) => (
                        <Option key={user._id} value={user?.permissions?._id}>
                          {user.first_name}&nbsp;{user.last_name}
                        </Option>
                      ))
                    : null}
                </Select>
              </Col>
              {secondShow ? (
                <Col style={{ marginTop: 16 }}>
                  <Search
                    style={{ width: '30%' }}
                    allowClear
                    placeholder={t('placeholder.search')}
                    onSearch={(value) =>
                      handleSearchUserTwo(value, setKeySearchedUserTwo)
                    }
                  />
                  <TreePermissionsUserTwo
                    userId={secondUserId}
                    keySearched={keySearchedUserTwo}
                  />
                </Col>
              ) : null}
            </Col>
          </Row>
        </ContentCustom>
      ) : null}
    </>
  );
};
