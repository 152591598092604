import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../contexts/AuthContext';

import useFields from './utils/fieldsProperty';

export const PropertyCreateUpdate = ({ purpose }) => {
  const resource = 'properties';
  const draggerFilesKeys = [
    'lease_agreements',
    'Deed_of_acquisition',
    'plans',
    'sectional_plans',
    'external_plans_or_cadastral_parcel_designations',
    'plans_Documents_for_specific_installations',
    'condominium_status',
    'statutes_of_ASL_AFUL',
    'certificates_Q4_Q5_Q17_Q18_Q19',
    'security_surveillance_contracts',
    'KBIS',
    'presentation_model',
    'current_contract',
    'loss_history_report',
    'tax_return_package',
    'other'
  ];
  const { fieldsProperty, isFieldsLoading } = useFields();
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();
  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        desired_effective_date:
          data.desired_effective_date && dayjs(data.desired_effective_date),
        date_added: data.date_added && dayjs(data.date_added)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        programmes_types: 'PROPERTY',
        company
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };
  return (
    <CreateUpdateContainer
      fields={fieldsProperty}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      draggerFilesKeys={draggerFilesKeys}
      baseUrl="properties"
      resource={resource}
      config={config}
      extraTitle={
        <Row justify="center" className="form-company-name">{`${t(
          'folder'
        )} ${companyName}`}</Row>
      }
      withFilesManager
      populate="?populate=documents.file"
    />
  );
};

PropertyCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
