import { Checkbox } from 'antd';

/**
 * A custom hook that returns predefined field configurations.
 *
 * This configuration can be used in conjunction with form libraries or frameworks
 * that need a set of field definitions to render and validate form controls.
 *
 * @hook
 * @returns {Array<Object>} returns.propertyFields - Field configurations for vehicles.
 */

export const usePropertiesFields = () => {
  const propertyFields = [
    {
      name: ['missing_documents'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['contract_number'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['date_added'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['entity'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['site'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['asset_name'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['assets_addresses'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['postal_code'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['city'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['country'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['total_insured_developed_area'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['legal_status'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['typology'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['tenant_occupation'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    },
    {
      name: ['last_and_first_name_of_tenant'],
      rules: [{ required: false }],
      valuePropName: 'checked',
      input: <Checkbox />
    }
  ];

  return { propertyFields };
};
