import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFields } from './fieldsConstructionInsurance';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';

/**
 * Provides a component for creating or updating Construction insurance claims.
 *
 * @component
 * @param {object} props - React component props.
 * @param {string} props.purpose - The purpose of the component, either "create" or "edit".
 * @returns {React.ReactNode} The CreateUpdateContainer component with specific configurations for Construction insurance claims.
 */

export const ConstructionInsuranceCreateUpdate = ({ purpose }) => {
  const { dispatchAPI, company } = useAuthContext();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const [constructionInsurance, setConstructionInsurance] = useState();
  const [isComplaint, setIsComplaint] = useState(false);
  const { fieldsInformations } = useFields(setIsComplaint);

  const draggerFilesKeysOverRide = [
    'lifting_of_reservation_report',
    'letter_formal_notice',
    'reception_report',
    'registered_mail',
    'declaration_to_insurer',
    'full_statement',
    'filing_complaint'
  ];
  const mandatoryDocuments = isComplaint ? ['filing_complaint'] : [];
  const draggerFilesKeysMandatory = isComplaint ? ['filing_complaint'] : [];

  const getConstructionInsurance = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `constructions/${id}?populate=entity,site,contract,company_Id`
      });
      setConstructionInsurance(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getConstructionInsurance();
    })();
  }, [id]);

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        sinister_date: data.sinister_date && dayjs(data.sinister_date)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        entry_id: constructionInsurance?._id,
        company,
        contract: constructionInsurance?.contract?._id,
        opening_date: dayjs(Date.now()),
        city: constructionInsurance?.city,
        entity: constructionInsurance?.entity?._id,
        site: constructionInsurance?.site?._id,
        construction_sub_programmes: 'CONSTRUCTION_INSURANCE'
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="construction_claims"
      resource="claims"
      config={config}
      populate="?populate=documents.file"
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      mandatoryDocuments={mandatoryDocuments}
      draggerFilesKeysMandatory={draggerFilesKeysMandatory}
    />
  );
};

ConstructionInsuranceCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
