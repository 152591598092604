import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  ClearOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Col,
  Flex,
  Skeleton,
  Row,
  Steps,
  Popconfirm
} from 'antd';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useListContentClaim } from '../listContentClaim';
import { useListContent } from '../listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../../components';
import { Documents } from '../../../components/Documents';
import {
  useListItemsStep,
  useListBeforeValidation
} from '../../utils/listItemsStep';
import { routes } from '../../../../utils/constants/adminRoutes';
import { handleStatusUtils } from '../../utils/handleStatusUtils';
import { AcknowledgeReceiptButton } from '../../utils/ClaimsButtons/AcknowledgeReceiptButton';
import { AcknowledgeComplianceButton } from '../../utils/ClaimsButtons/AcknowledgeComplianceButton';
import { ExpertCall } from '../../utils/ClaimsButtons/ExpertCall';
import { ModalClosure } from '../../utils/ModalClosure';
import { AttestationGenerationButton } from '../../utils/ClaimsButtons/AttestationGenerationButton';
import { MailingButtonClaim } from '../../../Mailing/MailingButtonClaim';

/**
 * Displays detailed information about a civilLiability claim, including claim information, civilLiability information,
 * pictures, and related actions.
 *
 * @component
 * @returns {React.ReactNode} Detailed view of the civilLiability claim.
 */

export const CivilLiabilityProShow = () => {
  const targetUrl = 'civil_liability_claims';
  const { id } = useParams();
  const { dispatchAPI, company, permissions, verifPermissions } =
    useAuthContext();
  const { message } = useErrorMessage();
  const { current, setCurrent, onChange, toEnclosed, setToEnclose } =
    handleStatusUtils(targetUrl);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { items } = useListItemsStep();
  const { itemsBeforeValidation } = useListBeforeValidation();
  const [civilLiability, setCivilLiability] = useState();
  const [civilLiabilityClaim, setciviliabilityClaim] = useState();
  const [civilLiabilityId, setCivilLiabilityId] = useState();
  const [isCompliant, setIsCompliant] = useState(false);
  const [edit, setEdit] = useState(true);
  const listContentClaim = useListContentClaim(civilLiabilityClaim);
  const listContent = useListContent(civilLiability);
  const [closure, setClosure] = useState(false);

  const getCivilLiabilityClaim = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/civil_liability_claims/documents/${id}`
      });
      setciviliabilityClaim(data);
      setCivilLiabilityId(data.entry_id._id);
      setCurrent(data.step);
      setIsCompliant(data.is_compliant);
      setToEnclose(data.to_enclose);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  const getCivilLiabilityPro = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/civil-liabilities/${civilLiabilityId}?populate=customer_manager,unit_manager,contract,contract.entity,site,company_id`
      });
      setCivilLiability(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [civilLiabilityId]);

  useEffect(() => {
    if (civilLiabilityId) {
      (async () => {
        await getCivilLiabilityPro();
      })();
    }
  }, [civilLiabilityId]);

  useEffect(() => {
    (async () => {
      await getCivilLiabilityClaim();
    })();
  }, []);

  const reception = () => {
    onChange(0, id, edit);
  };

  const closureValidation = () => {
    setClosure(true);
    onChange(6, id, edit);
  };

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions('/claims/edit', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  const extraButtons = (
    <Flex gap="small" wrap="wrap" justify="end">
      {current === undefined ? (
        <AcknowledgeReceiptButton
          reception={reception}
          claimReferenceNumber={civilLiabilityClaim?.unit_counter}
        />
      ) : null}
      {current !== 6 && current !== undefined ? (
        <>
          <MailingButtonClaim
            asset={civilLiabilityClaim}
            templateName="Blank"
            tradKey="specific_demand"
            resource="Civil_liabilty_claim"
            baseUrl="civil_liability_claims"
            reference={civilLiability?.contract?.contract_number}
          />
          {(current === 0 || current === 1) && !isCompliant ? (
            <AcknowledgeComplianceButton
              setIsCompliant={setIsCompliant}
              claimReferenceNumber={civilLiabilityClaim?.unit_counter}
              id={id}
              url="/civil_liability_claims"
            />
          ) : null}
          {current === 2 && isCompliant ? (
            <ExpertCall
              company={company}
              claimReferenceNumber={civilLiabilityClaim?.unit_counter}
              programmeType="CIVIL_LIABILITY"
              claimId={id}
              claimNumber={civilLiabilityClaim?.unit_counter}
              url="civil_liability_claims"
            />
          ) : null}
          {current >= 2 && isCompliant ? (
            <>
              <AttestationGenerationButton />
              <MailingButtonClaim
                asset={civilLiabilityClaim}
                templateName="Blank"
                tradKey="sending_attestation"
                resource="Civil_liability_claim"
                baseUrl="civil_liability_claims"
                reference={civilLiability?.contract?.contract_number}
              />
            </>
          ) : null}
          {edit && (
            <Link
              to={{
                pathname: `${routes.CLAIMS}/civil-liability-pro/edit/${id}`
              }}
            >
              <Button icon={<EditOutlined />}>{`${t(
                'claims.form.edit'
              )} `}</Button>
            </Link>
          )}
          {toEnclosed && (
            <Popconfirm
              title={t('claims.popconfirm.title')}
              okText={t('claims.popconfirm.confirm')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('claims.popconfirm.cancel')}
              onConfirm={closureValidation}
              icon={<WarningOutlined />}
            >
              <Button icon={<ClearOutlined />}>
                {`${t('claims.form.enclose')}`}{' '}
              </Button>
            </Popconfirm>
          )}
        </>
      ) : null}
    </Flex>
  );

  return civilLiabilityId && civilLiability && !isLoading ? (
    <>
      <PageHeaderCustom
        title={`${civilLiabilityClaim?.unit_counter || 'xxxx'} - ${
          civilLiability?.company_id?.name
        }`}
        extra={extraButtons}
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          {current !== undefined && current !== 6 ? (
            <Steps
              onChange={(step) => {
                onChange(step, id, edit);
              }}
              items={isCompliant ? items : itemsBeforeValidation}
              current={current}
            />
          ) : null}
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContentClaim}
                    translate
                    layout="vertical"
                    title={t('claims.form.claim_informations')}
                  />
                </Card>
                <Card title={t('claims.form.contract_informations')}>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                  />
                </Card>
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Documents
                idWithoutParams={id}
                baseUrl="civil_liability_claims"
                resource="Civil_liability_claim"
                noDelete={current === 6}
              />
            </Col>
          </Row>
        </Flex>
        <ModalClosure
          open={closure}
          onCancel={() => setClosure(false)}
          onOk={() => setClosure(false)}
          claimReferenceNumber={civilLiabilityClaim?.unit_counter}
        />
      </ContentCustom>
    </>
  ) : (
    <Skeleton active loading={isLoading} />
  );
};
