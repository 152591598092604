import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useColumns } from '../columns';
import { useActionColumn } from '../../actionColumn';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { SelectByEntity } from '../../../components/SelectByEntity';
import { SelectBySite } from '../../../components/SelectBySite';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { FilterByMissingDocuments } from '../../../components/FilterByMissingDocuments';
import { StatusFilter } from '../../../components/StatusFilter';
import { claimStatus } from '../../../../utils/constants/tagColors';

/**
 * Displays a list of other-vehicles claims with relevant columns and additional filters.
 *
 * @component
 * @returns {React.ReactNode} List of other-vehicles claims.
 */
export const ListOtherVehicles = () => {
  const [filter, setFilter] = useState('');
  const [entity, setEntity] = useState('');
  const [site, setSite] = useState('');
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [statusTag, setStatusTag] = useState('');
  const actionColumnUrl = 'other-vehicles';
  const url = 'vehicle_claims';
  const enums = 'claimStatus';

  const columns = useColumns();
  const actionColumn = useActionColumn(actionColumnUrl, url);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { company } = useAuthContext();

  let filterParameter = `entity=${entity}__claim_status=${statusTag}__missing_documents=${isMissingDocuments}__other_vehicle_type=${filter}`;
  const translate = {
    1: '',
    2: 'CONSTRUCTION_MACHINE',
    3: 'MACHINERY',
    4: 'LIFTING_EQUIPMENT',
    5: 'GOLF_CART',
    6: 'ROBOT_LAWN_MAWER'
  };

  const onChange = (activeKey) => {
    setFilter(translate[activeKey]);
  };

  const onFilterEntity = (entityId) => {
    setEntity(entityId || '');
  };

  const onFilterSite = (siteId) => {
    setSite(siteId || '');
  };

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  const onFilterStatus = (status) => {
    setStatusTag(status || '');
  };

  useEffect(() => {
    if (site !== '') {
      filterParameter += `__site=${site}__`;
    } else {
      filterParameter += '__';
    }
    navigate({
      pathname: '/claims/other-vehicles',
      search: `?f=${filterParameter}`
    });
  }, [entity, site, isMissingDocuments, statusTag, navigate, filter]);

  const tabs = [
    {
      label: t(`vehicles.form.all`),
      key: '1'
    },
    {
      label: t(`vehicles.form.enums.other_vehicle_type.CONSTRUCTION_MACHINE`),
      key: '2'
    },
    {
      label: t(`vehicles.form.enums.other_vehicle_type.MACHINERY`),
      key: '3'
    },
    {
      label: t(`vehicles.form.enums.other_vehicle_type.LIFTING_EQUIPMENT`),
      key: '4'
    },
    {
      label: t(`vehicles.form.enums.other_vehicle_type.GOLF_CART`),
      key: '5'
    },
    {
      label: t(`vehicles.form.enums.other_vehicle_type.ROBOT_LAWN_MAWER`),
      key: '6'
    }
  ];

  return (
    <ListResource
      extraQuery={`company=${company}&fleet_type=OTHER_VEHICLE&deleted=false`}
      exportUrl={`exports/company/${company}/programme-type/vehicle_claim`}
      resourceName="vehicle_claims"
      tradKey="claims"
      columns={[...columns, ...actionColumn]}
      withExtraPageHeader={
        <Tabs
          defaultActiveKey="1"
          onChange={onChange}
          items={tabs}
          type="card"
        />
      }
      withExtraFilters={
        <>
          <SelectByEntity onChange={onFilterEntity} />
          <SelectBySite onChange={onFilterSite} />
          <StatusFilter
            onChange={onFilterStatus}
            url={url}
            enums={enums}
            tagMapping={claimStatus}
          />
          <FilterByMissingDocuments onChange={onFilterMissingDocuments} />
        </>
      }
      populate="entity,site,entry_id,company"
      editAction={false}
      showAction={false}
      sheetName={t('sheet.vehicle_claim_other')}
      headers={[]}
      onlyExport
      deleteAction={false}
      noCreateButton
      noArchiveFilter
    />
  );
};
