import PropTypes from 'prop-types';
import { FileExclamationOutlined } from '@ant-design/icons';
import { Flex, Tooltip, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A component for filtering data by missing documents.
 *
 * This component provides a switch to toggle the filter and a tooltip for user guidance.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Function} props.onChange - A callback function to handle the change when the switch is toggled (required).
 *
 * @returns {ReactElement} The rendered `FilterByMissingDocuments` component.
 */

export const FilterByMissingDocuments = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <Flex gap="small" align="center">
      <Switch onChange={onChange} />
      <Tooltip title={t('buttons.missing_documents_only')}>
        <FileExclamationOutlined
          style={{ fontSize: 18, color: 'var(--warningColor)' }}
        />
      </Tooltip>
    </Flex>
  );
};

FilterByMissingDocuments.propTypes = {
  onChange: PropTypes.func.isRequired
};
