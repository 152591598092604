import PropTypes from 'prop-types';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';
import { useAuthContext } from '../../../../contexts/AuthContext';

import { useFields } from './fieldsConstructionInsurance';

/**
 * `ConstrutionInsuanceCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const ConstructionInsuranceCreateUpdate = ({ purpose }) => {
  const [permit, setPermit] = useState(null);
  const draggerFilesKeysMandatory = [
    'insurance_company_form',
    'construction_start_statement_document_CERFA',
    'construction_permit_or_declaration_of_work',
    'contracting_owner_tied_companies_and_subcontractors',
    'project_management_agreement',
    'plans_and_technical_documents',
    'initial_report_from_technical_controller',
    'soil_test_report',
    'detailed_description_of_the_work_on_existing_premises'
  ];
  const { fields, isFieldsLoading } = useFields(purpose, permit);
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();

  const config = {
    onGetResource: {
      setFields: (data) => {
        setPermit(data.construction_permit_number);

        return {
          ...data,
          start_date: data.start_date && dayjs(data.start_date),
          construction_starting_date:
            data.construction_starting_date &&
            dayjs(data.construction_starting_date),
          expected_construction_starting_date:
            data.expected_construction_starting_date &&
            dayjs(data.expected_construction_starting_date),
          expected_construction_completion_date:
            data.expected_construction_completion_date &&
            dayjs(data.expected_construction_completion_date),
          construction_completion_date:
            data.construction_completion_date &&
            dayjs(data.construction_completion_date),
          construction_permit_number_expiry_date:
            data.construction_permit_number_expiry_date &&
            dayjs(data.construction_permit_number_expiry_date)
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_Id: company,
        programmes_types: 'CONSTRUCTION',
        sub_programme: 'CONSTRUCTION_INSURANCE'
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };
  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="constructions"
      resource="constructions"
      config={config}
      extraTitle={<Row justify="center">{`${t('folder')} ${companyName}`}</Row>}
      withFilesManager
      draggerFilesKeysMandatory={draggerFilesKeysMandatory}
      populate="?populate=documents.file"
    />
  );
};

ConstructionInsuranceCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
