import { useTranslation } from 'react-i18next';

/**
 * A custom hook that generates a list of step titles for a subscription process.
 *
 * @returns {Object} An object containing an array of step titles.
 */
export const useListItemsStep = () => {
  const { t } = useTranslation();

  const items = [
    {
      title: t('subscriptions.form.request_received')
    },
    {
      title: t('subscriptions.form.additional_request')
    },
    {
      title: t('subscriptions.form.insurance_exchange')
    },
    {
      title: t('subscriptions.form.rejected')
    },
    {
      title: t('subscriptions.form.accepted')
    }
  ];
  return { items };
};
