import { useCallback, useEffect, useState } from 'react';
import { DatePicker, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { SelectEnumsClaimsType } from '../../components/SelectEnumsClaimsType';
import { SelectManager } from '../../components/SelectManager';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

const { Group } = Radio;
const { Option } = Select;

/**
 * A hook for generating form fields configuration based on the provided parameters.
 *
 * @hook
 * @param {boolean} isOther - Indicates whether the "claim_description_if_other" field is visible.
 * @param {Function} setIsOther - A function to set the visibility of the "claim_description_if_other" field.
 * @returns {Object} An object containing an array of form fields configuration.
 */

export const useFields = (
  isOther,
  setIsOther,
  setIsComplaint,
  unusual,
  setUnusual,
  usualDrivers
) => {
  const dateFormat = 'DD-MM-YYYY';
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const fieldsInformations = [
    {
      label: ['customer_manager'],
      name: ['customer_manager'],
      rules: [{ required: true }],
      input: <SelectManager dbKey="customer_manager" />
    },
    {
      label: ['unit_manager'],
      name: ['unit_manager'],
      rules: [{ required: false }],
      input: <SelectManager dbKey="unit_manager" />
    },
    {
      label: ['insurance_company_reference'],
      name: ['insurance_company_reference']
    },
    {
      label: ['client_reference'],
      name: ['client_reference']
    },
    {
      label: ['claim_type'],
      name: ['claim_type'],
      rules: [{ required: true }],
      input: (
        <SelectEnumsClaimsType
          dbKey="claim_type"
          url="vehicle_claims"
          setIsOther={setIsOther}
          setIsComplaint={setIsComplaint}
        />
      )
    },
    {
      label: ['claim_type_if_other'],
      name: ['claim_type_if_other'],
      hidden: !isOther
    },
    {
      label: ['sinister_date'],
      name: ['sinister_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} style={{ width: '100%' }} />
    },
    {
      label: ['place_of_occurrence'],
      name: ['place_of_occurrence'],
      rules: [{ required: true }]
    },
    {
      label: ['unusual_driver'],
      name: ['unusual_driver'],
      rules: [{ required: true }],
      input: (
        <Group
          onChange={(e) => {
            if (e.target.value === 'YES') {
              setUnusual(true);
            } else {
              setUnusual(false);
            }
          }}
        >
          {(enums?.unusualDriver || []).map((option) => (
            <Radio key={option} value={option}>
              {t(`claims.form.${option}`)}
            </Radio>
          ))}
        </Group>
      )
    },
    {
      label: ['driver_time_accident'],
      name: ['unusual_driver_name'],
      rules: [{ required: !unusual }],
      hidden: unusual
    },
    {
      label: ['driver_time_accident'],
      name: ['driver_time_accident'],
      rules: [{ required: unusual }],
      hidden: !unusual,
      input: (
        <Select>
          {(usualDrivers || []).map((driver) => (
            <Option key={driver._id} value={driver._id}>
              {driver.first_name} {driver.last_name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: ['personal_injury'],
      name: ['personal_injury'],
      rules: [{ required: true }],
      input: (
        <Group>
          {(enums?.personalInjury || []).map((option) => (
            <Radio key={option} value={option}>
              {t(`claims.form.${option}`)}
            </Radio>
          ))}
        </Group>
      )
    },
    {
      label: ['garage_informations'],
      name: ['garage_informations'],
      rules: [{ required: false }]
    },
    {
      label: ['pictures_damages_caused'],
      name: ['pictures_damages_caused'],
      input: 'File',
      maxFilesCount: 5,
      multipleFiles: true,
      startWithDivider: {
        title: t('CLAIMS.FORM.PHOTOS')
      }
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/vehicle_claims/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(false);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fieldsInformations,
    isFieldsLoading
  };
};
