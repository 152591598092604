import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  ClearOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Col,
  Flex,
  Skeleton,
  Row,
  Steps,
  Popconfirm
} from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContentClaim } from '../utils/listContentClaim';
import { useListContent, useListContentAddresses } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { Documents } from '../../components/Documents';
import {
  useListItemsStep,
  useListBeforeValidation
} from '../utils/listItemsStep';
import { routes } from '../../../utils/constants/adminRoutes';
import { handleStatusUtils } from '../utils/handleStatusUtils';
import { AcknowledgeReceiptButton } from '../utils/ClaimsButtons/AcknowledgeReceiptButton';
import { AcknowledgeComplianceButton } from '../utils/ClaimsButtons/AcknowledgeComplianceButton';
import { ExpertCall } from '../utils/ClaimsButtons/ExpertCall';
import { ModalClosure } from '../utils/ModalClosure';
import { PhotosClaims } from '../utils/PhotosClaims';
import { AttestationGenerationButton } from '../utils/ClaimsButtons/AttestationGenerationButton';
import { MailingButtonClaim } from '../../Mailing/MailingButtonClaim';

/**
 * Displays detailed information about a golf claim, including claim information, golf information,
 * pictures, and related actions.
 *
 * @component
 * @returns {React.ReactNode} Detailed view of the golf claim.
 */

export const GolfShow = () => {
  const targetUrl = 'golf_claims';
  const { id } = useParams();
  const { dispatchAPI, company, permissions, verifPermissions } =
    useAuthContext();
  const { message } = useErrorMessage();
  const { current, setCurrent, onChange, toEnclosed, setToEnclose } =
    handleStatusUtils(targetUrl);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { items } = useListItemsStep();
  const { itemsBeforeValidation } = useListBeforeValidation();
  const [golf, setGolf] = useState();
  const [golfClaim, setGolfClaim] = useState();
  const [golfId, setGolfId] = useState();
  const [edit, setEdit] = useState(true);
  const [isCompliant, setIsCompliant] = useState(false);
  const listContentClaim = useListContentClaim(golfClaim);
  const listContent = useListContent(golf);
  const listContentAddresses = useListContentAddresses(golf);
  const [closure, setClosure] = useState(false);

  const getGolfClaim = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/golf_claims/documents/${id}`
      });
      setGolfClaim(data);
      setGolfId(data.entry_id._id);
      setCurrent(data.step);
      setIsCompliant(data.is_compliant);
      setToEnclose(data.to_enclose);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  const getGolf = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/golfs/${golfId}?populate=customer_manager,unit_manager,contract,entity,site,company`
      });
      setGolf(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [golfId]);

  useEffect(() => {
    if (golfId) {
      (async () => {
        await getGolf();
      })();
    }
  }, [golfId]);

  useEffect(() => {
    (async () => {
      await getGolfClaim();
    })();
  }, []);

  const reception = () => {
    onChange(0, id, edit);
  };

  const closureValidation = () => {
    setClosure(true);
    onChange(6, id, edit);
  };

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions('/claims/edit', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  const extraButtons = (
    <Flex gap="small" wrap="wrap" justify="end">
      {current === undefined ? (
        <AcknowledgeReceiptButton
          reception={reception}
          claimReferenceNumber={golfClaim?.unit_counter}
        />
      ) : null}
      {current !== 6 && current !== undefined ? (
        <>
          <MailingButtonClaim
            asset={golfClaim}
            templateName="Blank"
            tradKey="specific_demand"
            resource="Golf_claim"
            baseUrl="golf_claims"
            reference={golf?.contract?.contract_number}
          />
          {(current === 0 || current === 1) && !isCompliant ? (
            <AcknowledgeComplianceButton
              setIsCompliant={setIsCompliant}
              claimReferenceNumber={golfClaim?.unit_counter}
              id={id}
              url="/golf_claims"
            />
          ) : null}
          {current === 2 && isCompliant ? (
            <ExpertCall
              company={company}
              claimReferenceNumber={golfClaim?.unit_counter}
              programmeType="GOLF"
              claimId={id}
              claimNumber={golfClaim?.unit_counter}
              url="golf_claims"
            />
          ) : null}
          {current >= 2 && isCompliant ? (
            <>
              <AttestationGenerationButton />
              <MailingButtonClaim
                asset={golfClaim}
                templateName="Blank"
                tradKey="sending_attestation"
                resource="Golf_claim"
                baseUrl="golf_claims"
                reference={golf?.contract?.contract_number}
              />
            </>
          ) : null}
          {edit && (
            <Link
              to={{
                pathname: `${routes.CLAIMS}/golfs/edit/${id}`
              }}
            >
              <Button icon={<EditOutlined />}>{`${t(
                'claims.form.edit'
              )} `}</Button>
            </Link>
          )}
          {toEnclosed && (
            <Popconfirm
              title={t('claims.popconfirm.title')}
              okText={t('claims.popconfirm.confirm')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('claims.popconfirm.cancel')}
              onConfirm={closureValidation}
              icon={<WarningOutlined />}
            >
              <Button icon={<ClearOutlined />}>
                {`${t('claims.form.enclose')}`}{' '}
              </Button>
            </Popconfirm>
          )}
        </>
      ) : null}
    </Flex>
  );

  return golfId && golf && !isLoading ? (
    <>
      <PageHeaderCustom
        title={`${golfClaim?.unit_counter || 'xxxx'} - ${golf?.golf_name} ${
          golf?.city
        }`}
        extra={extraButtons}
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          {current !== undefined && current !== 6 ? (
            <Steps
              onChange={(step) => {
                onChange(step, id, edit);
              }}
              items={isCompliant ? items : itemsBeforeValidation}
              current={current}
            />
          ) : null}
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContentClaim}
                    translate
                    layout="vertical"
                    title={t('claims.form.claim_informations')}
                  />
                </Card>
                <PhotosClaims baseUrl="golf_claims" />
                <Documents
                  idWithoutParams={id}
                  baseUrl="golf_claims"
                  resource="Golf_claim"
                  noDelete={current === 6}
                />
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card title={t('claims.form.main_informations')}>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                  />
                </Card>
                <Card>
                  <DescriptionList
                    data={listContentAddresses}
                    translate
                    title={t('claims.form.address')}
                  />
                </Card>
              </Row>
            </Col>
          </Row>
        </Flex>
        <ModalClosure
          open={closure}
          onCancel={() => setClosure(false)}
          onOk={() => setClosure(false)}
          claimReferenceNumber={golfClaim?.unit_counter}
        />
      </ContentCustom>
    </>
  ) : (
    <Skeleton active loading={isLoading} />
  );
};
