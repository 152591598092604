import PropTypes from 'prop-types';
import { Drawer, Row, Select, Radio } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useFieldsDrivers } from './fieldsDrivers';
import { CreateUpdateContainer } from '../../../../components/CreateUpdateContainerV3/CreateUpdateContainerDrivers';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Group } = Radio;
const { Option } = Select;

/**
 * `DriverDrawer` is a component that presents a drawer UI element allowing users to manage drivers.
 * The user can either create a new driver or edit existing driver data.
 *
 * @component
 * @param {Object} props - Properties passed to the component.
 * @param {boolean} props.isOpen - Flag indicating if the drawer is open.
 * @param {Function} props.setIsOpen - Function to set the `isOpen` state.
 * @param {Function} props.setDriverId - Callback to set the selected driver's ID.
 * @param {Function} props.setIsPrincipal - Callback to set if the driver is principal.
 *
 */

export const DriverDrawer = ({
  isOpen,
  setIsOpen,
  setDriverId,
  setIsPrincipal
}) => {
  const { driversFields, isFieldsLoading } = useFieldsDrivers();
  const [purpose, setPurpose] = useState('edit');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [idWithoutParams, setIdWithoutParams] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const { message } = useErrorMessage();

  const { company, companyName, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        driving_licences: data.driving_licences.map((licence) => ({
          licence_selection: licence.licence_selection,
          date_obtained: licence.date_obtained && moment(licence.date_obtained)
        }))
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_Id: company
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data
      })
    }
  };
  const onChange = (e) => {
    setPurpose(e.target.value);
    setForceRefresh(!forceRefresh);
  };

  const getDrivers = async () => {
    try {
      const filters = company ? `?company_Id=${company}` : '';
      const { data } = await dispatchAPI('GET', { url: `/drivers${filters}` });
      setDrivers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    await getDrivers();
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <Drawer
      title={t('drivers.form.add_driver')}
      width={720}
      onClose={() => {
        setIsOpen(false);
        setIdWithoutParams(null);
      }}
      destroyOnClose
      open={isOpen}
    >
      <Row style={{ marginBottom: 12 }}>
        <Group onChange={onChange} defaultValue="edit">
          <Radio.Button value="edit">
            {t('drivers.form.add_existing_driver')}
          </Radio.Button>
          <Radio.Button value="create">
            {t('drivers.form.add_new_driver')}
          </Radio.Button>
        </Group>
      </Row>
      <Row justify="start" style={{ marginBottom: 12 }}>
        {purpose && purpose === 'edit' && (
          <Select
            placeholder={t('drivers.form.select_driver')}
            showSearch
            onChange={(value) => setIdWithoutParams(value)}
            filterOption={(input, option) =>
              (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
            }
          >
            {drivers &&
              drivers.map((d) => (
                <Option key={d._id} value={d._id}>
                  {`${d.first_name} ${d.last_name}`}
                </Option>
              ))}
          </Select>
        )}
      </Row>
      {(purpose && purpose === 'create') ||
      (idWithoutParams !== null && idWithoutParams !== undefined) ? (
        <CreateUpdateContainer
          key={forceRefresh}
          fields={driversFields}
          loadingFields={isFieldsLoading}
          purpose={purpose}
          baseUrl="drivers"
          resource="drivers"
          config={config}
          extraTitle={
            <Row justify="center" className="form-company-name">{`${t(
              'folder'
            )} ${companyName}`}</Row>
          }
          setDriverId={setDriverId}
          setIsPrincipal={setIsPrincipal}
          idWithoutParams={idWithoutParams}
          setIsOpen={setIsOpen}
          setIdWithoutParams={setIdWithoutParams}
          WithPageHeaderCustom={false}
        />
      ) : null}
    </Drawer>
  );
};

DriverDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setDriverId: PropTypes.func.isRequired,
  setIsPrincipal: PropTypes.func.isRequired
};
