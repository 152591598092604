import { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  ClearOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Col,
  Flex,
  Skeleton,
  Row,
  Steps,
  Popconfirm
} from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListContentClaim } from '../utils/listContentClaim';
import { useListContent, useListContentAddresses } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../../components';
import { Documents } from '../../components/Documents';
import {
  useListItemsStep,
  useListBeforeValidation
} from '../utils/listItemsStep';
import { routes } from '../../../utils/constants/adminRoutes';
import { handleStatusUtils } from '../utils/handleStatusUtils';
import { AcknowledgeReceiptButton } from '../utils/ClaimsButtons/AcknowledgeReceiptButton';
import { AcknowledgeComplianceButton } from '../utils/ClaimsButtons/AcknowledgeComplianceButton';
import { ExpertCall } from '../utils/ClaimsButtons/ExpertCall';
import { ModalClosure } from '../utils/ModalClosure';
import { PhotosClaims } from '../utils/PhotosClaims';
import { AttestationGenerationButton } from '../utils/ClaimsButtons/AttestationGenerationButton';
import { MailingButtonClaim } from '../../Mailing/MailingButtonClaim';
import { ShowClaimAccountingOverview } from '../ClaimAccountingOverview/ShowClaimAccountingOverview';

/**
 * Displays detailed information about a property claim, including claim information, property information,
 * pictures, and related actions.
 *
 * @component
 * @returns {React.ReactNode} Detailed view of the property claim.
 */

export const PropertyShow = () => {
  const targetUrl = 'property_claims';
  const { id } = useParams();
  const { dispatchAPI, company, permissions, verifPermissions } =
    useAuthContext();
  const { message } = useErrorMessage();
  const { current, setCurrent, onChange, toEnclosed, setToEnclose } =
    handleStatusUtils(targetUrl);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { items } = useListItemsStep();
  const { itemsBeforeValidation } = useListBeforeValidation();
  const [property, setProperty] = useState();
  const [propertyClaim, setPropertyClaim] = useState();
  const [propertyId, setPropertyId] = useState();
  const [isCompliant, setIsCompliant] = useState(false);
  const [edit, setEdit] = useState(true);
  const [closure, setClosure] = useState(false);
  const [accountsOk, setAccountsOk] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [accountingBalanced, setAccountingBalanced] = useState(false);
  const listContentClaim = useListContentClaim(propertyClaim);
  const listContent = useListContent(property);
  const listContentAddresses = useListContentAddresses(property);

  const getPropertyClaim = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/property_claims/documents/${id}`
      });
      setPropertyClaim(data);
      setPropertyId(data?.entry_id?._id);
      setCurrent(data?.step);
      setIsCompliant(data?.is_compliant);
      setToEnclose(data?.to_enclose);
      setAccountsOk(data?.accounting_started && data?.accounting_balanced);
      setIsClosed(data?.is_closed);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  const getProperty = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/properties/${propertyId}?populate=customer_manager,unit_manager,contract,entity,site,company`
      });
      setProperty(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [propertyId]);

  useEffect(() => {
    if (propertyId) {
      (async () => {
        await getProperty();
      })();
    }
  }, [propertyId]);

  useEffect(() => {
    (async () => {
      await getPropertyClaim();
    })();
  }, [current, accountingBalanced]);

  const reception = () => {
    onChange(0, id, edit);
  };

  const closureValidation = () => {
    setClosure(true);
    onChange(6, id, edit);
  };

  useEffect(() => {
    if (permissions?.notPermittedRoutes?.length > 0) {
      setEdit(
        !verifPermissions('/claims/edit', permissions?.notPermittedRoutes)
      );
    }
  }, [permissions]);

  const extraButtons = (
    <Flex gap="small" wrap="wrap" justify="end">
      {current === undefined ? (
        <AcknowledgeReceiptButton
          reception={reception}
          claimReferenceNumber={propertyClaim?.unit_counter}
        />
      ) : null}
      {current !== 6 && current !== undefined ? (
        <>
          <MailingButtonClaim
            asset={propertyClaim}
            templateName="Blank"
            tradKey="specific_demand"
            resource="Property_claim"
            baseUrl="property_claims"
            reference={property?.contract?.contract_number}
          />
          {(current === 0 || current === 1) && !isCompliant ? (
            <AcknowledgeComplianceButton
              setIsCompliant={setIsCompliant}
              claimReferenceNumber={propertyClaim?.unit_counter}
              id={id}
              url="/property_claims"
            />
          ) : null}
          {current === 2 && isCompliant ? (
            <ExpertCall
              company={company}
              claimReferenceNumber={propertyClaim?.unit_counter}
              programmeType="PROPERTY"
              claimId={id}
              claimNumber={propertyClaim?.unit_counter}
              url="property_claims"
            />
          ) : null}
          {current >= 2 && isCompliant ? (
            <>
              <AttestationGenerationButton />
              <MailingButtonClaim
                asset={propertyClaim}
                templateName="Blank"
                tradKey="sending_attestation"
                resource="Property_claim"
                baseUrl="property_claims"
                reference={property?.contract?.contract_number}
              />
            </>
          ) : null}
          {edit && (
            <Link
              to={{
                pathname: `${routes.CLAIMS}/properties/edit/${id}`
              }}
            >
              <Button icon={<EditOutlined />}>{`${t(
                'claims.form.edit'
              )} `}</Button>
            </Link>
          )}
          {toEnclosed && (accountingBalanced || accountsOk) && (
            <Popconfirm
              title={t('claims.popconfirm.title')}
              okText={t('claims.popconfirm.confirm')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('claims.popconfirm.cancel')}
              onConfirm={closureValidation}
              icon={<WarningOutlined />}
            >
              <Button icon={<ClearOutlined />}>
                {`${t('claims.form.enclose')}`}{' '}
              </Button>
            </Popconfirm>
          )}
        </>
      ) : null}
    </Flex>
  );

  return propertyId && property && !isLoading ? (
    <>
      <PageHeaderCustom
        title={`${propertyClaim?.unit_counter || 'xxxx'} - ${
          property?.asset_name
        } ${property?.city}`}
        extra={extraButtons}
      />
      <ContentCustom>
        <Flex vertical gap="middle">
          {current !== undefined && current !== 6 ? (
            <Steps
              onChange={(step) => {
                onChange(step, id, edit);
              }}
              items={isCompliant ? items : itemsBeforeValidation}
              current={current}
            />
          ) : null}
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card>
                  <DescriptionList
                    data={listContentClaim}
                    translate
                    layout="vertical"
                    title={t('claims.form.claim_informations')}
                  />
                </Card>
                <PhotosClaims baseUrl="property_claims" />
                <Documents
                  idWithoutParams={id}
                  baseUrl="property_claims"
                  resource="Property_claim"
                  noDelete={current === 6}
                />
              </Row>
            </Col>
            <Col xs={24} xl={12}>
              <Row gutter={[0, 16]}>
                <Card title={t('claims.form.main_informations')}>
                  <DescriptionList
                    data={listContent}
                    translate
                    layout="vertical"
                  />
                </Card>
                <Card>
                  <DescriptionList
                    data={listContentAddresses}
                    translate
                    title={t('claims.form.address')}
                  />
                </Card>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={24}>
              {current >= 2 && isCompliant ? (
                <ShowClaimAccountingOverview
                  current={current}
                  buttonBelowTable={
                    current !== 6 && current !== undefined && edit === true
                  }
                  noActionColumnsButton={isClosed}
                  accountingBalanced={accountingBalanced}
                  setAccountingBalanced={setAccountingBalanced}
                />
              ) : null}
            </Col>
          </Row>
        </Flex>
        <ModalClosure
          open={closure}
          onCancel={() => setClosure(false)}
          onOk={() => setClosure(false)}
          claimReferenceNumber={propertyClaim?.unit_counter}
        />
      </ContentCustom>
    </>
  ) : (
    <Skeleton active loading={isLoading} />
  );
};
