import { Route, Routes } from 'react-router-dom';
import { ClaimsTilesPage } from './ClaimsTilesPage';
import { AutomissionsRouter } from './VehiclesFleets/Automissions/AutomissionsRouter';
import { LightVehiclesRouter } from './VehiclesFleets/LightVehicles/LightVehiclesRouter';
import { OtherVehiclesRouter } from './VehiclesFleets/OtherVehicles/OtherVehiclesRouter';
import { HeavyVehiclesRouter } from './VehiclesFleets/HeavyVehicles/HeavyVehiclesRouter';
import { PropertyRouter } from './Property/PropertyRouter';
import { GolfRouter } from './Golf/GolfRouter';
import { CivilLiabilityExecutiveRouter } from './CivilLiability/CivilLiabilityExecutive/CivilLiabilityExecutiveRouter';
import { CivilLiabilityProRouter } from './CivilLiability/CivilLiabilityPro/CivilLiabilityProRouter';
import { AdValoremRouter } from './Transport/AdValorem/AdValoremRouter';
import { FreightedGoodsRouter } from './Transport/FreightedGoods/FreightedGoodsRouter';
import { OwnAccountsRouter } from './Transport/OwnAccounts/OwnAccountsRouter';
import { FacultiesRouter } from './Transport/Faculties/FacultiesRouter';
import { ConstructionInsuranceRouter } from './Construction/ConstructionInsurance/ConstructionInsuranceRouter';
import { OthersConstructionRouter } from './Construction/OthersConstruction/OthersConstructionRouter';
import { Exception } from '../../components';

export const ClaimsRouter = () => (
  <Routes>
    <Route path="/properties/*" element={<PropertyRouter />} />

    <Route path="/golfs/*" element={<GolfRouter />} />

    <Route path="/light-vehicles/*" element={<LightVehiclesRouter />} />
    <Route path="/heavy-vehicles/*" element={<HeavyVehiclesRouter />} />
    <Route path="/other-vehicles/*" element={<OtherVehiclesRouter />} />
    <Route path="/automissions/*" element={<AutomissionsRouter />} />

    <Route
      path="/civil-liability-pro/*"
      element={<CivilLiabilityProRouter />}
    />
    <Route
      path="/civil-liability-executive/*"
      element={<CivilLiabilityExecutiveRouter />}
    />

    <Route path="/freighted-goods/*" element={<FreightedGoodsRouter />} />
    <Route path="/own-accounts/*" element={<OwnAccountsRouter />} />
    <Route path="/faculties/*" element={<FacultiesRouter />} />
    <Route path="/ad-valorem/*" element={<AdValoremRouter />} />

    <Route
      path="/construction-insurance/*"
      element={<ConstructionInsuranceRouter />}
    />
    <Route
      path="/other-construction/*"
      element={<OthersConstructionRouter />}
    />

    <Route
      index
      element={<ClaimsTilesPage title="claims" tilesPage="claims" />}
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
