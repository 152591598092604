import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A custom menu component for adding subscriptions.
 * @component
 * @returns {JSX.Element} The JSX element representing the menu.
 */
export const MenuAddSubscription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menu1 = { CIVIL_LIABILITY_PRO: 'civil-liability/pro' };
  const menu2 = { CIVIL_LIABILITY_EXECUTIVE: 'civil-liability/executive' };
  const menu3 = { OWN_ACCOUNTS: 'transport/own-accounts' };
  const menu4 = { FREIGHTED_GOODS: 'transport/freighted-goods' };
  const menu5 = { FACULTY: 'transport/faculty' };
  const menu6 = { OTHER: 'construction' };

  const getItem = (label, key, children, type) => ({
    key,
    children,
    label,
    type
  });

  const items = [
    getItem(t('subscriptions.form.subscription_request'), 'add_subscription', [
      getItem(
        t('subscriptions.form.CIVIL_LIABILITY'),
        'g1',
        [
          getItem(
            t('subscriptions.form.CIVIL_LIABILITY_PRO'),
            `${menu1.CIVIL_LIABILITY_PRO}`
          ),
          getItem(
            t('subscriptions.form.CIVIL_LIABILITY_EXECUTIVE'),
            `${menu2.CIVIL_LIABILITY_EXECUTIVE}`
          )
        ],
        'group'
      ),
      getItem(
        t('transport.title'),
        'g2',
        [
          getItem(t('subscriptions.form.OWN_ACCOUNT'), `${menu3.OWN_ACCOUNTS}`),
          getItem(
            t('subscriptions.form.FREIGHTED_GOODS'),
            `${menu4.FREIGHTED_GOODS}`
          ),
          getItem(t('subscriptions.form.FACULTY'), `${menu5.FACULTY}`)
        ],
        'group'
      ),
      getItem(
        t('construction.title'),
        'g3',
        [getItem(t('subscriptions.form.OTHER'), `${menu6.OTHER}`)],
        'group'
      )
    ])
  ];

  const handleNavigate = ({ key }) => {
    const allMenus = [menu1, menu2, menu3, menu4, menu5, menu6];
    const foundMenu = allMenus.find((menu) => Object.values(menu)[0] === key);
    const originalKey = foundMenu ? Object.keys(foundMenu)[0] : null;
    if (originalKey) {
      const navigation = key;
      navigate(`/subscriptions/${navigation}/create`);
    }
  };

  return (
    <Menu
      mode="vertical"
      items={items}
      onClick={handleNavigate}
      className="new-subscription-menu"
    />
  );
};
