import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

/**
 * ErrorModal component displays an error modal dialog.
 * @component
 * @param {Object} props - The component's props.
 * @param {boolean} props.isOpen - Determines whether the modal is open or closed.
 * @param {function} props.setIsOpen - A function to control the visibility of the modal.
 * @param {string} props.title - The title of the error modal.
 * @param {string[]} props.details - An array of error details to be displayed.
 * @param {string} props.tradKey - A translation key for error detail messages.
 * @returns {JSX.Element} The rendered ErrorModal component.
 */

export const ErrorModal = ({ isOpen, setIsOpen, title, details, tradKey }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={() => setIsOpen(false)}
      title={title}
    >
      <ul>
        {details &&
          details.map((detail) => (
            <li key={detail}>{t(`${tradKey}.${detail}`)}</li>
          ))}
      </ul>
    </Modal>
  );
};

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  tradKey: PropTypes.string.isRequired
};
