import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * A custom hook that generates a list of content for displaying subscription details.
 * @hook
 * @param {object} data - The subscription data to generate content for.
 * @returns {Array} An array of objects containing label, span, and content for each detail.
 */
export const useListContent = (data = {}) => {
  const { t } = useTranslation();

  const {
    company_id,
    customer_manager,
    unit_manager,
    desired_effective_date,
    APE_code,
    previous_year_revenue,
    eighteen_months_span_statistic_number_of_employees,
    description_of_the_activity
  } = data;
  const customerManagerName = customer_manager
    ? `${customer_manager.first_name} ${customer_manager.last_name}`
    : '-';
  const unit_managerName = unit_manager
    ? `${unit_manager.first_name} ${unit_manager.last_name}`
    : '-';

  return [
    {
      label: 'subscriptions.form.company_name',
      span: 1,
      content: company_id?.name || '-'
    },
    {
      label: 'subscriptions.form.customer_manager',
      span: 1,
      content: (customerManagerName && customerManagerName) || '-'
    },
    {
      label: 'subscriptions.form.unit_manager',
      span: 1,
      content: (unit_managerName && unit_managerName) || '-'
    },
    {
      label: 'subscriptions.form.desired_effective_date',
      span: 1,
      content:
        (desired_effective_date &&
          moment(desired_effective_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'subscriptions.form.APE_code',
      span: 1,
      content: (APE_code && APE_code) || '-'
    },
    {
      label: 'subscriptions.form.previous_year_revenue',
      span: 2,
      content: (previous_year_revenue && previous_year_revenue) || '-'
    },
    {
      label:
        'subscriptions.form.eighteen_months_span_statistic_number_of_employees',
      span: 2,
      content: eighteen_months_span_statistic_number_of_employees
        ? t(
            `subscriptions.form.${eighteen_months_span_statistic_number_of_employees}`
          )
        : '-'
    },
    {
      label: 'subscriptions.form.description_of_the_activity',
      span: 3,
      content: description_of_the_activity || '-'
    }
  ];
};
