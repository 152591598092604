import PropTypes from 'prop-types';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../../../components/CreateUpdateContainerV3/CreateUpdateContainerAmendment';
import { useAuthContext } from '../../../../../contexts/AuthContext';

import { useFields } from './fieldsAmendments';

/**
 * `AmendmentCreateUpdate` is a React component for creating or updating documents.
 *
 * Depending on the provided `purpose` prop, this component can be used to either create or edit a document.
 * The component utilizes the `CreateUpdateContainer` for rendering the input fields and managing form actions.
 *
 * @component
 * @param {object} props The props for the component.
 * @param {string} props.purpose Indicates the purpose of the form. It should be either 'create' or 'edit'.
 * @returns {React.ElementType} A form for creating or updating a document.
 */

export const AmendmentCreateUpdate = ({ purpose }) => {
  const [amendmentNewConstructionCost, setAmendmentNewConstructionCost] =
    useState(null);
  const [expectedConstructionCost, setExpectedConstructionCost] =
    useState(null);
  const [amendingAmendment, setAmendingAmendment] = useState(null);
  const { fields, isFieldsLoading } = useFields(
    setAmendmentNewConstructionCost,
    amendmentNewConstructionCost,
    expectedConstructionCost,
    purpose,
    amendingAmendment
  );
  const { company, companyName } = useAuthContext();
  const { t } = useTranslation();

  const config = {
    onGetResource: {
      setFields: (data) => {
        setExpectedConstructionCost(
          data?.amended_new_expected_construction_costs ||
            data?.expected_construction_cost
        );
        setAmendingAmendment(data?.amended_new_expected_construction_costs);
        return {
          ...data,
          start_date: data.start_date && dayjs(data.start_date),
          construction_starting_date:
            data.construction_starting_date &&
            dayjs(data.construction_starting_date),
          expected_construction_starting_date:
            data.expected_construction_starting_date &&
            dayjs(data.expected_construction_starting_date),
          expected_construction_completion_date:
            data.expected_construction_completion_date &&
            dayjs(data.expected_construction_completion_date),
          construction_completion_date:
            data.construction_completion_date &&
            dayjs(data.construction_completion_date),
          construction_permit_number_expiry_date:
            data.construction_permit_number_expiry_date &&
            dayjs(data.construction_permit_number_expiry_date),
          amendment_validity_start_date:
            data.amendment_validity_start_date &&
            dayjs(data.amendment_validity_start_date),
          amendment_new_construction_cost: null,
          expected_construction_cost:
            purpose === 'edit'
              ? data?.amendment_new_construction_cost
              : data?.amended_new_expected_construction_costs ||
                data?.expected_construction_cost
        };
      }
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_Id: company,
        programmes_types: 'CONSTRUCTION',
        sub_programme: 'CONSTRUCTION_INSURANCE',
        amendment_new_construction_cost: amendmentNewConstructionCost,
        expected_construction_cost: amendmentNewConstructionCost,
        previous_expected_construction_cost: expectedConstructionCost
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        amendment_new_construction_cost: amendmentNewConstructionCost,
        expected_construction_cost: amendmentNewConstructionCost
      })
    }
  };
  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      createUrl="constructions"
      editUrl="amendments"
      resource="constructions"
      config={config}
      extraTitle={<Row justify="center">{`${t('folder')} ${companyName}`}</Row>}
      withFilesManager={false}
      populate="?populate=documents.file"
    />
  );
};

AmendmentCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
