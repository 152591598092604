import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFields } from './utils/fieldsCivilLiability';
import { useAuthContext } from '../../contexts/AuthContext';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainerV3/CreateUpdateContainerDocViewer';

/**
 * A component for creating or updating civil liability subscriptions.
 * @component
 * @param {object} props - The component's props.
 * @param {string} props.purpose - The purpose of the component (e.g., 'create' or 'update').
 * @returns {JSX.Element} The JSX element representing the create/update component.
 */
export const CivilLiabilitySubscriptionCreateUpdate = ({
  purpose,
  subProgramme
}) => {
  const { company } = useAuthContext();
  const program = 'CIVIL_LIABILITY';
  const { t } = useTranslation();
  const { fieldsInformations } = useFields(subProgramme);
  const draggerFilesKeysOverRide =
    subProgramme === 'CIVIL_LIABILITY_PRO'
      ? [
          'company_KBIS',
          'programme_type_document',
          'certificate_of_current_contract_or_copy',
          'others'
        ]
      : ['company_KBIS_lte_3_months', 'organization_chart_of_the_company'];

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data,
        desired_effective_date:
          data.desired_effective_date && dayjs(data.desired_effective_date)
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        company_id: company,
        programme_types: program,
        sub_programme: subProgramme
      })
    }
  };

  return (
    <CreateUpdateContainer
      title={t(`subscriptions.form.${purpose}`)}
      fields={fieldsInformations}
      purpose={purpose}
      baseUrl="subscriptions"
      resource="subscriptions"
      populate="?populate=documents.file"
      config={config}
      draggerFilesKeysOverRide={draggerFilesKeysOverRide}
      messageOnSuccess
    />
  );
};

CivilLiabilitySubscriptionCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired,
  subProgramme: PropTypes.string
};

CivilLiabilitySubscriptionCreateUpdate.defaultProps = {
  subProgramme: null
};
